import React from 'react'
import './Die.css'
import './_theme/Die_Theme.css'
import './_state/Die_State.css'
import {useClassName} from "../../share/hooks/useClassName";
export const Die = ({
children,
additionalClassString,
additionalClassBoolean,
body,
action,
imgSrc,
postionImg
                    }) => {
    // Функция для отслеживания  клика по плашке
    const HandlerClick = () => {
        if(action)
         action()
    }
    return(<>
        <div
            className={useClassName({mainClass: 'Die', additionalClassString: additionalClassString, additionalClassBoolean: additionalClassBoolean})}
            onClick={HandlerClick}
        >
            { imgSrc&& <img style={{order: postionImg || 0}} src = {`${imgSrc}`} alt ='#'/> }
           <p>{body}</p>
         {children}
        </div>
        </>)
}
