import {useEffect} from "react";

export const useInfinityScroll = (refWrapper, refTrigger, callback) => {
    useEffect(() => {
        let options = {
            root: refWrapper.current,
            rootMargin: "0px",
            threshold: 1.0,
        };
        const observe = new IntersectionObserver(([entry], observe) => {
           if (entry.isIntersecting) {
               callback()
           }
        }, options)

        observe.observe(refTrigger.current)
    }, []);
}
