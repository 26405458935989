import React from "react";
import { Helmet } from "react-helmet";
import { SectionBottom, Container } from "../core/styles";
import styled from "styled-components"
import Carrousel from "../components/Carrousel";
import { Promotion } from "../components/Menu/Promotion";
import KitchenFilter from "../components/KitchenFilter";
import KitchenContainer from "../components/KitchenContainer";
import { useSelector } from "react-redux";
import { Loader } from "../utils/loader";
import EmptyData from "../components/EmptyData";
import Subscribe from "../components/Subscribe";

const MainPage = () => {
  const { isLoadingKitchens, kitchenData } = useSelector(state => state.kitchen);

  return (
    <>
      <Helmet>
        <title>Главная</title>
      </Helmet>

      {/* <Carrousel/> */}

      <SectionBottom>
        <Container>
          {/* <Promotion
              isLoading = {isLoadingPromotion}
              data={storiesKitchens}
              width = {viewportWidth}
              resetPromotion = {resetPromotion}
          /> */}

          <KitchenFilter/>

          {isLoadingKitchens ? (
            <Loader heightContainer={500}/> 
          ) : (
            kitchenData.length > 0 ?
              <KitchenContainer/>
            :
              <EmptyData/>
          )}
        </Container>
      </SectionBottom>

      <Subscribe/>
    </>
  )
}

export default MainPage;
