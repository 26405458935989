import React from "react";
import {connect} from "react-redux";
import {getOrders, resetOrders} from '../../redux/reducer/slices/ordersSlice'
import {
    confirmInviteToCompany,
    // getInviteClient,
    // getRemoveInviteToCompany,
    // removeUserFromCompany,
    // sendDetailToEmail
} from "../../redux/reducer/slices/personalSlice";
import {deliveryCycleInfoMessage} from "../../redux/reducer/slices/modalSlice";
import InnerProfile from "../../components/Personal/Profile/InnerProfile";
import {getProfile, logout, saveProfile} from "../../redux/reducer/slices/profileSlice";


export const Personal = ({
    logout,
    isLoading,
    getOrders,
    data,
    hasMore,
    isLoadingProfile,
    phone,
    name,
    settings,
    serverTime,
    resetOrders,
    serverError,
    profileErrorServer,
    errorSaveProfile,
    saveProfile
    }) => {
    
    return(<>
            <InnerProfile  resetOrders = {resetOrders}
                           serverTime = {serverTime}
                           settings = {settings}
                           name = {name}
                           phone = {phone}
                           isLoadingProfile = {isLoadingProfile}
                           hasMore = {hasMore}
                           data = {data}
                           isLoading = {isLoading}
                           getOrders = {getOrders}
                           deleteToken = {logout}
                           serverError = {serverError}
                           profileErrorServer = {profileErrorServer}
                           errorSaveProfile = {errorSaveProfile}
                           saveProfile = {saveProfile}
            />
        </>)
}


const mapToStateProps = (state) => {
    return {
        isLoading: state.orders.isLoading,
        serverTime: state.orders.serverTime,
        isLoadingProfile: state.profile.isLoading,
        phone: state.profile.phone,
        hasMore: state.orders.hasMore,
        data: state.orders.data,
        name: state.profile.name,
        settings: state.profile.settings,
        serverError: state.orders.errorServer,
        errorSaveProfile: state.profile.errorSaveProfile,
        profileErrorServer: state.profile.profileErrorServer,

    }
}
const mapDispatchToProps = dispatch => ({
    // getInviteClient: (essence, companyId) => dispatch(getInviteClient(essence, companyId)),
    // getRemoveInviteToCompany: (companyId, essence) => dispatch(getRemoveInviteToCompany(companyId, essence)),
    deliveryCycleInfoMessage: (actionUser, product) => dispatch(deliveryCycleInfoMessage(actionUser, product)),
    confirmInviteToCompany: (companyId) => dispatch(confirmInviteToCompany(companyId)),
    // removeUserFromCompany: (clientId, companyId, roles) => dispatch(removeUserFromCompany(clientId, companyId, roles)),
    // sendDetailToEmail: (companyId, dateFrom, dateTo, mailTo) => dispatch(sendDetailToEmail(companyId, dateFrom, dateTo, mailTo)),
    getOrders: () => dispatch(getOrders()),
    getProfile: () => dispatch(getProfile()),
    resetOrders: () => dispatch(resetOrders()),
    saveProfile: (name) => dispatch(saveProfile(name))

});
export default connect(mapToStateProps, mapDispatchToProps) (Personal)



