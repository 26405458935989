import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';
import { addActiveMessage, typesMessages } from './messagesSlice';

const initialState = {
    kitchenInfo: {},
    menu: null,
    isLoadingKitchenInfo: false,
    isLoadingMenu: false,
    errorsKitchenInfo: '',
    errorsMenu: ''
};

export const getMenuInfo = createAsyncThunk(
    'menu/getMenuInfo',
    async (request, thunkAPI) => {
        const api = new axiosRequest().instance;
        const { kitchenId } = request;

        try {
            const response = await api.post('kitchens/getKitchenInfo', {
                data: {
                    token: localStorage.getItem('token'),
                    city: { id: localStorage.getItem('city').id },
                    kitchenId: kitchenId,
                    clientInfo: {
                        os: 'android',
                        osVersion: '33',
                        model: 'M2101K6G',
                        brand: 'Redmi',
                        screenWidth: 1080,
                        deviceId: '69516350155354a2',
                        appVersion: '1.29.13',
                        appId: 'com.menu4me.dinner.sitiyoffice',
                        locale: 'ru_RU',
                    },
                },
            });

            if (response.data.result) {
                return response.data.data.kitchen;
            } else {
                return thunkAPI.rejectWithValue('Failed to fetch kitchen info');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.toString());
        }
    }
);

export const getMenu = createAsyncThunk(
    'menu/getMenu',
    async (request, thunkAPI) => {
        const api = new axiosRequest().instance;
        const { kitchenId, kitchenRacePolygonId, deliveryDay } = request;

        try {
            const response = await api.post('kitchens/getMenu', {
                data: {
                    token: localStorage.getItem('token'),
                    city: { id: thunkAPI.getState().settings.currentCity.id },
                    kitchenId: kitchenId,
                    kitchenRacePolygonId: kitchenRacePolygonId,
                    deliveryDay: deliveryDay,
                    clientInfo: {
                        os: 'android',
                        osVersion: '33',
                        model: 'M2101K6G',
                        brand: 'Redmi',
                        screenWidth: 1080,
                        deviceId: '69516350155354a2',
                        appVersion: '1.29.13',
                        appId: 'com.menu4me.dinner.sitiyoffice',
                        locale: 'ru_RU',
                    },
                },
            });

            return response.data;
        } catch (e) {
            thunkAPI.dispatch(addActiveMessage(typesMessages.MENU_DETAIL_ERROR_SERVER_MESSAGE));
            return thunkAPI.rejectWithValue(e.toString());
        }
    }
);

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMenuInfo.pending, (state) => {
                state.isLoadingKitchenInfo = true;
            })
            .addCase(getMenuInfo.fulfilled, (state, action) => {
                state.isLoadingKitchenInfo = false;
                state.kitchenInfo = action.payload;
            })
            .addCase(getMenuInfo.rejected, (state, action) => {
                state.isLoadingKitchenInfo = false;
                state.errorsKitchenInfo = action.payload;
                state.kitchenInfo = {};
                state.menu = null;
            })
            .addCase(getMenu.pending, (state) => {
                state.isLoadingMenu = true;
            })
            .addCase(getMenu.fulfilled, (state, action) => {
                state.isLoadingMenu = false;
                state.menu = action.payload;
            })
            .addCase(getMenu.rejected, (state, action) => {
                state.isLoadingMenu = false;
                state.errorsMenu = action.payload;
                state.menu = null;
                state.kitchenInfo = {};
            });
    },
});

export const {

} = menuSlice.actions;

export default menuSlice.reducer;
