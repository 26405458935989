import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import MoneyRed from '../../../../../images/Profile/MoneyRed.png';
import Bycycle from '../../../../../images/Profile/bicycle.png'
import Wallet from '../../../../../images/Profile/WalletPayed.png'
import {DetailContentItemOrder} from "../DetailContentItemOrder/DetailContentItemOrder";
import {DetailOrderModal} from "../../../../Modal/DetailOrderModal/DetailOrderModal";
import {Portal} from "@material-ui/core";
import {SwitchStatus} from "../SwitchStatus/SwitchStatus";
import {SwitchButton} from "../SwitchButton/SwitchButton";
import moment from "moment";
import {Timer} from "../../../../../share/ui/Timer/Timer";
import Info from '../../../../../images/Profile/info.png'
import {MonthSwitcher} from "../../../../../share/libs/MonthSwitcher";
import {TimeShower} from "../../../../../share/ui/TimeShower/TimeShower";
import {SwitcherTimeShower} from "../../../../../share/ui/TimeShower/types";
import {useTimer} from "../../../../../share/hooks/useTimer";
import {Loader} from "../../../../../utils/loader";


export const ContentItemOrder = ({ created, idGroup, pay, isFirst, delivery, ordersKitchen, shutoffKitchen, statusName, finalPrice , isPayed, status, serverTime, timeIsRangeOut}) => {
  const [open,setOpen] = useState(false)
    const date = new moment(delivery.deliveryDay*1000)
    let shutOffKitchenMoment = new moment(shutoffKitchen*1000)
    let serverTimeMoment = new moment(serverTime*1000)
    let difference =  moment.duration(shutOffKitchenMoment.diff(serverTimeMoment))
     // const testShutOffTime = moment("2024-03-20 18:00",       "YYYY-MM-DD HH:mm")
     // const testServerTime = moment()
     // const testDifference = moment.duration(testShutOffTime.diff(testServerTime))
    const { time, canShow, timeEnd, showSeconds} = useTimer(shutOffKitchenMoment, serverTimeMoment)
   const clickHandler = () => {
      setOpen((prevState) => !prevState)
    }
    return (<>

            <ContentItemOrderContainer isFirst = {isFirst}>
                <HeaderOrder>
                    <Header>Заказ № {idGroup}</Header>
                    <SwitchStatus statusName={statusName} status={status}/>
                    <p onClick={clickHandler}>Детальный просмотр</p>
                </HeaderOrder>
                <TimeContainer>
                    <img alt ='' src = {Bycycle}/>
                    <p> {date.date()} {MonthSwitcher(date.month(), 'month')} {delivery.race.timeStart} - {delivery.race.timeEnd}</p>
                </TimeContainer>
                <AdressContainer>
                    {
                        Object.values(ordersKitchen).map((element, index) => <InfoAdressContainer isFirst = {index === 0}>{element.kitchenName}</InfoAdressContainer>)
                    }
                </AdressContainer>

                 <InfoContainer>
                        <InfoInnerContainer>
                            {
                                timeIsRangeOut ?
                                    undefined
                                    :
                                    difference.isRestMoreHour(difference) ?
                                     undefined :
                                     <>

                                        {
                                            timeEnd ? undefined
                                                :
                                                canShow ?
                                                    showSeconds?
                                                        <>
                                                            <img alt='' src = {Info}/>
                                                            <p style ={{display: 'flex', margin: '0 4px 0 0'}}>Отменить заказ возможно <span>до</span> <TimeShower title={''} type={SwitcherTimeShower.FULL_TIMER} hours={shutOffKitchenMoment.hours()} minutes={shutOffKitchenMoment.minute()}/></p>
                                                            <Timer title={'осталось'} color = 'EA394B' timeString = 'seconds'  time={time[0][1]}/>
                                                        </>
                                                        :
                                                        <>
                                                            <img alt='' src = {Info}/>
                                                            <p style ={{display: 'flex', margin:  '0 4px 0 0'}}>Отменить заказ возможно <span>до</span> <TimeShower title={''} type={SwitcherTimeShower.FULL_TIMER} hours={shutOffKitchenMoment.hours()} minutes={shutOffKitchenMoment.minute()}/></p>
                                                            (<Timer title={'осталось'} color = 'EA394B' timeString = 'minutes'  time={time[1][1]}/>)
                                                        </>

                                                    :
                                                    <Loader/>

                                        }
                                     </>


                            }


                        </InfoInnerContainer>
                    </InfoContainer>



                <FooterContainer>
                    <PayContainer>
                        <p> {finalPrice}₽ </p>
                        {
                            isPayed? <InnerPayContainer isPayed = {isPayed}>
                                    <p>Оплачен</p>
                                    <img alt = '' src = {Wallet}/>
                                </InnerPayContainer> :
                                <InnerPayContainer isPayed = {isPayed}>
                                    <p>Ожидает оплату</p>
                                    <img alt = '' src = {MoneyRed}/>
                                </InnerPayContainer>
                        }
                    </PayContainer>
                    {
                       <SwitchButton timeIsRangeOut timeEnd = {timeEnd} isPayed={isPayed} currentTime={serverTime} shutOffTime={shutoffKitchen} status={status}/>
                    }
                </FooterContainer>
            </ContentItemOrderContainer>


        {
            open ?  <Portal>
                <DetailOrderModal  changeAuthOpen={clickHandler} isAuthOpen={open}>
                    <DetailContentItemOrder
                        day={date.date()}
                        month={MonthSwitcher(date.month()+1)}
                        timeStart={delivery.race.timeStart} timeEnd={delivery.race.timeEnd}
                        created = {created}
                        delivery = {delivery}
                        idGroup={idGroup}
                        statusName={statusName}
                        ordersKitchen = {ordersKitchen}
                    />

                </DetailOrderModal>
            </Portal> : undefined
        }

        </>)
}




const ContentItemOrderContainer = styled.div`
  max-width: 787px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding:32px;
  margin-top: ${({isFirst}) => isFirst ? 0 : '16px'};
  
`;

const HeaderOrder = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    border-bottom: 1px solid #333333;
  }

`

const Header = styled.div `
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;


`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  
  img {
    margin-left: 10px;
  }
  
  p {
    margin: 0;
    border: none;
  }
  

`

const TimeContainer = styled.div`
 display: flex;
  margin-top: 16px;
  p {
    margin: 0 0 0 10px;
    
  }

`

const AdressContainer = styled.div`
 display: flex;
  margin-top: 16px;

`
const InfoAdressContainerFirst = styled.div`
  border: 1px solid #CDCDCD;
  border-radius: 16px;
  padding: 10px;
  text-align: center;

`
const InfoAdressContainer = styled.div`
  border: 1px solid #CDCDCD;
  border-radius: 16px;
  padding: 10px;
  text-align: center;
  margin-left: ${({isFirst}) => isFirst? '0': '10px' };

`

const InfoContainer = styled.div `
  margin-top: 16px;
   p {
     font-family: Montserrat;
     font-size: 16px;
     font-weight: 400;
     line-height: 21px;
     letter-spacing: -0.7852098345756531px;
     color: #A4A4A4;
     margin-top: 8px;
     max-width: 372px;
   }
`

const InfoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.7852098345756531px;
    margin: 0 0 0 16px;
    color: #2C2E32;
  }
  span {
    display: block;
    margin: 0 5px;
  }
`

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  

`

const PayContainer = styled.div`

  p {
    font-family: SF Pro Display;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: start;
  }

`

const InnerPayContainer = styled.div`
   display: flex;
  align-items: center;
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.7852098345756531px;
    text-align: right;
    margin: 0;
    color: ${({isPayed}) => isPayed?  '#5D9953' : '#EA394B' };
  }
  img {
    margin-left: 10px;
  }
`

const ButtonCancel = styled.button`
  border-radius: 16px;
  background-color: #F1F1F1;
  padding: 10px;
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.7852098345756531px;
  text-align: left;
  border: none;


`
