
const StylesObject = {
    mainClass: null,
    additionalClassString: [],
    additionalClassBoolean: {key: ''}
}
export const useClassName = (StylesObject) => {
    if (!StylesObject.additionalClassBoolean) {
        StylesObject.additionalClassBoolean = {}
    }
    if (!StylesObject.additionalClassString) {
        StylesObject.additionalClassString = []
    }
   return [StylesObject.mainClass && StylesObject.mainClass ,
       ...StylesObject.additionalClassString,
       ...Object.entries(StylesObject.additionalClassBoolean).filter(([key,value]) => value).map(([key,value]) => key),
   ].join(' ')
}
