import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart, removeProductFromCart } from '../redux/reducer/slices/cartSlice';
import styled from "styled-components";

const OrdersProductCard = ({ product }) => {
  const { products } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(product.amountPerOrder);

  useEffect(() => {
    const matchingProducts = products.filter(item => item.id === product.id);
  
    const totalQuantity = matchingProducts.reduce((sum, item) => sum + item.quantity, 0);
    
    setCount(product.quantity);
    setAvailableCount(product.amountPerOrder - totalQuantity);
  }, [products, product]);

  const handleClickIncrementCount = () => {
    if (availableCount > 0) {
      dispatch(addProductToCart(product));
    }
  };

  const handleClickDecrementCount = () => {
    if (count > 1) {
      dispatch(removeProductFromCart({ productId: product.id, removeAll: false }));
    } 
  };

  const handleRemoveProduct = () => {
    dispatch(removeProductFromCart({ productId: product.id, removeAll: true }));
  };

  if (!product) {
    return null;
  }

  return (
    <Card>
      <ImageContainer>
        <Image src={product.pictures.small[0]} alt={product.name} />
      </ImageContainer>
      <InfoContainer>
        <ProductName>{product.name}</ProductName>
        <ProductDetails>{product.weight} г</ProductDetails>
        {!product.isLocked ? (
          <QuantityControl>
            <Button onClick={handleClickDecrementCount}>-</Button>
            <Quantity>{product.quantity}</Quantity>
            <Button onClick={handleClickIncrementCount}>+</Button>
            <InfoBadge>
              доступно: {availableCount} шт
            </InfoBadge>
          </QuantityControl>
        ) : (
          <Quantity>{product.quantity} шт</Quantity>
        )}
      </InfoContainer>
      <PriceContainer>
        <Price>{product.price * product.quantity} ₽</Price>
        {!product.isLocked &&
          <DeleteButton onClick={handleRemoveProduct}>
            <svg width="32" height="32" viewBox="0 0 32 32" stroke-width="1.25" fill="none" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6665 19.334L12.6665 15.334"/>
              <path d="M19.3333 19.334L19.3333 15.334"/>
              <path d="M4 8.66699H28V8.66699C25.9654 8.66699 24.948 8.66699 24.26 9.22413C24.1243 9.33406 24.0004 9.45793 23.8905 9.59369C23.3333 10.2817 23.3333 11.299 23.3333 13.3337V21.0003C23.3333 23.3573 23.3333 24.5359 22.6011 25.2681C21.8689 26.0003 20.6904 26.0003 18.3333 26.0003H13.6667C11.3096 26.0003 10.1311 26.0003 9.3989 25.2681C8.66667 24.5359 8.66667 23.3573 8.66667 21.0003V13.3337C8.66667 11.299 8.66667 10.2817 8.10953 9.59369C7.99959 9.45793 7.87573 9.33406 7.73997 9.22413C7.05196 8.66699 6.03464 8.66699 4 8.66699V8.66699Z"/>
              <path d="M12.6666 4.66764C12.6666 4.66764 13.3333 3.33398 15.9999 3.33398C18.6666 3.33398 19.3333 4.66732 19.3333 4.66732"/>
            </svg>
          </DeleteButton>
        }
      </PriceContainer>
    </Card>
  );
};

export default OrdersProductCard;

const Card = styled.div`
  display: flex;
  padding: 25px 0;
  border-bottom: 1px solid #ddd;
`;

const ImageContainer = styled.div`
  flex: 0 0 136px;
  margin-right: 15px;
  border-radius: 16px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.div`
  font-size: 18px;
  color: #464646;
  margin-bottom: 5px;
`;

const ProductDetails = styled.div`
  font-size: 16px;
  color: #A4A4A4;
  margin: 10px;
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: auto;
`;

const Button = styled.button`
  width: 36px;
  height: 36px;
  padding: 5px;
  background: #FAFAFA;
  border: 1px solid #F3F3F3;
  border-radius: 8px;
  font-size: 18px;
  color: #111111;
  text-align: center;
  transition: all .2s ease;
  &:hover {
    background-color: #E4E3E3;
  }
`;

const Quantity = styled.div`
  margin: 0 10px;
  font-size: 18px;
  font-weight: 400;
  color: #464646;
`;

const InfoBadge = styled.div`
  font-size: 16px;
  color: #A4A4A4;
  margin-left: 20px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: #2C2E32;
  margin-bottom: 10px;
`;

const DeleteButton = styled.button`
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
  
  svg {
    stroke: #333333;
    transition: all .2s ease;

    &:hover {
      stroke: #EB273B;
    }
  }
`;
