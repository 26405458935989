import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  removeOrder,
  lockedOrder,
  setMultiplier,
  renameOrder,
} from '../redux/reducer/slices/cartSlice';
import styled from 'styled-components';
import OrdersProductCard from './OrdersProductCard';
import { Switcher } from "../common/switcher/Switcher";
import { Link } from 'react-router-dom';
import Button from './Button';
import InputButton from './InputButton';

const OrdersContainer = () => {
  const { products } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  // const { multiplier, setMultiplier } = useState(1);
  
  // Группируем заказы по order
  const groupedProducts = products.reduce((acc, product) => {
      const order = product.order;
      if (!acc[order]) {
          acc[order] = [];
      }
      acc[order].push(product);
      return acc;
  }, {});

  console.log(products)
  console.log(groupedProducts)

  const handleRemoveOrder = (order) => {
    dispatch(removeOrder(order));
  }

  const handleLockedOrder = (order) => {
    dispatch(lockedOrder(order));
  }

  const handleMultiplyOrder = (order, value) => {
    dispatch(setMultiplier({order: order, multiplier: value}));
  }

  const handleRenameOrder = (order, value) => {
    dispatch(renameOrder({ order: order, newOrderName: value }));
  }
  return (
    <>
      {Object.keys(groupedProducts).map(order => {
        const totalOrderPrice = groupedProducts[order].reduce((acc, item) => acc + item.price * item.quantity, 0);
        const orderIsLocked = groupedProducts[order].every((item) => item.isLocked === true);
        // const orderName = groupedProducts[order].every((item) => item?.orderName === '');
      
        return (
          <Order key={order}>
            <RemoveOrder onClick={() => handleRemoveOrder(order)}>
              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" stroke-width="1.9375" stroke-linecap="square" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0002 8.16113L8.00024 24.1611"/>
                <path d="M8.00024 8.16113L24.0002 24.1611"/>
              </svg>
            </RemoveOrder>
            <Title>Заказ №{order} {groupedProducts[order][0].orderName !== '' && `"${groupedProducts[order][0].orderName}"`}</Title>
            <KitchenLink to={'#'}>Кухня {groupedProducts[order][0].kitchenId}</KitchenLink>
            {groupedProducts[order].map(product => (
              <OrdersProductCard key={product.productId} product={product} />
            ))}
            <Cutlery>
              <CutleryInfo>
                <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.01619 -0.547276C3.95877 -0.492992 3.95398 -0.378998 3.89655 2.09904C3.85348 3.98809 3.79605 5.85272 3.73384 7.40523C3.70991 7.96706 3.65727 8.18148 3.51369 8.27919L3.42277 8.34161L3.34619 8.23305C3.20741 8.04848 3.18827 7.84221 3.13084 6.14314C3.10212 5.23118 3.05905 3.85238 3.03512 3.07613C3.01119 2.29988 2.98727 1.23593 2.98248 0.714811C2.97769 -0.267715 2.95377 -0.522848 2.86284 -0.539133C2.80062 -0.54999 2.73841 -0.430567 2.59962 -0.0451546C2.50869 0.204548 2.49912 0.383682 2.46084 2.22117C2.38905 5.51888 2.29334 7.38623 2.16891 7.85035C2.03012 8.36333 1.71427 8.43118 1.27398 8.04305L1.15434 7.93449L1.21177 4.73178C1.24527 2.97028 1.28834 1.21693 1.30748 0.834234C1.35055 0.0932674 1.31227 -0.449566 1.22134 -0.501135C1.13998 -0.547276 1.04427 -0.465851 0.924624 -0.248716C0.790624 -0.00172806 0.608767 1.16265 0.379053 3.29327C0.340767 3.66511 0.254624 4.4305 0.187625 4.98962C-0.0995182 7.42966 -0.0612325 8.63474 0.331196 9.56299C0.541767 10.0705 0.819338 10.4098 1.26441 10.7029C2.18805 11.3109 2.74798 12.079 2.89155 12.9286C2.92505 13.1267 2.93941 15.7051 2.93941 20.5146V27.8022L3.26005 27.8103C3.44191 27.8157 3.67641 27.8103 3.78648 27.8022L3.99226 27.7859L4.00662 20.2622L4.02098 12.7386L4.12148 12.4807C4.40862 11.7587 4.87284 11.2051 5.57634 10.7382C6.19369 10.3338 6.50476 9.90497 6.71534 9.16943C6.94983 8.3769 6.96419 7.00896 6.76798 5.34246C6.71534 4.88648 6.60526 3.93381 6.52869 3.22541C6.25112 0.750095 6.13148 0.00641441 5.97355 -0.278572C5.83476 -0.525562 5.75341 -0.579845 5.65769 -0.487564C5.57634 -0.408854 5.57634 0.345684 5.65769 2.58759C5.68641 3.49683 5.71512 5.07919 5.71512 6.10514V7.96706L5.56676 8.09462C5.38969 8.2439 5.18391 8.31718 5.04991 8.27376C4.91112 8.23305 4.77234 7.9942 4.71491 7.70378C4.63355 7.29937 4.57612 6.31142 4.51869 4.44679C4.38948 0.0769825 4.38948 0.142122 4.27941 -0.151007C4.15498 -0.48485 4.07362 -0.601559 4.01619 -0.547276Z" fill="black"/>
                  <path d="M13.7651 -0.506878C13.3774 -0.411882 12.7792 0.0603828 12.5112 0.481079C12.0422 1.21662 11.5972 2.4977 10.8171 5.38286C10.2715 7.39948 10.171 7.90703 10.1327 8.86242C10.0753 10.4014 10.439 11.2645 11.5493 12.2008C12.0662 12.6351 12.5064 12.9174 13.052 13.1671C13.7747 13.4955 13.8847 13.6013 13.9948 14.0546C14.1097 14.516 14.1336 15.9925 14.1384 21.7547V27.8154H14.5691H14.9998V13.8456V-0.121466L14.8467 -0.257174C14.5499 -0.517735 14.1719 -0.607302 13.7651 -0.506878Z" fill="black"/>
                </svg>
                <p>Приборы</p>
              </CutleryInfo>
              <Switcher additionalClassString={['Switcher_Theme_Red']} disabled={orderIsLocked}/>
            </Cutlery>
            <Settings>
              <SettingsInfo>Настройки заказа</SettingsInfo>
              <SettingsActions>
                <Button type='text' onClick={() => handleLockedOrder(order)}>
                  {orderIsLocked ?
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="24" fill="#BED7AA"/>
                      <path d="M19.3333 30.6676C19.3333 27.9964 19.3333 26.6607 20.1631 25.8308C20.993 25.001 22.3286 25.001 24.9999 25.001H34.9999C37.6712 25.001 39.0069 25.001 39.8367 25.8308C40.6666 26.6607 40.6666 27.9964 40.6666 30.6676V32.501C40.6666 36.5079 40.6666 38.5114 39.4218 39.7562C38.177 41.001 36.1735 41.001 32.1666 41.001H27.8333C23.8263 41.001 21.8228 41.001 20.578 39.7562C19.3333 38.5114 19.3333 36.5079 19.3333 32.501V30.6676Z" stroke="#517633" stroke-width="1.41667"/>
                      <path d="M35.3332 23.6667V22.3333C35.3332 19.3878 32.9454 17 29.9998 17V17C27.0543 17 24.6665 19.3878 24.6665 22.3333V23.6667" stroke="#517633" stroke-width="1.41667" stroke-linecap="round"/>
                      <circle cx="30.0002" cy="33.0007" r="2.66667" fill="#517633"/>
                    </svg>
                  :
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.658537" y="0.658537" width="58.6829" height="58.6829" rx="23.3415" fill="white" stroke="#C9C4C4" stroke-width="1.31707"/>
                      <path d="M20.6665 29.8519C20.6665 27.6789 20.6665 26.5924 21.3416 25.9173C22.0167 25.2422 23.1032 25.2422 25.2763 25.2422H34.7234C36.8965 25.2422 37.983 25.2422 38.6581 25.9173C39.3332 26.5924 39.3332 27.6789 39.3332 29.8519V32.3276C39.3332 35.5871 39.3332 37.2169 38.3205 38.2296C37.3079 39.2422 35.6781 39.2422 32.4185 39.2422H27.5811C24.3215 39.2422 22.6918 39.2422 21.6791 38.2296C20.6665 37.2169 20.6665 35.5871 20.6665 32.3276V29.8519Z" stroke="#2C2E32" stroke-width="1.15244"/>
                      <path d="M35.2495 25.2428L35.34 24.5188C35.6421 22.1019 34.5546 19.7215 32.5298 18.3677V18.3677C30.119 16.7557 26.928 16.9544 24.7358 18.8529L23.7811 19.6797" stroke="#2C2E32" stroke-width="1.15244" stroke-linecap="round"/>
                      <circle cx="29.9998" cy="32.2415" r="2.33333" fill="#2C2E32"/>
                    </svg>
                  }
                </Button>
                <InputButton type='number' data={order} onSave={handleMultiplyOrder}>
                  <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.981445" width="60" height="60" rx="24" fill="#C0DAEC"/>
                    <path d="M34.0001 17.9814H26.0001C23.0546 17.9814 20.6667 20.3693 20.6667 23.3148V33.9814" stroke="#557788" stroke-width="1.31707"/>
                    <path d="M25.3253 29.3148C25.3253 27.7357 25.3263 26.6066 25.4245 25.7355C25.5212 24.8778 25.7051 24.3522 26.0158 23.9473C26.1936 23.7156 26.4009 23.5083 26.6326 23.3305C27.0375 23.0198 27.5631 22.8359 28.4208 22.7392C29.2919 22.641 30.421 22.64 32.0001 22.64C33.5791 22.64 34.7083 22.641 35.5793 22.7392C36.4371 22.8359 36.9627 23.0198 37.3676 23.3305C37.5992 23.5083 37.8066 23.7156 37.9844 23.9473C38.295 24.3522 38.479 24.8778 38.5757 25.7355C38.6739 26.6066 38.6749 27.7357 38.6749 29.3148V34.6481C38.6749 36.2271 38.6739 37.3563 38.5757 38.2274C38.479 39.0851 38.295 39.6107 37.9844 40.0156C37.8066 40.2473 37.5992 40.4546 37.3676 40.6324C36.9627 40.9431 36.4371 41.127 35.5793 41.2237C34.7083 41.3219 33.5791 41.3229 32.0001 41.3229C30.421 41.3229 29.2919 41.3219 28.4208 41.2237C27.5631 41.127 27.0375 40.9431 26.6326 40.6324L26.2317 41.1549L26.6326 40.6324C26.4009 40.4546 26.1936 40.2473 26.0158 40.0156C25.7051 39.6107 25.5212 39.0851 25.4245 38.2274C25.3263 37.3563 25.3253 36.2271 25.3253 34.6481V29.3148Z" stroke="#557788" stroke-width="1.31707"/>
                  </svg>
                </InputButton>
                <InputButton data={order} onSave={handleRenameOrder}>
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.658781" y="0.658537" width="58.6829" height="58.6829" rx="23.3415" fill="white" stroke="#C9C4C4" stroke-width="1.31707"/>
                    <path d="M21.4711 32.544L22.0436 32.8695L21.4711 32.544C21.3369 32.7801 21.2718 33.0404 21.1983 33.3343C21.1934 33.354 21.1885 33.3738 21.1835 33.3938L20.2957 36.9281C20.2927 36.9398 20.2898 36.9515 20.2868 36.9633C20.2349 37.1696 20.1795 37.3899 20.1612 37.575C20.141 37.7788 20.1453 38.1226 20.4208 38.3987C20.6963 38.6748 21.0401 38.6799 21.2439 38.6601C21.429 38.6422 21.6495 38.5873 21.8559 38.5358C21.8677 38.5329 21.8794 38.53 21.8911 38.5271L25.453 37.6408L25.4531 37.6409L25.4584 37.6394C25.4764 37.635 25.4944 37.6305 25.5122 37.6261C25.8049 37.5536 26.0641 37.4894 26.2996 37.3566C26.535 37.2238 26.724 37.0353 26.9376 36.8223C26.9519 36.8081 26.9662 36.7937 26.9808 36.7793L36.589 27.2163L36.6199 27.1856C37.0355 26.772 37.3935 26.4157 37.6416 26.0919C37.9075 25.745 38.1089 25.3589 38.1089 24.8825C38.1089 24.4062 37.9075 24.02 37.6416 23.6731C37.3935 23.3493 37.0355 22.993 36.6199 22.5794L36.589 22.5488L36.1245 23.0155L36.589 22.5488L36.3231 22.2841L36.2925 22.2536C35.8805 21.8435 35.5255 21.4902 35.2031 21.2452C34.8574 20.9827 34.4733 20.784 34.0003 20.784C33.5273 20.784 33.1432 20.9827 32.7976 21.2452C32.4751 21.4902 32.1201 21.8436 31.7082 22.2536L31.6776 22.2841L22.0542 31.8621C22.0396 31.8767 22.0251 31.891 22.0107 31.9053C21.7957 32.1189 21.6054 32.3079 21.4711 32.544Z" stroke="#2C2E32" stroke-width="1.31707"/>
                    <path d="M30.667 22.8914L34.667 20.2373L38.667 24.2185L36.0003 28.1996L30.667 22.8914Z" fill="#2C2E32"/>
                  </svg>
                </InputButton>
              </SettingsActions>
            </Settings>
            <OrderTotalPrice>
              {groupedProducts[order][0].multiplier > 1 ? (
                <>
                  <OrderTotalPriceValue>{totalOrderPrice} ₽ x {groupedProducts[order][0].multiplier}</OrderTotalPriceValue>
                  <OrderTotalPriceValue>{totalOrderPrice * groupedProducts[order][0].multiplier} ₽</OrderTotalPriceValue>
                </>
              ) : (
                <>
                  <div></div>
                  <OrderTotalPriceValue>{totalOrderPrice} ₽</OrderTotalPriceValue>
                </>
              )}
            </OrderTotalPrice>
          </Order>
        )
      })}
    </>
  );
};

export default OrdersContainer;

const Order = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  padding: 50px 60px;
  background: #ffffff;
  border: 1px solid #EAE9E9;
  border-radius: 24px;
`;

const RemoveOrder = styled.button`
  position: absolute;
  top: 35px;
  right: 35px;
  width: fit-content;
  
  svg {
    stroke: #333333;
    transition: all .2s ease;

    &:hover {
      stroke: #EB273B;
    }
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #2C2E32;
`;

const KitchenLink = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #2C2E32;
`;

const Cutlery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
`;

const CutleryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 24px;
  font-weight: 500;
  color: #464646;
`;

const Settings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
`;

const SettingsInfo = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: rgba(70, 70, 70, 0.5);
`;

const SettingsActions = styled.div`
  display: flex;
  gap: 24px;
`;

const OrderTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  border-top: 1px solid #ddd;
`;

const OrderTotalPriceValue = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: #2C2E32;
`;