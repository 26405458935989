import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import { axiosRequest } from '../../../utils/axiosRequest';
import { sha256 } from '../../../utils/sha256';

const initialState = {
    phone: null,
    name: null,
    phoneSend: false,
    errorPhone: false,
    errorPhoneServer: false,
    errorCode: false,
    errorCodeServer: false,
    errorSaveProfile: false,
    profileErrorServer: false,
    session: null,
    token: null,
    isAuth: false,
    isLoading: false,
    isAuthModalOpen: false,
};

export const sendCodeToTelephone = createAsyncThunk(
    'profile/sendCodeToTelephone',
    async (value, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        
        try {
            const response = await api.post('auth/sendCodeToPhone?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    phone: value,
                    device: 'TestId',
                },
            });
            if (response.data.result) {
                localStorage.setItem('phone', sha256(value));
                if (response.data.data.session !== '') {
                    localStorage.setItem('session', response.data.data.session);
                }
                return { session: response.data.data.session };
            }
        } catch (error) {
            return rejectWithValue('Server error');
        }
    }
);

export const createTokenBySession = createAsyncThunk(
    'profile/createTokenBySession',
    async (value, { dispatch, rejectWithValue }) => {
        const api = new axiosRequest().instance;
        localStorage.setItem('code', sha256(value));
        const session = localStorage.getItem('session');

        try {
            const response = await api.post('auth/createTokenBySession?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    session: session,
                    code: value,
                },
            });

            if (response.data.result) {
                localStorage.setItem('token', response.data.data.token);
                dispatch(getProfile());
                return response.data;
            } else {
                return rejectWithValue('Invalid code');
            }
        } catch (error) {
            return rejectWithValue('Server error');
        }
    }
);

export const logout = createAsyncThunk(
    'profile/logout',
    async (_, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        const token = localStorage.getItem('token');

        try {
            await api.post('auth/logout?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    token: token,
                },
            });

            localStorage.removeItem('token');
            localStorage.removeItem('session');
            localStorage.removeItem('codeHash');
        } catch (err) {
            return rejectWithValue('Failed to logout');
        }
    }
);

export const getProfile = createAsyncThunk(
    '/profile/getProfile',
    async (_, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.post('profile/get?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    token: localStorage.getItem('token'),
                },
            });

            if (response.data.result) {
                return response.data.data;
            } else {
                return rejectWithValue('Failed to load profile');
            }
        } catch (error) {
            return rejectWithValue('Server error');
        }
    }
);

export const saveProfile = createAsyncThunk(
    'profile/saveProfile',
    async (name, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.post('profile/save?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    token: localStorage.getItem('token'),
                    name: name,
                },
            });

            if (response.data.result) {
                return true;
            } else {
                return rejectWithValue('Failed to save profile');
            }
        } catch (error) {
            return rejectWithValue('Server error');
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        changeTelephone: (state) => {
            state.phoneSend = false;
        },
        setIsAuthModalOpen: (state) => {
            state.isAuthModalOpen = true;
        },
        setIsAuthModalClose: (state) => {
            state.isAuthModalOpen = false;
            state.phoneSend = false;
            state.errorPhone = false;
            state.errorPhoneServer = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => {
                return initialState;
            })
            .addCase(sendCodeToTelephone.pending, (state) => {
                console.log('bilder')
                state.phoneSend = false;
                state.errorPhone = false;
                state.errorPhoneServer = false;
            })
            .addCase(sendCodeToTelephone.fulfilled, (state, action) => {
                state.phoneSend = true;
                if (action.payload) {
                    state.session = action.payload.session;
                }
            })
            .addCase(sendCodeToTelephone.rejected, (state, action) => {
                state.errorPhoneServer = action.payload === 'Server error';
                state.errorPhone = action.payload === 'Invalid code';
            })

            .addCase(createTokenBySession.pending, (state) => {
                state.errorCode = false;
                state.errorCodeServer = false;
            })
            .addCase(createTokenBySession.fulfilled, (state, action) => {
                state.token = action.payload.data.token;
                state.isAuth = true;
            })
            .addCase(createTokenBySession.rejected, (state, action) => {
                state.errorCodeServer = action.payload === 'Server error';
                state.errorCode = action.payload === 'Invalid code';
            })

            .addCase(logout.fulfilled, (state) => {
                state.phone = null;
                state.name = null;
                state.isAuth = false;
                state.phoneSend = false;
            })
            .addCase(getProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.phone = action.payload.phone;
                state.name = action.payload.name;
                state.isLoading = false;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.profileErrorServer = action.payload === 'Server error';
            })
            .addCase(saveProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveProfile.fulfilled, (state) => {
                state.isLoading = false;
                state.errorSaveProfile = false;
            })
            .addCase(saveProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.errorSaveProfile = true;
            });
    },
});

export const {
  changeTelephone,
  setIsAuthModalOpen,
  setIsAuthModalClose,
} = profileSlice.actions;

export default profileSlice.reducer;
