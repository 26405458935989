import React, {useState} from 'react'
import styled from "styled-components";
import Switch from "@material-ui/core/Switch";
import {Loader} from "../../../../utils/loader";
import InputMask from "react-input-mask";
import {useDispatch} from "react-redux";

export const MySettings = ({isLoadingProfile, name, phone, profileErrorServer, errorSaveProfile, saveProfile, settings = {notifications: {}}}) => {
    const [Notice, setNotice] = useState(Boolean(settings.notifications.isEnabledNotice) || true)
    const [Service, setService] = useState( Boolean ( settings.notifications.isEnabledService ) || true)
    const [Name, setName] = useState(name)
    const changeHandlerNotice = () => {
        setNotice( (prevState) => !prevState)
    }
    const changeHandlerService = () => {
        setService( (prevState) => !prevState)
    }
    const changeHandlerName = (e) => {
       setName(e.target.value)
    }
    const clickHandler = () => {
        saveProfile(Name)

    }
    return (<>
        {
            isLoadingProfile ? <Loader/> : profileErrorServer ? <ErrorMessage>На сервере произошла ошибка. Пожалуйста, повторите попытку позже.</ErrorMessage> :
                <SettingsContainer>
                    <InnerContainer>
                        <Header>Настройки</Header>
                        <InputPhone mask="+7 999 999 99 99"  disabled = {true} value = {phone} />
                        <InputName onChange = {changeHandlerName} value = {Name} placeholder = 'Имя'/>
                        <Header>Уведомления</Header>
                        <NotificationContainer>
                            <p>Получать сервисные
                                PUSH-уведомления</p>
                            <SwitchCustom
                                onChange = {changeHandlerNotice}
                                checked={Notice}/>
                        </NotificationContainer>

                        <NotificationContainer>
                            <p>Получать информационные
                                PUSH-уведомления</p>
                            <SwitchCustom
                                onChange = {changeHandlerService}
                                checked = {Service}/>
                        </NotificationContainer>
                    </InnerContainer>
                    <ButtonDelete onClick = {clickHandler} isDisable = {Name === null || Name.length === 0} disable = {true}>Сохранить</ButtonDelete>
                </SettingsContainer>
        }


        </>)
}


const SettingsContainer = styled.div` 
    display: flex;
    flex-direction: column;
    max-width: 787px;
    width: 100%;
    padding:  24px 48px;
    border-radius: 16px;
    height: 518px;
    background-color: #FFFFFF;
    `

const InnerContainer = styled.div `
  width: 100%;
  border-bottom: 1px solid #F1F1F1;
  padding: 28px 0;
  
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 24px 0 16px 0;
    color: #A9A9A9;
    

  }
  
`

const Header = styled.h1 `
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  color: #2C2E32;
  

`

const InputName = styled.input `
  font-family: Montserrat;
  max-width: 492px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #A9A9A9;
  border-radius: 16px;
  border: 1px solid #E0E0E0;
  padding: 19px 24px;
  background-color: #FBFBFB;
  outline: none;
  margin-bottom: 32px;

`

const NotificationContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.44200000166893005px;
    max-width: 256px;
    margin: 0;
  }
  
  

`

const SwitchCustom = styled(Switch)(({ theme }) => ({
    width: '90px !important',
    height: '60px !important',
    '&.Mui-disabled + .MuiSwitch-track': {
        opacity: '0 !important'
    },
    '& .MuiSwitch-switchBase': {
        padding: 16,
        backgroundColor: 'transparent !important',
        border: 'none !important',
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 27,
        height: 27,
        backgroundColor: 'white',
    },
    '& .MuiSwitch-track': {
        borderRadius: 26,
        height: '100%',
        opacity: 1,
        backgroundColor: '#787880',

    },

    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#EA394B',
        opacity: 1,
    }
}));

const ButtonDelete = styled.button`
  border: none;
  background-color: #FFFFFF;
  cursor: ${({isDisable}) => isDisable ? 'none' : 'pointer'};
  color: ${({isDisable}) => isDisable ? '#969696' : '#EA394B'};
  width: 100%;
  text-align: center;
  padding: 15px;
  

`

const InputPhone = styled(InputMask)`
  border: none;
  display: block;
  background-color: transparent;
  outline: none;
  margin-bottom: 16px;
  padding: 0;
  color: #A9A9A9;
  font-weight: 600;
  max-width: 130px;
  
`;

const ErrorMessage = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #EA394B



`



