import React from 'react';
import styled from 'styled-components';
import ProductCard from './ProductCard';

const ProductsContainer = ({ data }) => {
    const { products, structure } = data;

    return (
        <Container>
            {structure.map((category, index) => (
                <CategoryContainer key={index}>
                    <CategoryTitle>{category.structureName}</CategoryTitle>
                    <ProductsGrid>
                        {category.products.map(productId => {
                            const product = products[productId];
                            return (
                                <ProductCard 
                                    key={productId} 
                                    product={product} 
                                />
                            );
                        })}
                    </ProductsGrid>
                </CategoryContainer>
            ))}
        </Container>
    );
};

export default ProductsContainer;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
`;

const CategoryContainer = styled.div`
  margin-bottom: 40px;
`;

const CategoryTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #2C2E32;
  margin-bottom: 20px;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;