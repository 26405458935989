import React from 'react';
import styled from "styled-components";
import {TypesPlane} from "../types";
import {MyPoints} from "./MyPoints";
import {MyBill} from "./MyBill";
import {CreatePlane} from "./CreatePlane";

export const Plane = ({title, subtitle, points, types, isFirst}) => {
    const SwitchType = (types) => {
         switch (types) {
             case TypesPlane.MY_POINTS:
                 return <MyPoints title = {title} subtitle={subtitle} points={points}/>
             case TypesPlane.MY_CHECK:
                 return <MyBill title={title} points={points}/>
             case  TypesPlane.CREATE_PLANE:
                 return <CreatePlane/>
             default:
                 return null
         }
    }

    return(<>
       <PlaneConatiner isFirst = {isFirst} types = {types}>
           {SwitchType(types)}
       </PlaneConatiner>

        </>)

}


const PlaneConatiner = styled.div`
  width: 100%;
  max-width: 384px;
  height: 229px;
  padding: 10px 24px;
  border-radius: 16px;
  font-family: Montserrat;
  font-weight: 500;
  background-color: ${({types}) => types === TypesPlane.CREATE_PLANE ? '#C0DAEC' : '#FFFFFF'};
  box-shadow: #0000000D;
  margin-top: ${({isFirst}) => isFirst ? '0px' : '15px'};
`

