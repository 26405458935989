import React from 'react'
import styled from "styled-components";
export const NoData = ({title,text,img}) => {
    return (<>
     <MainContainer>
          <ImgCustom src = {img}/>
          <Title>{title}</Title>
          <Text>{text}</Text>
     </MainContainer>
        </>)
}


const MainContainer = styled.div` 
    display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
   text-align: center;
  
    
    
`

const ImgCustom = styled.img` 
    
    
`

const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  margin: 26px 0 0 0;

`

const Text = styled.p `
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: -0.7703428268432617px;
  margin: 16px 0 0 0;
  max-width: 442px;

`

