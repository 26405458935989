import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

// Глобальные стили CSS
export const GlobalStyle = createGlobalStyle`
  /* Переменные */
  :root {
    --color-light: #ffffff;
    --color-deep-light: #eeeeee;
    --color-very-deep-light: #dddddd;
    --color-dark: #999999;
    --color-deep-dark: #666666;
    --color-very-deep-dark: #333333;
    --color-accent: #1d95f8;
    --color-font: #111111;
    --radius-small: 5px;
    --radius-medium: 10px;
    --radius-large: 20px;
    --shadow-contour: 0 0 5px 0 rgba(0, 0, 0, .5);
    --shadow-small: 0 0 10px 3px rgba(0, 0, 0, .10);
    --shadow-medium: 0 0 15px 6px rgba(0, 0, 0, .10);
    --shadow-large: 0 0 20px 9px rgba(0, 0, 0, .10);

    --font-size: 18px;
  }

  /* Обнуления */
  *, *::before, *::after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  ul, ol, li {
    list-style: none;
  }
  img {
    vertical-align: top;
  }
  button {
    background: transparent;
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    font-size: inherit;
  }
  html, body {
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: var(--font-size);
    line-height: 1;
  }
`;

export const Section = styled.div`
  position: relative;
  flex: 1;
  padding: 64px 0;
  background: #ffffff;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
  overflow: hidden;
`;

export const SectionTop = styled(Section)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
`;

export const SectionBottom = styled(Section)`
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;
