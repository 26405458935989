import React from 'react'
import i18n from "i18next";

export const FullTimer = ({
    title,
    minutes,
    hours,
    seconds
                          }) => {
    return (<>
        {title?  title: undefined}
        {hours&& i18n.t('time.time', {postProcess: 'interval', count:hours })}
        {hours === 0 && '0'}
        {minutes !== undefined && hours !== undefined &&':'}
        {minutes&& i18n.t('time.time', {postProcess: 'interval', count: minutes})}
        {minutes === 0 && '0'}
        {seconds !== undefined &&minutes !== undefined &&':'}
        {seconds&& i18n.t('time.time', {postProcess: 'interval', count: seconds})}
        {seconds === 0 && '0'}
    </>)
}
