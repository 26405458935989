import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentCity } from "../redux/reducer/slices/settingsSlice";
import { setIsAddressModalOpen } from "../redux/reducer/slices/mapSlice";
import styled from "styled-components";
import { Loader } from "../utils/loader";
import Button from "./Button";

const AddressButton = () => {
  const { isLoading, addressesOwn } = useSelector((state) => state.map);
  const { currentCity } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  const [questionType, setQuestionType] = useState(""); // "" | "addAddress" | "confirmAddress"
  const addresses = addressesOwn || [];

  useEffect(() => {
    if (addresses.length === 0) {
      setQuestionType("addAddress");
      setIsShowQuestion(true);
    } else if (addresses.length > 0) {
      setQuestionType("confirmAddress");
      setIsShowQuestion(true);
    }
  }, [addresses]);

  const handleAddAddress = () => {
    dispatch(setIsAddressModalOpen());
  };

  const handleConfirmAddress = () => {
    if (addresses.length > 0) {
      dispatch(setCurrentCity(addresses[0].city));
    }
    setIsShowQuestion(false);
  };

  const handleCancel = () => {
    setIsShowQuestion(false);
  };

  const handleShowAddressList = () => {
    // Логика отображения списка всех адресов
    console.log("Показать список всех адресов и кнопку для добавления нового");
  };

  return (
    <AddressSelectorWrapper>
      <Button onClick={() => setIsShowQuestion(true)}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {addresses.length > 0 ? (
              <div>
                <h1>
                  {addresses[0].street && `${addresses[0].street} `}
                  {addresses[0].house && `${addresses[0].house}`}
                  {addresses[0].floor && `, эт. ${addresses[0].floor},`}
                  {addresses[0].flat && `кв ${addresses[0].flat}`}
                </h1>
                <p>{addresses[0].name && addresses[0].name}</p>
              </div>
            ) : (
              <>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1499 1.26175C14.6316 1.35396 13.9233 1.58838 12.8908 1.93252L4.95092 4.57916C3.94535 4.91434 3.21368 5.15844 2.68223 5.36579C2.13 5.58125 1.8877 5.72385 1.78623 5.82136C1.0349 6.54335 1.0349 7.74536 1.78623 8.46735C1.8877 8.56486 2.13 8.70746 2.68223 8.92292C3.21368 9.13028 3.94535 9.37437 4.95092 9.70955C4.9669 9.71488 4.98267 9.72014 4.99823 9.72532C5.35537 9.84428 5.60192 9.92641 5.82579 10.0448C6.36857 10.3319 6.81237 10.7757 7.09942 11.3184C7.21782 11.5423 7.29995 11.7889 7.41891 12.146C7.42409 12.1615 7.42935 12.1773 7.43468 12.1933C7.76987 13.1989 8.01396 13.9306 8.22131 14.462C8.43678 15.0142 8.57937 15.2565 8.67689 15.358C9.39888 16.1093 10.6009 16.1093 11.3229 15.358C11.4204 15.2565 11.563 15.0142 11.7784 14.462C11.9858 13.9306 12.2299 13.1989 12.5651 12.1933L15.2117 4.25342C15.5559 3.22098 15.7903 2.51265 15.8825 1.99432C15.9755 1.47156 15.8837 1.3528 15.8376 1.30664C15.7914 1.26049 15.6727 1.16876 15.1499 1.26175ZM14.9357 0.0574408C15.5593 -0.0534886 16.2141 -0.0467148 16.7025 0.441695C17.191 0.930106 17.1977 1.58498 17.0868 2.20856C16.977 2.82602 16.7122 3.62009 16.3888 4.59034L16.3722 4.64024L13.7255 12.5801L13.7195 12.5983C13.3916 13.582 13.1379 14.343 12.918 14.9066C12.7048 15.4529 12.4897 15.9091 12.2049 16.2056C11.0015 17.4578 8.99821 17.4578 7.79489 16.2056C7.51002 15.9091 7.29491 15.4529 7.08176 14.9066C6.86186 14.343 6.60819 13.582 6.28029 12.5983L6.27423 12.5801C6.1335 12.1579 6.08337 12.0137 6.01811 11.8903C5.84588 11.5646 5.5796 11.2984 5.25393 11.1261C5.13054 11.0609 4.98629 11.0107 4.5641 10.87L4.54596 10.864C3.56222 10.536 2.80123 10.2824 2.23761 10.0625C1.69131 9.84932 1.23512 9.63422 0.938671 9.34934C-0.313542 8.14603 -0.313542 6.14268 0.938671 4.93937C1.23512 4.65449 1.69131 4.43939 2.23761 4.22624C2.80123 4.00633 3.56224 3.75266 4.54599 3.42474L4.5641 3.41871L12.504 0.772075L12.554 0.75542C13.5242 0.431986 14.3182 0.167279 14.9357 0.0574408Z" fill="#2C2E32"/>
                </svg>
                <p>Укажите адрес доставки</p>
              </>
            )}
          </>
        )}
      </Button>

      {isShowQuestion && (
        <QuestionWrapper>
          {questionType === "addAddress" ? (
            <>
              <Title>Добавим адрес?</Title>
              <Body>
                <Text>Добавив адрес доставки, вы увидите доступные кухни и условия доставки</Text>
              </Body>
              <Actions>
                <Button onClick={handleCancel}>Нет</Button>
                <Button type="primary" onClick={handleAddAddress}>Да</Button>
              </Actions>
            </>
          ) : questionType === "confirmAddress" && addresses.length > 0 ? (
            <>
              <Title>Заказ доставить на этот адрес?</Title>
              <Body>
                <Address>
                    {addresses[0].street && `${addresses[0].street} `}
                    {addresses[0].house && `${addresses[0].house}, `}
                    {addresses[0].flat && `кв ${addresses[0].flat}`}
                </Address>
              </Body>
              <Actions>
                <Button onClick={handleShowAddressList}>Нет</Button>
                <Button type="primary" onClick={handleConfirmAddress}>Да</Button>
              </Actions>
            </>
          ) : null}
        </QuestionWrapper>
      )}
    </AddressSelectorWrapper>
  );
};

export default AddressButton;

const AddressSelectorWrapper = styled.div`
  position: relative;
`;

const QuestionWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 16px;
  padding: 24px 32px;
  width: fit-content;
  max-width: 450px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 10;
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
`;

const Text = styled.div`
  font-size: 15px;
  font-weight: 400;
`;

const Address = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const Body = styled.div``;

const Actions = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;

