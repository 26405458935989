import React from 'react'
import styled, {keyframes} from "styled-components";

export const Loader = ({
    widthContainer,
    heightContainer,
    height,
    width,
    marginTop
                       }) => {
    return(<>
          <LoaderContainer
            widthContainer = {widthContainer}
            heightContainer = {heightContainer}
            marginTop = {marginTop}
          >
              <Load
              width = {width}
              height = {height}
              />
          </LoaderContainer>
        </>)
}

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
}
    100% {
        transform: rotate(360deg);
}
`
const LoaderContainer = styled.div `
  width:${({widthContainer}) => widthContainer? `${widthContainer}px` : '100%'}
  height: ${({heightContainer}) => heightContainer? `${heightContainer}px` : '100vh'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:${({marginTop}) => marginTop ? `${marginTop}px` : 0 };

`
const Load = styled.div `
    width:${({width}) => width? `${width}px` : '35px'};
    height: ${({height}) => height? `${height}px` : '35px'};
    border: 5px solid #FFF;
    border-bottom-color: #EA394B;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${rotation} 1s linear infinite;
`
