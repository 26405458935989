import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';

const initialState = {
    data: [],
    actualOrders: [],
    isLoadingActual: false,
    isLoading: false,
    page: 0,
    hasMore: true,
    error: false,
    errorActual: false,
    errorServer: false,
    serverTime: null,
};

// Async Thunks
export const getOrders = createAsyncThunk(
    'orders/getOrders',
    async (_, { getState, dispatch }) => {
        const api = new axiosRequest().instance;
        const state = getState().orders;

        if (state.hasMore) {
            try {
                const response = await api.post('orders/get?XDEBUG_SESSION_START=MyVSCode', {
                    data: {
                        token: localStorage.getItem('token'),
                        offset: state.page,
                    },
                });

                if (response.data.result && response.data.data.ordersData) {
                    const hasMore = !!response.data.data.ordersData.length; // Determine if there are more orders
                    dispatch(setPage());
                    return {
                        ordersData: response.data.data.ordersData,
                        serverTime: response.data.data.serverTime,
                        hasMore,
                    };
                } else {
                    throw new Error('Failed to fetch orders');
                }
            } catch (e) {
                throw new Error('Server error');
            }
        }
    }
);

export const getOrdersActual = createAsyncThunk(
    'orders/getOrdersActual',
    async (types = [10, 20, 30, 40]) => {
        const api = new axiosRequest().instance;

        try {
            const response = await api.post('orders/get?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    token: localStorage.getItem('token'),
                    offset: 0,
                    statuses: types,
                },
            });

            if (response.data.result && response.data.data.ordersData) {
                return response.data.data.ordersData;
            } else {
                throw new Error('Failed to fetch actual orders');
            }
        } catch (e) {
            throw new Error('Server error');
        }
    }
);

// Slice
const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setPage(state) {
            state.page += 1;
        },
        resetOrders(state) {
            state.data = [];
            state.hasMore = true;
            state.page = 0;
            state.errorServer = false;
            state.error = false;
        },
        setCurrentTime(state, action) {
            state.serverTime = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrders.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.errorServer = false;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.data = [...state.data, ...action.payload.ordersData];
                state.serverTime = action.payload.serverTime;
                state.isLoading = false;
                state.error = false;
                state.errorServer = false;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getOrders.rejected, (state) => {
                state.isLoading = false;
                state.error = true;
                state.hasMore = false;
            })
            .addCase(getOrdersActual.pending, (state) => {
                state.isLoadingActual = true;
                state.errorActual = false;
            })
            .addCase(getOrdersActual.fulfilled, (state, action) => {
                state.isLoadingActual = false;
                state.actualOrders = action.payload;
                state.errorActual = false;
            })
            .addCase(getOrdersActual.rejected, (state) => {
                state.isLoadingActual = false;
                state.errorActual = true;
            });
    },
});

export const {
  setPage,
  resetOrders,
  setCurrentTime,
} = ordersSlice.actions;

export default ordersSlice.reducer;
