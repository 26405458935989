import React from "react";
import styled from "styled-components";

export const DetailKitchen = ({orders,kitchenName}) => {
    return (<>
        <KitchenContainer>
            <Header>{kitchenName}</Header>
            <InnerContainer>
                {
                    orders.map((order) =><OrderContainer>



                    </OrderContainer> )
                }
            </InnerContainer>
        </KitchenContainer>

        </>)
}


const KitchenContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding:32px;
  margin-top: 8px;
  ::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    right: 50px;
    background-color: transparent;
    border-top: 1px solid #2C2E32;
    border-left: 1px solid #2C2E32;
    transform: rotate(135deg);
    bottom: 38%;
  }
`;

const InnerContainer = styled.div`

`;

const OrderContainer = styled.div`


`

const imgOrder = styled.img `


`

const Header = styled.p`
  margin:0;
  font-family: 'SFProTextMedium';
  font-size: 24px;

`
