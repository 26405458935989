import React from 'react'
import styled from "styled-components";
export const ErrorBanner = () => {
    return (<>
      <ErrorMessage>На сервере произошла ошибка, пожалуйста повторите позже</ErrorMessage>
        </>)
}

const ErrorMessage = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.7852098345756531px;
  color: #EA394B

`
