import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const Carrousel = ({data}) => {
  if (data && data.length === 0) {
    return;
  }

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '60px',
    autoplay: false,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slides = [
    { id: 1, content: "1" },
    { id: 2, content: "2" },
    { id: 3, content: "3" },
    { id: 4, content: "4" },
    { id: 5, content: "5" },
    { id: 6, content: "6" },
    { id: 7, content: "7" },
    { id: 8, content: "8" },
    { id: 9, content: "9" },
    { id: 10, content: "10" },
  ];

  return (
    <Wrapper>
      <SliderContainer>
        <StyledSlider {...settings}>
          {slides.map((slide) => (
            <div key={slide.id}>
              <Slide>{slide.content}</Slide>
            </div>
          ))}
        </StyledSlider>
      </SliderContainer>
    </Wrapper>
  );
};

export default Carrousel;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  padding: 32px 64px;
  background: #ffffff;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  overflow: hidden;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100px;
    pointer-events: none;
  }

  &:before {
    left: -1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  &:after {
    right: -1px;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
`;

const SliderContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: visible;
  }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    visibility: hidden;
    pointer-events: none;
  }
`;

const Slide = styled.div`
  margin: 0 10px;
  width: 200px;
  height: 220px;
  background-color: #a0dcf8;
  border-radius: 32px;
  overflow: hidden;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #fff;
  border: 1px solid #F6F4F4;
  border-radius: 50%;
  box-shadow: -4px 8px 11px 0px rgba(87, 87, 87, 0.29);
  color: #333; 
  cursor: pointer;

  &:hover {
    background: #fff;
    box-shadow: 0px 5px 5px 0px rgba(189, 189, 189, 0.29);
  }

  &:active {
    background: #fff;
    border: 1px solid #E1E1E1;
  }
`;

const NextArrow = ({ className, onClick }) => (
  <Arrow className={className} style={{ right: '-30px' }} onClick={onClick}>
    <svg width="23" height="8" viewBox="0 0 23 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3536 3.64644C22.5488 3.84171 22.5488 4.15829 22.3536 4.35355L19.1716 7.53553C18.9763 7.73079 18.6597 7.73079 18.4645 7.53553C18.2692 7.34027 18.2692 7.02369 18.4645 6.82843L21.2929 4L18.4645 1.17157C18.2692 0.976309 18.2692 0.659727 18.4645 0.464464C18.6597 0.269202 18.9763 0.269202 19.1716 0.464464L22.3536 3.64644ZM-4.37112e-08 3.5L22 3.5L22 4.5L4.37112e-08 4.5L-4.37112e-08 3.5Z" fill="#2C2E32"/>
    </svg>
  </Arrow>
);

const PrevArrow = ({ className, onClick }) => (
  <Arrow className={className} style={{ left: '-30px' }} onClick={onClick}>
    <svg width="23" height="8" viewBox="0 0 23 8" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
      <path d="M22.3536 3.64644C22.5488 3.84171 22.5488 4.15829 22.3536 4.35355L19.1716 7.53553C18.9763 7.73079 18.6597 7.73079 18.4645 7.53553C18.2692 7.34027 18.2692 7.02369 18.4645 6.82843L21.2929 4L18.4645 1.17157C18.2692 0.976309 18.2692 0.659727 18.4645 0.464464C18.6597 0.269202 18.9763 0.269202 19.1716 0.464464L22.3536 3.64644ZM-4.37112e-08 3.5L22 3.5L22 4.5L4.37112e-08 4.5L-4.37112e-08 3.5Z" fill="#2C2E32"/>
    </svg>
  </Arrow>
);