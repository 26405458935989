import React from 'react'
import styled from "styled-components";
import Switch from '@material-ui/core/Switch';
export const Option = ({title, last}) => {
    return (<>
     <OptionContainer>
         <p>{title}</p>
         <SwitchCustom focusVisibleClassName=".Mui-focusVisible"/>
     </OptionContainer>
        { !last &&<HR/>  }
        </>)
}

const OptionContainer = styled.div`
    display: flex;
    justify-content: space-between;
   align-items: center;
    width: 100%;
  p{
    font-family: 'SFProTextMedium';
    color: #2C2E32!important;
    margin: 0;
    font-size: 18px;
  }
 
`

const HR = styled.hr`
    display: flex;
    justify-content: space-between;
   align-items: center;
   max-width: 480px;
    width: 100%;
  border: 1px solid #F1F1F1;
  margin: 8px 0;

`

const SwitchCustom = styled(Switch)(({ theme }) => ({
    width: '90px !important',
    height: '60px !important',
    '&.Mui-disabled + .MuiSwitch-track': {
        opacity: '0 !important'
    },
    '& .MuiSwitch-switchBase': {
        padding: 16,
        backgroundColor: 'transparent !important',
        border: 'none !important',
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 27,
        height: 27,
        backgroundColor: 'white',
    },
    '& .MuiSwitch-track': {
        borderRadius: 26,
        height: '100%',
        opacity: 1,
        backgroundColor: '#787880',

    },

    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#EA394B',
        opacity: 1,
    }
}));



