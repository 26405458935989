import React from "react";
import styled from "styled-components";
import {DetailKitchen} from "./DetailKitchen/DetailKitchen";
import {MonthSwitcher} from "../../../../../share/libs/MonthSwitcher";
import {TimeShower} from "../../../../../share/ui/TimeShower/TimeShower";
import {SwitcherTimeShower} from "../../../../../share/ui/TimeShower/types";
import moment from "moment";
export const DetailContentItemOrder = ({idGroup, statusName,delivery, created, day, month, timeStart, timeEnd, orders, ordersKitchen }) => {

    const date = new moment(created+1000)


    return(<>
            <ContentItemOrderContainer>
                <InnerContainer>
                    <HeaderOrder>
                        <Header>Заказ № {idGroup}</Header>
                        <p style = {{display: 'flex'}}>Создан: {date.daysInMonth()}  {MonthSwitcher(date.month()+1) },  <TimeShower title={' '} type = {SwitcherTimeShower.FULL_TIMER} hours={date.hour()} minutes={date.minutes()}/></p>
                    </HeaderOrder>
                    <TitleContainer>
                        <Title>Адрес доставки</Title>
                        <p>Доставим: {day} {month}, {timeStart} - {timeEnd} </p>
                    </TitleContainer>
                </InnerContainer>

                {
                   ordersKitchen.map((kitchen) => <DetailKitchen kitchenName={kitchen.kitchenName} orders ={kitchen.orders}/>)
                }

            </ContentItemOrderContainer>
    </>)
}




const ContentItemOrderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  border-radius: 16px;
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding:32px;
`;




const HeaderOrder = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding-bottom: 29px;
  border-bottom: 2px solid #F1F1F1;
  p {
    font-family: 'SFProTextRegular';
    font-size: 24px;
    margin: 0;
    color: #A4A4A4;
  }
  
  p:last-child {
    color: #2C2E32;
  }

`

const Header = styled.div `
  font-family: 'SFProTextBold';
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #37474F;
`

const TitleContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-family: 'SFProTextRegular';
    font-size: 24px;
    margin: 0;
    color: #A4A4A4;
  }

  p:last-child {
    color: #2C2E32;
  }

`

const Title = styled.div `
  font-family: 'SFProTextMedium';
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #2C2E32;
`


