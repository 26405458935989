import React, { useState } from 'react';
import styled from "styled-components";
import { getCookie } from '../utils/function';
import { CSSTransition } from 'react-transition-group';

const Cookie = () => {
  const [openWarningCookie, setOpenWarningCookie] = useState(!getCookie("warningCookie"));

  const warningCookieOk = () => {
    document.cookie = "warningCookie=yes";
    setOpenWarningCookie(false);
  };

  return (
    <CSSTransition timeout={500} classNames="warningCookie" in={openWarningCookie} unmountOnExit>
      {document.documentElement.clientWidth <= 992 ? (
        <CookieWarning>
          <Text>Мы используем куки во всех своих проектах</Text>
          <ButtonOk onClick={warningCookieOk}>Хорошо 👌</ButtonOk>
        </CookieWarning>
      ) : (
        <CookieWarning>
          <Text>Мы используем куки на всех своих сайтах, включая этот, потому что без кук весь интернет работал бы плохо</Text>
          <ButtonOk onClick={warningCookieOk}>Понимаю</ButtonOk>
          <ButtonOkMinimal onClick={warningCookieOk}>
            <i className="fas fa-times"></i>
          </ButtonOkMinimal>
        </CookieWarning>
      )}
    </CSSTransition>
  );
};

export default Cookie;

const CookieWarning = styled.div`
	width: 100%;
	height: 80px;
	position: fixed;
	bottom: 0;
	z-index: 10001;
	background: #EA394B;
	font-family: 'Arsenal', sans-serif;
	line-height: 23px;
	color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 992px) {
		width: calc(100% - 16px);
		height: 83px;
		padding: 0 20px;
		bottom: 8px;
		left: 8px;
		right: 8px;
		background: #ffffff;
		font-family: 'Roboto', sans-serif;
		font-weight: normal;
		font-size: 13px;
		line-height: 15px;
		color: #222222;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
	}
`;

const Text = styled.div`
	padding: 5px 45px;
	font-size: 20px;

	@media(max-width:992px){
		width: 144px;
		max-width: calc(100% - 135px);
    	padding: 0;
		font-size: inherit;
    }
`;

const ButtonOk = styled.div`
	background: #fff;
	opacity: 0.7;
	font-family: 'Arsenal', sans-serif;
	font-size: 20px;
	line-height: 23px;
	color: #EA394B;
	border-radius: 6px;
	padding: 10px 45px;
    margin-right: 45px;
    cursor: pointer;

    @media (max-width:992px) {
    	width: 125px;
    	height: 42px;
		background: #6A11CB;
		opacity: 1;
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		color: #ffffff;
		border-radius: 66px;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
    }
`;

const ButtonOkMinimal = styled.div`
	position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px 0 0;
    cursor: pointer;

	@media(min-width:992px){
		display: none;
    }
`;
