import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    todayOrdersCount: 0,
    nextOrdersCount: 0
};

export const getTodayOrdersCount = createAsyncThunk(
    // 'header/getTodayOrdersCount',
    // async (_, thunkAPI) => {
    //     try {
    //         const response = await fetchOrdersCount();

    //         return {
    //             today: { dayOrdersCount: response.today.dayOrdersCount },
    //             tomorrow: { dayOrdersCount: response.tomorrow.dayOrdersCount }
    //         };
    //     } catch (err) {
    //         return thunkAPI.rejectWithValue(err.response.data);
    //     }
    // }
);

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTodayOrdersCount.pending, (state) => {
                state.todayOrdersCount = 0;
                state.nextOrdersCount = 0;
            })
            .addCase(getTodayOrdersCount.fulfilled, (state, action) => {
                state.todayOrdersCount = parseInt(action.payload.today.dayOrdersCount);
                state.nextOrdersCount = parseInt(action.payload.tomorrow.dayOrdersCount);
            })
            .addCase(getTodayOrdersCount.rejected, (state, action) => {
                console.error("Ошибка при получении данных:", action.payload);
            });
    }
});

export const {

} = headerSlice.actions;

export default headerSlice.reducer;
