import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  addressObj: {},
  addressStr: "",
  addressPoint: null,
  addressAdditional: {
    addressType: '',
    companyName: '',
    office: '',
    apartment: '',
    floor: '',
    flat: '',
    comment: '',
  }
};

const addCompanySlice = createSlice({
  name: 'addCompany',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setAddress: (state, action) => {
      state.addressObj = action.payload;
    },
    setAddressPoint: (state, action) => {
      state.addressPoint = action.payload;
    },
    setAddressStr: (state, action) => {
      state.addressStr = action.payload;
    },
    setAddressAdditional: (state, action) => {
      state.addressAdditional = action.payload;
    },
    resetAddCompany: () => initialState,
  },
});

export const {
  setAddress,
  setAddressPoint,
  setAddressStr,
  setAddressAdditional,
  resetAddCompany,
} = addCompanySlice.actions;

export default addCompanySlice.reducer;
