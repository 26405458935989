import React from "react";
import styled from "styled-components";
import {Close} from "../../share/Close/Close";

export const MyBill = ({title,points}) => {
    return (<>
        <InnerContainer>
            <Close/>
            <HeaderContainer>
                <Header>{title}</Header>
            </HeaderContainer>
            <FooterContainer>
                <PointTitle>
                    {points}
                </PointTitle>
                <Button>Пополнить</Button>
            </FooterContainer>
        </InnerContainer>

    </>)
}




const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`


const PointTitle = styled.p`
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -0.44200000166893005px;
  margin:0;
`

const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 16px;
  background-color: #EA394B;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.44200000166893005px;
  text-align: left;

`
