import React from 'react'
import Switch from '@material-ui/core/Switch';
import './Switcher.css'
import './_theme/Switcher_theme.css'
import {useClassName} from "../../share/hooks/useClassName";
export const Switcher = ({
                             additionalClassString,
                             additionalClassBoolean
                         }) => {
    return (<>
   <Switch className=
               {
       useClassName({
           mainClass: 'Switcher',
           additionalClassString: additionalClassString,
           additionalClassBoolean:additionalClassBoolean
       })
   }

   />


        </>)
}
