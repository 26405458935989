import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import MainPage from '../pages/MainPage';
import About from '../components/About/index';
import Personal from '../containers/Personal/index';
import KitchenPage from '../pages/KitchenPage';
import CartPage from '../pages/CartPage';


export const Router = ({isAuth}) => {
  return(
    <Routes>
      <Route path="/" element={<MainPage/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/menu" element={<KitchenPage/>}/>
      <Route path="/cart" element={<AuthRoute isAuth={isAuth}><CartPage/></AuthRoute>}/>
      <Route path="/personal" element={<AuthRoute isAuth={isAuth}><Personal/></AuthRoute>}/>
      <Route path="*" element={<div>404</div>}/>
    </Routes>
  )
}

export default Router;

function AuthRoute({ isAuth, children }) {

  console.log('rout', isAuth);
  if (!isAuth) {
    return <Navigate to="/" replace />;
  } 

  return <>{children}</>;
}