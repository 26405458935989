import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';

const initialState = {
    isLoading: false,
    title: null,
    goods: [],
    errors: null,
    errorsServer: null,
};

export const getGoods = createAsyncThunk(
    'goods/getGoods',
    async (_, thunkAPI) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.post('kitchens/getRecommendations', {
                data: {
                    "token": localStorage.getItem('token'),
                    "city": { "id": 18 },
                    "clientInfo": {
                        "os": "android",
                        "osVersion": "33",
                        "model": "M2101K6G",
                        "brand": "Redmi",
                        "screenWidth": 1080,
                        "deviceId": "69516350155354a2",
                        "appVersion": "1.29.13",
                        "appId": "com.menu4me.dinner.sitiyoffice",
                        "locale": "ru_RU"
                    }
                }
            });

            if (response.data.result) {
                return response.data.data.recommendations.shift();
            } else {
                return thunkAPI.rejectWithValue('Request failed');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Server error');
        }
    }
);

const goodsSlice = createSlice({
    name: 'goods',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getGoods.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getGoods.fulfilled, (state, action) => {
                state.isLoading = false;
                state.errors = null;
                state.errorsServer = null;
                state.title = action.payload.title;
                state.goods = action.payload.items;
            })
            .addCase(getGoods.rejected, (state, action) => {
                state.isLoading = false;
                if (action.payload === 'Server error') {
                    state.errorsServer = true;
                } else {
                    state.errors = true;
                }
            });
    },
});

export const {
  
} = goodsSlice.actions;

export default goodsSlice.reducer;
