import React from 'react'
import styled from "styled-components";

export const Close = () => {
    return(<>
        <CloseBlock/>
        </>)
}

const CloseBlock = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
  background-color: transparent;
  ::after {
    position: absolute;
    content: "";
    display: block;
    transform: rotate(45deg);
    background-color: #BCBCBC;
    width: 1px;
    height: 25px;
    
  }

  ::before {
    position: absolute;
    content: "";
    display: block;
    transform: rotate(-45deg);
    background-color: #BCBCBC;
    width: 1px;
    height: 25px;

  }
`
