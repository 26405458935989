import React from 'react'
import logo from "../images/logo_v3.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsAuthModalOpen } from '../redux/reducer/slices/profileSlice';
import styled from "styled-components";
import Button from './Button';
import AddressButton from './AddressButton';


const Header = () => {
  const { isAuth, name } = useSelector((state) => state.profile);
  const { totalPrice } = useSelector((state) => state.cart);
  const { authModalIsOpen } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const handleSearch = () => {
    alert('Поиск...')
  }

  const handleClickAuth = () => {
    dispatch(setIsAuthModalOpen());
  }

  console.log(authModalIsOpen);

  return (
    <HeaderWrapper>
      <HeaderLeft>
        <HeaderLogo>
          <Link to={'/'}>
            <img alt = '#' src ={logo}/>
          </Link>
        </HeaderLogo>

        <HeaderSearch>
          <HeaderSearchInput
            placeholder='Поиск блюд и кухонь'
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <Button type='primary' onClick={handleSearch}>Найти</Button>
        </HeaderSearch>

        {isAuth && <AddressButton/>}
      </HeaderLeft>

      <HeaderRight>
          {isAuth &&
            <Link to='/cart'>
                <Button type='primary'>
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0587 18.5898L10.7646 14.0606" stroke="white" stroke-width="1.29408" stroke-linecap="round"/>
                    <path d="M9.47 4.35938L6.88184 8.24162M18.5286 4.35938L21.1167 8.24162" stroke="white" stroke-width="1.29408" stroke-linecap="round"/>
                    <path d="M15.9414 18.5898L17.2355 14.0606" stroke="white" stroke-width="1.29408" stroke-linecap="round"/>
                    <path d="M4.29492 10.8281V10.8281C5.05104 10.8281 5.7057 11.3533 5.86973 12.0914L7.27641 18.4214C7.70823 20.3647 7.92415 21.3363 8.63383 21.9056C9.34352 22.4749 10.3388 22.4749 12.3295 22.4749H15.6716C17.6622 22.4749 18.6575 22.4749 19.3672 21.9056C20.0769 21.3363 20.2928 20.3647 20.7247 18.4214L22.1313 12.0914C22.2954 11.3533 22.95 10.8281 23.7061 10.8281V10.8281" stroke="white" stroke-width="1.29408" stroke-linecap="round"/>
                    <path d="M23.7053 10.8304H4.29408C3.57938 10.8304 3 10.251 3 9.53627C3 8.82157 3.57938 8.24219 4.29408 8.24219H23.7053C24.42 8.24219 24.9994 8.82157 24.9994 9.53627C24.9994 10.251 24.42 10.8304 23.7053 10.8304Z" stroke="white" stroke-width="1.29408" stroke-linecap="round"/>
                  </svg>
                  {totalPrice > 0 ? `${totalPrice} ₽` : 'Корзина'}
                </Button>
            </Link>
          }
          {isAuth ? (
            <Link to="/personal">
              <Button>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.0154 23.8553C22.4836 22.3668 21.3119 21.0516 19.6819 20.1135C18.0518 19.1755 16.0547 18.667 14.0001 18.667C11.9455 18.667 9.94832 19.1755 8.31831 20.1135C6.6883 21.0516 5.51654 22.3668 4.98477 23.8553" stroke="#333333" stroke-width="1.7" stroke-linecap="round"/>
                  <circle cx="13.9999" cy="9.33366" r="4.66667" stroke="#333333" stroke-width="1.7" stroke-linecap="round"/>
                </svg>
                {name ? name : 'Профиль'}
              </Button>
            </Link>
          ) : (
            <Button type='primary' onClick={handleClickAuth}>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0154 23.8553C22.4836 22.3668 21.3119 21.0516 19.6819 20.1135C18.0518 19.1755 16.0547 18.667 14.0001 18.667C11.9455 18.667 9.94832 19.1755 8.31831 20.1135C6.6883 21.0516 5.51654 22.3668 4.98477 23.8553" stroke="white" stroke-width="1.7" stroke-linecap="round"/>
                <circle cx="13.9999" cy="9.33366" r="4.66667" stroke="white" stroke-width="1.7" stroke-linecap="round"/>
              </svg>
              Войти
            </Button>
          )}
      </HeaderRight>
    </HeaderWrapper>
  )
}

export default Header;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 32px 40px;
  background-color: #ffffff;
  height: 125px;
`;

const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const HeaderLogo = styled.div`
  width: fit-content;
  margin-right: 35px;
`;

const HeaderSearch = styled.div`
  position: relative;
  display: flex;
  max-width: 510px;
  width: 100%;

  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
`;

const HeaderSearchInput = styled.input`
  padding: 0 150px 0 24px;
  width: 100%;
  height: 60px;
  background: #FBFBFB;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
`;

const HeaderRight = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  gap: 32px;
`;
