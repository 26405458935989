import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"
import backArrow from '../images/menu/arrow_left.svg'

const BackButton = ({ title }) => {
  return (
    <Wrapper>
      <Link to={-1}>
        <img src={backArrow} alt="#"/>
      </Link>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default BackButton;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
`;