import React, {Component} from "react";
import styled from "styled-components";
import smart1 from "../../../images/smart-transition.png";
import smart2 from "../../../images/smart.png";
import {Fade} from "react-reveal";
import {NAME_COMPANY} from "../../../utils/constants";

class DownloadMobile extends Component {
	render() {
		return (
			<Container>
				<Wrapper>
					<Text>
						<Title>
							Заказывайте через приложение «{NAME_COMPANY}»
						</Title>
						<Paragraph>
							Вы и ваши коллеги можете заказывать и оплачивать независимо, каждый сам за себя. Это просто и удобно! Главное условие - это набрать минимальную сумму для бесплатной доставки на всю компанию.
						</Paragraph>
						<Buttons>
							{/*<Button className="download-banner_playm" href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice">Скачать в Playmarket</Button>*/}
							<Button className="download-banner_playm" href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&utm_source=my_comp&utm_medium=cpa&utm_campaign=promo&utm_content=landing_page&utm_term=just">Скачать в Playmarket</Button>
							<Button className="download-banner_app" href="https://itunes.apple.com/ru/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950?mt=8">Скачать в Appstore</Button>
						</Buttons>
						<ImgBlock>
							<Item>
								<Fade left>
									<ItemImg>
										<Img src={smart1}/>
									</ItemImg>
								</Fade>
								<Fade right>
									<ItemImg style={{marginLeft: "-110px"}}>
										<Img src={smart2}/>
									</ItemImg>
								</Fade>
							</Item>
						</ImgBlock>
					</Text>
				</Wrapper>
			</Container>
		);
	}
}

export default DownloadMobile;

const Container = styled.div`
	padding: 45px 0 15px;
`;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 0 15px;
	margin: 0 auto 30px;
	display: flex;
		overflow: hidden;

	
	@media(max-width: 992px){
		flex-direction: column;
	}
`;

const Text = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	width: 60%;
	height: 500px;
	justify-content: center;
	flex-direction: column;
	padding-left: 30px;
	
	@media (max-width: 992px){
		width: 100%;
		height: auto;
	}
`;

const Title = styled.div`
	text-align: left;
	font-size: 34px;
	font-family: "Lato";
	margin-bottom: 20px;
	width: 100%;
	align-self: baseline;
	order: 0;
`;

const Paragraph = styled.div`
	font-size: 20px;
	font-family: "Lato";
	margin-bottom: 75px;
	align-self: baseline;
	order: 1;
	
	@media(max-width: 992px){
		margin-bottom: 0;
	}
`;

const Buttons = styled.div`
	margin-bottom: 20px;
	align-self: baseline;
	display: flex;
	z-index: 10;
	order: 2
	
	@media (max-width: 992px){
		flex-direction: column;
		width: 100%;
		align-items: center;
		order: 3;
	}
`;

const Button = styled.a`
	padding: 10px 30px;
	border-radius: 25px;
	border: 1px solid #E0D5D2;
	background-color: #fff;
	color: #E0D5D2 !important;
	margin-right: 20px;
	font-family: "Lato", sans-serif;;
	font-size: 20px;
	outline: none;
	margin: 10px;
	
	&:hover{
		background: #E0D5D2;
		color: #fff !important;
		text-decoration: none;
	}
	
	@media (max-width: 992px){
		width: 100%;
		text-align: center;
	}
`;

const ImgBlock = styled.div`
	height: 100%;
	width: 75%;
	display: flex;
	order: 3;
	
	@media (max-width: 992px){
		width: 100%;
		order: 2;
		margin: 15px 0;
	}
`;

const ItemImg = styled.div`
    height: 100%;
	transition: all .6s ease;
	background-position: center top;
	background-repeat: no-repeat;
	
	@media (max-width: 992px){
		margin-left: 0;
		width: auto;
	}
`;

const Img = styled.img`
	width: auto;
    height: auto;
	transition: all .6s ease;
	background-position: center top;
	background-repeat: no-repeat;
	
	@media(max-width: 992px){
		width: 100%;
	}
`;

const Item = styled.div`
	padding: 0 15px;
	width: 100%;
	overflow-y: hidden;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	
	@media (max-width: 992px){
		display: flex;
		justify-content: center;
		margin-top: 15px;
	}
`;