import React from "react";
import styled from "styled-components";
import img from '../../../../../images/Profile/Visa.png'
import {Close} from "../../share/Close/Close";
export const MyCart = ({imgPath, typeCart, code}) => {
    return (<>
        <InnerContainer>
            <Close/>
            <Image src = {img}/>
            <TitleContainer>
                <Title>{typeCart} </Title>
                <Code> •••• </Code>
                <Code> {code}</Code>
            </TitleContainer>
        </InnerContainer>

    </>)
}
const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`

const Image = styled.img`
    max-width: 166px;
    max-height: 116px;
`
const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const Code = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const TitleContainer = styled.div`
  display: flex;
  
`


