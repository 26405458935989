import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const InputButton = ({ type='text', data, onSave, children }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    onSave(data, inputValue);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Container>
      <Input
        type={type}
        placeholder={type === 'number' ? '1' : 'Название'}
        value={inputValue}
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isVisible={isEditing}
        autoFocus={isEditing}
      />
      <Button onClick={isEditing ? handleSave : handleClick}>
        {children}
      </Button>
    </Container>
  );
};

export default InputButton;

const Container = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  height: 60px;
`;

const Input = styled.input`
  margin-right: -60px;
  margin-left: ${({ isVisible }) => (isVisible ? '0' : '60px')}; 
  width: ${({ isVisible, type }) => (isVisible && type === 'text' ? '300px' : isVisible && type === 'number' ? '150px' : '0px')};
  height: 100%;
  padding: ${({ isVisible }) => (isVisible ? '0 70px 0 10px' : '0')};
  border: solid 1px #C9C4C4;
  border-radius: 24px;
  font-size: 18px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: width 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  outline: none;
`;

const Button = styled.button`
  position: relative;
  z-index: 1;
  cursor: pointer;
`;
