import { combineReducers } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import profileSlice from './slices/profileSlice';
import headerSlice from './slices/headerSlice';
import addCompanySlice from './slices/addCompanySlice';
import goodsSlice from './slices/goodsSlice';
import menuSlice from './slices/menuSlice';
import modalSlice from './slices/modalSlice';
import settingsSlice from './slices/settingsSlice';
import messagesSlice from './slices/messagesSlice';
import personalSlice from './slices/personalSlice';
import promotionSlice from './slices/promotionSlice';
import ordersSlice from './slices/ordersSlice';
import kitchenSlice from './slices/kitchenSlice';
import mapSlice from './slices/mapSlice';
import cartSlice from './slices/cartSlice';
import { reducer as formReducer } from 'redux-form';

const combineReducer = combineReducers({
  common: commonSlice,
  profile: profileSlice,
  header: headerSlice,
  addCompany: addCompanySlice,
  goods: goodsSlice,
  menu: menuSlice,
  modal: modalSlice,
  settings: settingsSlice,
  messages: messagesSlice,
  personal: personalSlice,
  promotion: promotionSlice,
  orders: ordersSlice,
  kitchen: kitchenSlice,
  map: mapSlice,
  cart: cartSlice,
  form: formReducer,
});

export default combineReducer;
