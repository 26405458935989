window.getCity = function () {
    return JSON.parse(localStorage.getItem('currentCity')).name;
};

window.getFormattedCity = function() {
    let cityName = window.getCity();

    switch (cityName) {
        case "Самара": return ["Самары", "Самаре", "Самару"];
            break;
        case "Тольятти": return ["Тольятти", "Тольятти", "Тольятти"];
            break;
        case "Димитровград": return ["Димитровграда", "Димитровграду", "Димитровград"];
            break;
        case "Балаково": return ["Балаково", "Балаково", "Балаково"];
            break;
    }
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
};