import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';
import { addActiveMessage, typesMessages } from './messagesSlice';
import moment from 'moment';

const initialState = {
    deliveryDays: [],
    isLoadingDays: false,
    menuLoader: false,
    errorDays: false,
    kitchenData: [],
    errorKitchens: false,
    isLoadingKitchens: false,
    currentDay: null,
    serverTime: null,
    errorSubscribe: false,
};

// Async Thunks
export const getDeliverDay = createAsyncThunk(
    'kitchen/getDeliverDay',
    async (_, { getState, dispatch }) => {
        const api = new axiosRequest().instance;
        const currentCityId = getState().settings.currentCity.id;

        try {
            const response = await api.post('kitchens/getDeliveryPeriods?XDEBUG_SESSION_START=MyVSCode', {
                data: {
                    token: localStorage.getItem('token'),
                    city: {
                        id: currentCityId,
                    },
                },
            });

            if (response.data.result) {
                return response.data.data.deliveryDays;
            } else {
                throw new Error('Failed to fetch delivery days');
            }
        } catch (e) {
            console.log(e)
            throw new Error('Server error');
        }
    }
);

export const getMenuByDay = createAsyncThunk(
    'kitchen/getMenuByDay',
    async (_, { getState, dispatch }) => {
        const api = new axiosRequest().instance;
        const day = getState().kitchen.currentDay;

        try {
            const response = await api.post('kitchens/getDeliveryKitchensByDay', {
                data: {
                    token: localStorage.getItem('token'),
                    day: day.format('YYYY-MM-DD') || null,
                    city: {
                        id: getState().settings.currentCity.id,
                    },
                },
            });

            if (response.data.result) {
                return {
                    data: response.data.data.deliveryDays.shift().periods,
                    serverTime: response.data.data.serverTime,
                };
            } else {
                throw new Error('Failed to fetch menu by day');
            }
        } catch (e) {
            if (!(e instanceof TypeError)) {
                dispatch(addActiveMessage(typesMessages.KITCHEN_ERROR_SERVER_MESSAGE));
            }
            throw new Error('Server error');
        }
    }
);

export const setCurrentDay = (day) => (dispatch) => {
    dispatch(setCurrentDayAction(day));
};

export const getRecommendation = createAsyncThunk(
    'kitchen/getRecommendation',
    async (_, { getState }) => {
        const api = new axiosRequest().instance;
        try {
            await api.post('kitchen/getRecommendation', {
                data: {
                    token: localStorage.getItem('token'),
                    city: {
                        id: getState().settings.currentCity.id,
                    },
                    clientInfo: {
                        os: 'android',
                        osVersion: '33',
                        model: 'M2101K6G',
                        brand: 'Redmi',
                        screenWidth: 1080,
                        deviceId: '69516350155354a2',
                        appVersion: '1.29.13',
                        appId: 'com.menu4me.dinner.sitiyoffice',
                        locale: 'ru_RU',
                    },
                },
            });
        } catch (e) {
            throw new Error('Server error');
        }
    }
);

export const setSubscribe = createAsyncThunk(
    'kitchen/setSubscribe',
    async ({ url, kitchenId }, { dispatch }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.post(`kitchens/${url}`, {
                data: {
                    token: localStorage.getItem('token'),
                    kitchenId,
                    clientInfo: {
                        os: 'android',
                        osVersion: '33',
                        model: 'M2101K6G',
                        brand: 'Redmi',
                        screenWidth: 1080,
                        deviceId: '69516350155354a2',
                        appVersion: '1.29.13',
                        appId: 'com.menu4me.dinner.sitiyoffice',
                        locale: 'ru_RU',
                    },
                },
            });

            if (!response.data.result) {
                throw new Error('Subscription failed');
            }
        } catch (e) {
            dispatch(setSubscribeFailed());
        }
    }
);

export const resetErrorSubscribe = () => (dispatch) => {
    dispatch(resetSubscribeFailed());
};

// Slice
const kitchenSlice = createSlice({
    name: 'kitchen',
    initialState,
    reducers: {
        setCurrentDayAction(state, action) {
            state.currentDay = action.payload;
        },
        setSubscribeFailed(state) {
            state.errorSubscribe = true;
        },
        resetSubscribeFailed(state) {
            state.errorSubscribe = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeliverDay.pending, (state) => {
                state.isLoadingDays = true;
                state.menuLoader = true;
            })
            .addCase(getDeliverDay.fulfilled, (state, action) => {
                state.deliveryDays = action.payload;
                state.currentDay = moment(action.payload[0]) || null;
                state.isLoadingDays = false;
                state.errorDays = false;
            })
            .addCase(getDeliverDay.rejected, (state) => {
                state.isLoadingDays = false;
                state.errorDays = true;
            })
            .addCase(getMenuByDay.pending, (state) => {
                state.isLoadingKitchens = true;
                state.menuLoader = true;
            })
            .addCase(getMenuByDay.fulfilled, (state, action) => {
                state.kitchenData = action.payload.data;
                state.serverTime = action.payload.serverTime;
                state.isLoadingKitchens = false;
                state.menuLoader = false;
                state.errorKitchens = false;
            })
            .addCase(getMenuByDay.rejected, (state) => {
                state.isLoadingKitchens = false;
                state.menuLoader = false;
                state.errorKitchens = true;
                state.kitchenData = [];
            })
            .addCase(getRecommendation.pending, (state) => {
                state.errorSubscribe = false;
            })
            .addCase(getRecommendation.rejected, (state) => {
                state.errorSubscribe = true;
            });
    },
});

export const {
    setCurrentDayAction,
    setSubscribeFailed,
    resetSubscribeFailed,
} = kitchenSlice.actions;

export default kitchenSlice.reducer;
