import {NoData} from "../share/NoData/NoData";
import NoOrder from "../../../../images/Profile/NoOrders.png";
import {WrapperContainer} from "../../../../share/ui/WrapperContainer/WrapperContainer";
import React, {useEffect} from "react";
import {Skeleton} from "../../../../utils/skeleton";
import styled from "styled-components";
import {ContentItemOrder} from "./ContentItemOrder/ContentItemOrder";
import {ErrorBanner} from "./ErrorBanner/ErrorBanner";
import {createTimeRanges} from "../../../../share/libs/createTimeRanges";

export const MyOrders = ({
 data,
 isLoading,
 getOrders,
 serverTime,
 hasMore,
 resetOrders,
 serverError
}) => {
    useEffect(() => {
        getOrders()
        return () => {
            resetOrders()
        }
    }, []);
    const timeIsRangeOut = createTimeRanges(serverTime,data,undefined, 'shutoffKitchen')
    return (<>
        <OrdersContainer >
            {

                 data.map((element, index) =>
                    <ContentItemOrder created = {element.created}
                                      serverTime = {serverTime}
                                      isPayed = {element.payment.isPayed}
                                      finalPrice={element.payment.finalPrice}
                                      statusName = {element.statusName}
                                      status = {element.status}
                                      shutoffKitchen ={element.shutoffKitchen}
                                      ordersKitchen = {element.ordersKitchen}
                                      delivery = {element.delivery}
                                      isFirst = {index === 0}
                                      key = {index}
                                      idGroup={element.orderGroupId}
                                      adress={element.adress}
                                      pay={element.pay}
                                      restaran={element.restaran}
                                      state={element.state}
                                      TypeRestaran={element.TypeRestaran}
                                      timeIsRangeOut = {timeIsRangeOut[index]}
                    />)


            }
            {
                isLoading || timeIsRangeOut.length <= 0  ?  <Skeleton height={400} count={4}/> : <WrapperContainer serverError={serverError} hasMore={hasMore} action={getOrders}/>
            }
            {
                (data.length === 0 && !isLoading && !serverError) && <NoData img={NoOrder} title={'Здесь пока пусто'} text={'Оформите первый заказ и вся\n'+'история о нём будет храниться здесь'}/>
            }
        </OrdersContainer>



        </>)
}




const OrdersContainer = styled.div `
  width: 100%;
  max-width: 816px;
`

