import React from 'react'
import styled from "styled-components";
import Switch from '@material-ui/core/Switch';
import {Option} from "./Option/Option";
export const MyPrefers = () => {
    const options = ['У меня аллергия на лактозу', 'Я ем только рыбу и курицу', 'Я вегетарианец','Не люблю сладкое', 'Я не ем жареное', 'Я не ем острое', 'Ненавижу лук']
    return (<>
  <PrefersContainer>
      <p>Эти настройки помогут нам лучше собирать
          для вас готовые наборы с обедами</p>
      {
          options.map((option,index) => <Option title={option} last={index === options.length - 1}/>)
      }
  </PrefersContainer>
        </>)
}

export const PrefersContainer = styled.div`
  max-width: 787px;
  width: 100%;
  height: 639px;
  border-radius: 16px;
  background-color: #FFFFFF;
  padding: 32px 48px;
  p {
    font-family: 'SFProTextRegular';
    font-size: 18px;
    color: #828282;
    max-width: 400px;
  }


`

