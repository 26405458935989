import React, {useEffect, useRef, useState} from 'react'
import {TypesPlane} from "./types";
import {Plane} from "./Plane/Plane";
import styled from "styled-components";
export const MyFinance = () => {
    const data = [{title: 'Мои баллы', subtitle: 'по умолчанию', points: 100, type: TypesPlane.MY_POINTS}, {title: 'Коорпаративный счет', points: 500, type: TypesPlane.MY_CHECK}]
    const [isScroll, setScroll] = useState(false)
    const ref = useRef(null)
    let timeOut;
    useEffect(() => {
        if(ref.current) {
            ref.current.addEventListener('scroll',Scrolling)
        }
        return () => {
            ref.current.removeEventListener('scroll',Scrolling)
        }
    }, []);
    const Scrolling = () => {
        clearTimeout(timeOut)
        setScroll(true)
        timeOut = setTimeout(() => {
                setScroll(false)
            },1000)
    }
    return (<>
        <FinanceContainer ref = {ref} isScroll = {isScroll}>
            {
                data.map((element, index) => <Plane key = {index} isFirst = {index === 0 || index === 1}  title={element.title} subtitle={element.subtitle} points={element.points} types={element.type}/>)
            }
            <Plane types={TypesPlane.CREATE_PLANE}/>
            <Plane types={TypesPlane.CREATE_PLANE}/>
            <Plane types={TypesPlane.CREATE_PLANE}/>
        </FinanceContainer>

        </>)
}


const FinanceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 816px;
    //max-height: 478px;
    //overflow-y: scroll;
    padding-right: 10px;
   
 
  // &::-webkit-scrollbar {
  //   width: 3px;
  //  
  // }
  //
  //
  // &::-webkit-scrollbar-track {
  //   background-color: transparent;
  //
  // }
  //
  //
  // &::-webkit-scrollbar-thumb {
  //   background-color: ${({isScroll}) => isScroll ? '#1f1f1f1f' : 'transparent'};
  //   border-radius: 6px;
  // }
`
