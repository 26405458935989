import React, { useState } from "react";
import styled from "styled-components";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import Button from "./Button"; // Предполагается, что у вас уже есть компонент Button

const AddressSelector = () => {
  const [address, setAddress] = useState("Революционная, 16");
  const [isAddressIncorrect, setIsAddressIncorrect] = useState(false);
  const [coordinates, setCoordinates] = useState([55.751574, 37.573856]); // Координаты по умолчанию

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsAddressIncorrect(!isAddressIncorrect);
  };

  const handleMapClick = (e) => {
    const coords = e.get("coords");
    setCoordinates(coords);
  };

  const handleNext = () => {
    if (!isAddressIncorrect) {
      console.log("Адрес подтвержден:", address, coordinates);
      // Дальнейшая логика при нажатии "Далее"
    } else {
      console.log("Адрес определён некорректно");
      // Логика обработки некорректного адреса
    }
  };

  return (
    <Wrapper>
      <Title>Укажите ваш адрес</Title>
      <AddressWrapper>
        <InputWrapper>
          <img src={require('../images/Header/SendLight.png')} alt="address icon" />
          <AddressInput
            type="text"
            value={address}
            onChange={handleAddressChange}
            placeholder="Введите адрес"
          />
          <StyledButton type="primary" onClick={handleNext}>
            Далее
          </StyledButton>
        </InputWrapper>
        <CheckboxWrapper>
          <input
            type="checkbox"
            checked={isAddressIncorrect}
            onChange={handleCheckboxChange}
          />
          <span>Адрес определён некорректно</span>
        </CheckboxWrapper>
      </AddressWrapper>
      <MapWrapper>
        <YMaps>
          <Map
            defaultState={{ center: coordinates, zoom: 16 }}
            width="100%"
            height="300px"
            onClick={handleMapClick}
          >
            <Placemark geometry={coordinates} />
          </Map>
        </YMaps>
      </MapWrapper>
    </Wrapper>
  );
};

export default AddressSelector;

// Стили
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
`;

const AddressWrapper = styled.div`
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddressInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  padding: 8px 16px;
`;

const CheckboxWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MapWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

