import React, {useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMenu, getMenuInfo } from "../redux/reducer/slices/menuSlice";
import {getDeliverDay} from "../redux/reducer/slices/kitchenSlice";
import { Helmet } from "react-helmet";
import { SectionBottom, Container } from "../core/styles";
import styled from "styled-components"
import bicycle from "../images/bicycle.svg";
import {Loader} from "../utils/loader";
import {DiePeriods} from "../containers/Menu/DetailMenu/DiePeriods/DiePeriods";
import moment from "moment";
import BackButton from "../components/BackButton";
import ProductsContainer from "../components/ProductsContainer";
import EmptyData from "../components/EmptyData";

const KitchenPage = () => {
  const { kitchenInfo, menu } = useSelector((state) => state.menu);
  const { deliveryDays } = useSelector((state) => state.kitchen);
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      Promise.all([
          dispatch(getMenuInfo({kitchenId: urlParams.get('kitchen')})),
          dispatch(getDeliverDay()),
          dispatch(getMenu({
              kitchenId: urlParams.get('kitchen'),
              kitchenRacePolygonId: urlParams.get('race'),
              deliveryDay: new moment (urlParams.get('date').replaceAll('.','-'), 'DD-MM-YYYY').format('YYYY-MM-DD')
          }
          ))
      ]).then(() => {
          setIsLoading(false)
      })
  }, []);

  const handleClickDeliveryDay = (day) => {
      setIsLoading(true)
      dispatch(getMenu({
          kitchenId: urlParams.get('kitchen'),
          kitchenRacePolygonId: urlParams.get('race'),
          deliveryDay: day.split('-').reverse().join('-')
      })).then(() => setIsLoading(false))
  }

  const handleClickKitchenInfo = () => {

  }

  return (
    <>
      <Helmet>
        <title>Меню</title>
      </Helmet>

      <SectionBottom>
        <Container>
          {isLoading ?
            <Loader heightContainer={1200}/> 
          : (
            <>
              <Header>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <BackButton title={'Кушать подано'}/>
                  <button onClick={handleClickKitchenInfo}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="12" stroke="#333333" stroke-width="1.33333"/>
                      <path d="M16.6668 9.99998C16.6668 10.3682 16.3684 10.6666 16.0002 10.6666C15.632 10.6666 15.3335 10.3682 15.3335 9.99998C15.3335 9.63179 15.632 9.33331 16.0002 9.33331C16.3684 9.33331 16.6668 9.63179 16.6668 9.99998Z" fill="#333333"/>
                      <path d="M16 22.6666V13.3333" stroke="#333333" stroke-width="1.33333"/>
                    </svg>
                  </button>
                </div>
                <Rating>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.0304 10.5639L20.037 9.19515L16.0167 0.660254C15.9069 0.426573 15.7262 0.237403 15.5031 0.122418C14.9434 -0.166901 14.2633 0.074198 13.9835 0.660254L9.96319 9.19515L0.969774 10.5639C0.721827 10.6009 0.495132 10.7233 0.321568 10.9088C0.11174 11.1347 -0.00388537 11.4385 9.96959e-05 11.7536C0.00408476 12.0686 0.127354 12.3691 0.342821 12.5891L6.84968 19.2323L5.3124 28.6129C5.27635 28.8311 5.29941 29.0555 5.37896 29.2607C5.45852 29.4659 5.59138 29.6436 5.76249 29.7738C5.9336 29.9039 6.13611 29.9812 6.34705 29.997C6.55799 30.0127 6.76893 29.9663 6.95594 29.8629L15.0001 25.4341L23.0442 29.8629C23.2638 29.9853 23.5188 30.0261 23.7633 29.9816C24.3796 29.8703 24.794 29.2583 24.6877 28.6129L23.1505 19.2323L29.6573 12.5891C29.8344 12.4073 29.9513 12.1699 29.9867 11.9103C30.0824 11.2612 29.6502 10.6603 29.0304 10.5639Z" fill="url(#paint0_linear_4275_2377)"/>
                    <defs>
                      <linearGradient id="paint0_linear_4275_2377" x1="0.75" y1="5.25" x2="31.5" y2="30.75" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFDD29"/>
                        <stop offset="1" stop-color="#FFB72B"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <div>{kitchenInfo.rating ? kitchenInfo.rating : '0.0'}</div>
                </Rating>
              </Header>

              <NavigateContainer>
                  {
                      deliveryDays.length > 0 && deliveryDays.map((deliveryDay, index) =>
                          <DiePeriods
                            text={deliveryDay.text}
                            day = {deliveryDay.day}
                            index={index}
                          //  indexDate = {indexDate}
                            getMenuByClick = {handleClickDeliveryDay}
                            kitchenId={urlParams.get('kitchen')}
                            kitchenRacePolygonId={urlParams.get('race')}
                          />
                      )
                  }
              </NavigateContainer>

              <DeliveryInfoContainer>
                  <img src={bicycle} width={32} height={32} alt="" />
                  <p className='DetailMenu__DeliveryInfoTitle'>с 11:30 до 12:30</p>
              </DeliveryInfoContainer>

              {/* Спец предложения кухни тут...*/}

              {menu && !Array.isArray(menu.products) && menu.products instanceof Object ?
                <ProductsContainer data={menu} />
              :
                <EmptyData/>
              }

            </>
          )}
        </Container>
      </SectionBottom>
    </>
  )
}

export default KitchenPage;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: fit-content;

  div {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
`;

export const NavigateContainer = styled.div`
  margin-top: 100px;
  display: flex;
`;

export const Title = styled.h1`
  align-self: flex-end;
  margin: 0 0 0 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #2C2E32;
  user-select: none;
`;

export const DeliveryInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const DeliveryInfoTitle = styled.h2`
  margin: 0 0 0 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #2C2E32;
  user-select: none;
`;

export const PeriodsContainer = styled.div`
  display: flex;
  margin-top: 20px;

  & > div:first-child {
    margin: 0 !important;
  }
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #2C2E32;
    user-select: none;
  }
`;