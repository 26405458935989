import React, { useEffect } from 'react'
import styled,{ keyframes } from "styled-components";

export const Skeleton = ({
    width,
    height,
    count
                         }) => {
     const arrayPrint = []
    const fillArray = () => {
         for (let i = 0; i < count; i++) {
            arrayPrint.push(i)
         }
    }
      fillArray()


    return (<>
    <SkeletonContainer>
            {
             arrayPrint.map(() => <ItemSkeleton width = {width} height = {height}/>)
            }
    </SkeletonContainer>


        </>)
}

const load = keyframes`
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
`
const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

`
const ItemSkeleton = styled.div`
  max-width:${({width}) => width ? `${width}px` : '100%'} 
  width: 100%;
  height: ${({height}) => `${height}px`};
  position: relative;
  //box-shadow: 0 2px 10px 0 #5f5f6c;
  overflow: hidden;
  border-radius: 16px;
  margin-top: 16px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(to right, transparent 0%, #EA394B 50%, transparent 100%);
    animation: ${load} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`


