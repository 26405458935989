import axios from "axios/index";

let baseURL = 'https://api-test.menuforme.online/api/v2/website/';
export class axiosRequest {
    constructor() {
         this.instance = axios.create({
             baseURL,
         });
    }

    instance;
}

export const updateServerURL = (newURL) => {
    baseURL = newURL;
};