import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import VoidCart from '../images/Cart/VoidCart.png';
import noMenu from '../images/menu/noMenu.png'
import Button from './Button';

const EmptyData = ({type}) => {
  return (
    <>
      {type === 'cart' ? 
        <Wrapper>
          <Image src={VoidCart} alt={''}/>
          <Title>Корзина пуста</Title>
          <Text>Вы еще не добавили ни один товар в корзину</Text>
          <Link style={{marginTop: '32px'}} to={'/'}><Button>Вернуться на главную</Button></Link>
        </Wrapper>
      :
        <Wrapper>
          <Image src={noMenu} alt={''}/>
          <Text>Меню пока не опубликовано,</Text>
          <Text>приходите позже</Text>
        </Wrapper>
      }
    </>
  );
};

export default EmptyData;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const Image = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
`;

const Title = styled.div`
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
`;

const Text = styled.div`
  margin-top: 16px;
  font-size: 24px;
`;
