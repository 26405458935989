import { createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';

const initialState = {
    isLoadingPromotion: false,
    errorsPromotions: null,
    storiesKitchens: [],
};

const promotionSlice = createSlice({
    name: 'promotion',
    initialState,
    reducers: {
        getPromotionsRequest(state) {
            state.isLoadingPromotion = true;
        },
        getPromotionsSuccess(state, action) {
            state.storiesKitchens = action.payload;
            state.isLoadingPromotion = false;
        },
        getPromotionsFailed(state, action) {
            state.errorsPromotions = action.payload;
            state.isLoadingPromotion = false;
            state.storiesKitchens = [];
        },
        getPromotionsFailedServer(state) {
            state.isLoadingPromotion = false;
            state.storiesKitchens = [];
        },
        resetPromotions(state) {
            state.storiesKitchens = [];
        },
    },
});

export const {
    getPromotionsRequest,
    getPromotionsSuccess,
    getPromotionsFailed,
    getPromotionsFailedServer,
    resetPromotions,
} = promotionSlice.actions;

export const getPromotions = () => async (dispatch, getState) => {
    const api = new axiosRequest().instance;
    dispatch(getPromotionsRequest());
    try {
        const response = await api.post('kitchens/getStoriesKitchen', {
            data: {
                token: localStorage.getItem('token'),
                city: {
                    id: getState().settings.currentCity.id,
                },
                clientInfo: {
                    os: 'android',
                    osVersion: '33',
                    model: 'M2101K6G',
                    brand: 'Redmi',
                    screenWidth: 1080,
                    deviceId: '69516350155354a2',
                    appVersion: '1.29.13',
                    appId: 'com.menu4me.dinner.sitiyoffice',
                    locale: 'ru_RU',
                },
            },
        });

        if (response.data.result) {
            dispatch(getPromotionsSuccess(response.data.data.storiesKitchens));
        } else {
            dispatch(getPromotionsFailed());
        }
    } catch (e) {
        dispatch(getPromotionsFailedServer());
    }
};

export const resetPromotion = () => (dispatch) => {
    dispatch(resetPromotions());
};

export default promotionSlice.reducer;
