import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import combineReducer from './reducer/combineReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['common', 'profile'],
  timeout: 10000,
};

const persistedReducer = persistReducer(persistConfig, combineReducer);

export { persistedReducer };