import React from 'react';
import styled from "styled-components";
import {TypesPlane} from "../types";
import {MyCart} from "./MyCart";

export const Plane = ({imgPath, typeCart, code, type, isFirst}) => {

    const SwitchType = (type) => {
         switch (type) {
             case TypesPlane.MY_CART:
                 return <MyCart imgPath={imgPath} typeCart={typeCart} code={code}  />
             case TypesPlane.CREATE_PLANE:
                 return <MyCart/>
             default:
                 return null
         }
    }

    return(<>
       <PlaneConatiner isFirst = {isFirst} type = {type}>
           {SwitchType(type)}
       </PlaneConatiner>

        </>)
}


const PlaneConatiner = styled.div`
  width: 100%;
  max-width: 384px;
  height: 229px;
  padding: 10px 24px;
  border-radius: 16px;
  font-family: Montserrat;
  font-weight: 500;
  background-color: ${({type}) => type === TypesPlane.CREATE_PLANE ? '#C0DAEC' : '#FFFFFF'};
  box-shadow: #0000000D;
  margin-top: ${({isFirst}) => isFirst ? '0px' : '15px'};
`

