import React from 'react'
import i18n from "i18next";

export const RestTimer = ({
                              time,
                              timeString
                          }) => {
    return (<>
        <p>{time&& i18n.t(`${timeString}.${i18n.services.pluralResolver.getSuffix('ru', time)}`,  {val: time})}</p>
    </>)
}
