import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';

export const getSettings = createAsyncThunk(
    'settings/getSettings',
    async (_, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.post('settings', {
                data: {
                    "token": localStorage.getItem('token'),
                    "clientInfo": {
                        "os": "android",
                        "osVersion": "33",
                        "model": "M2101K6G",
                        "brand": "Redmi",
                        "screenWidth": 1080,
                        "deviceId": "69516350155354a2",
                        "appVersion": "1.29.13",
                        "appId": "com.menu4me.dinner.sitiyoffice",
                        "locale": "ru_RU"
                    }
                }
            });

            if (response.data.result) {
                return response.data.data.settings;
            } else {
                return rejectWithValue('Failed to fetch settings');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        cities: [],
        currentCity: JSON.parse(localStorage.getItem('city')) || { id: 18, name: 'Тольятти' },
        haveCity: !!localStorage.getItem('city'),
        isLoading: false,
        error: null,
        isCityModalOpen: false,
    },
    reducers: {
        setCurrentCity(state, action) {
            const city = action.payload;
            if (city) {
                localStorage.setItem('city', JSON.stringify(city));
            } else if (!localStorage.getItem('city')) {
                localStorage.setItem('city', JSON.stringify(state.cities[0]));
            }
            state.currentCity = city || JSON.parse(localStorage.getItem('city'));
            state.haveCity = true;
        },
        setIsCityModalOpen: (state) => {
            state.isCityModalOpen = true;
        },
        setIsCityModalClose: (state) => {
            state.isCityModalOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSettings.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.cities = action.payload.cities;
                state.isLoading = false;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch settings';
            });
    }
});

export const {
  setCurrentCity,
  setIsCityModalOpen,
  setIsCityModalClose,
} = settingsSlice.actions;

export default settingsSlice.reducer;
