import React, {useEffect, useRef, useState} from 'react'
import {TypesPlane} from "./types";
import styled from "styled-components";
import {Plane} from "./Plane/Plane";
import {CreatePlane} from "./Plane/CreatePlane";
import {NoData} from "../share/NoData/NoData";
import NoAderss from "../../../../images/Profile/NoAdress.png"

export const MyAdress = ({type}) => {
    const data = [{typeAdress: 'Дом', adress: 'ул. Кулибина, 2, под 4, кв 65', type: TypesPlane.MY_ADRESS}, {typeAdress: 'Работа', adress: 'Южное шоссе 131, офис 45', type: TypesPlane.MY_ADRESS}]
    const [isScroll, setScroll] = useState(false)
    const ref = useRef(null)
    let timeOut;
    useEffect(() => {
        if(ref.current) {
            ref.current.addEventListener('scroll',Scrolling)
        }
        return () => {
            ref.current.removeEventListener('scroll',Scrolling)
        }
    }, []);
    const Scrolling = () => {
        clearTimeout(timeOut)
        setScroll(true)
        timeOut = setTimeout(() => {
            setScroll(false)
        },1000)
    }
    return (<>
        {
            data.length > 0 ? <AdressContainer ref = {ref} isScroll = {isScroll}>
                {
                    data.map((element, index) => <Plane key = {index} isFirst = {index === 0 || index === 1} typeAdress = {element.typeAdress} adress={element.adress} type={element.type}/>)
                }
                <CreatePlane/>
                <CreatePlane/>
                <CreatePlane/>
            </AdressContainer> : <NoData img={NoAderss} title = {'У вас пока нет карт'} text={'Банковскую карту можно добавить\n' +
                'при оформлении заказа'}/>

        }


        </>)
}


const AdressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 816px;
  //max-height: 478px;
  //overflow-y: scroll;
  padding-right: 10px;


  // &::-webkit-scrollbar {
  //   width: 3px;
  //
  // }
  //
  //
  // &::-webkit-scrollbar-track {
  //   background-color: transparent;
  //
  // }
  //
  //
  // &::-webkit-scrollbar-thumb {
  //   background-color: ${({isScroll}) => isScroll ? '#1f1f1f1f' : 'transparent'};
  //   border-radius: 6px;
  // }
`
