import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart, removeProductFromCart } from '../redux/reducer/slices/cartSlice';
import styled from "styled-components";
import NoImg from '../images/menu/NoImg.png';

const ProductCard = ({ product, onClick }) => {
  const { products } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(product.amountPerOrder);

  useEffect(() => {
    const matchingProducts = products.filter(item => item.id === product.id);
  
    const totalQuantity = matchingProducts.reduce((sum, item) => sum + item.quantity, 0);
    
    setCount(totalQuantity);
    setAvailableCount(product.amountPerOrder - totalQuantity);
  }, [products, product]);

  const handleClickIncrementCount = () => {
    if (availableCount > 0) {
      dispatch(addProductToCart(product));
    }
  };

  const handleClickDecrementCount = () => {
    if (count > 1) {
      dispatch(removeProductFromCart({ productId: product.id, removeAll: false }));
    } else {
      dispatch(removeProductFromCart({ productId: product.id, removeAll: true }));
    }
  };

  if (!product) {
    return null;
  }

  return (
    <Card>
      <ImageContainer onClick={onClick}>
        {product.pictures && product.pictures.small && product.pictures.small.length > 0 ? (
          <Image src={product.pictures.small[0]} alt={product.name} />
        ) : (
          <Image style={{objectFit: 'contain'}} src={NoImg} alt={''} />
        )}
        <InfoBadge>
          {availableCount} шт
        </InfoBadge>
      </ImageContainer>
      <CardInfo>
        <Title onClick={onClick}>{product.name}</Title>
        <Weight>{product.weight} г</Weight>
        {count > 0 ? (
          <CounterContainer>
            <CounterButton onClick={handleClickDecrementCount}>-</CounterButton>
            <Count>{count}</Count>
            <CounterButton 
              onClick={handleClickIncrementCount} 
              disabled={availableCount === 0}
            >
              +
            </CounterButton>
          </CounterContainer>
        ) : (
          <CounterContainer onClick={handleClickIncrementCount}>
            <CounterButton>{product.price} ₽</CounterButton>
          </CounterContainer>
        )}
      </CardInfo>
    </Card>
  );
};

export default ProductCard;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  box-shadow: 0px 8.92941px 25.0024px rgba(193, 193, 193, 0.25);
  border-radius: 16px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 220px;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InfoBadge = styled.div`
  position: absolute;
  background-color: #2C2E32;
  display: flex;
  align-items: center;
  bottom: 12px;
  right: 12px;
  padding: 10px 15px;
  border-radius: 100px;
  color: #FFFFFF;
  font-size: 20px;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;

const Title = styled.h1`
  height: 45px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  color: #2C2E32;
  cursor: pointer;
`;

const Weight = styled.p`
  margin-top: 16px;
  font-size: 14px;
  color: #A4A4A4;
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  margin-top: 8px;
`;

const CounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 12px;
  background-color: #F8F8F8;
  border: none;
  border-radius: 12px;
  margin-top: 8px;
  font-size: 20px;
  cursor: pointer;
`;

const Count = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex: 1;
  font-size: 24px;
  font-weight: 500;
`;