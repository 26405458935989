import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../redux/reducer/slices/cartSlice";
import { Helmet } from "react-helmet";
import { SectionTop, Container } from "../core/styles";
import styled from "styled-components"

import BackButton from "../components/BackButton";

// import ArrowBack from './imgs/BackArrow.png'
// import { LinkCustom } from "../../common/link/Link";
// import {Order} from "./Order/Order";
// import {} from "../../redux/reducer/slices/cartSlice";
import { Loader } from "../utils/loader";
import EmptyData from "../components/EmptyData";
import OrdersContainer from "../components/OrdersContainer";
import Button from "../components/Button";

const CartPage = () => {
  const { products, totalPrice } = useSelector((state) => state.cart);
  const dispatch = useDispatch()

  const handleClearCart = () => {
    dispatch(clearCart())
  }

  const handleGoToPurchase = () => {

  }

  return (
    <>
      <Helmet>
        <title>Корзина</title>
      </Helmet>

      <SectionTop>
        <Container>
          <Header>
            <BackButton title={'Корзина'}/>
            <ClearCart onClick={handleClearCart}>Очистить корзину</ClearCart>
          </Header>
        </Container>
      </SectionTop>
      
      <Container>
        {products.length > 0 ? (
          <>
            <OrdersContainer/>

            <CartPurchaseContainer>
              <CartPurchaseInfo>
                <CartPurchaseCost>{totalPrice} ₽</CartPurchaseCost>
                <CartPurchaseTime>с 11:30 до 12:30</CartPurchaseTime>
              </CartPurchaseInfo>
              <Button
                type='primary'
                onClick={handleGoToPurchase}
              >
                Перейти к оформлению
              </Button>
            </CartPurchaseContainer>
          </> 
        ) : (
          <EmptyData type='cart'/>
        )}
      </Container>
    </>
  )
}

export default CartPage;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const ClearCart = styled.button`
  font-size: 24px;
  font-weight: 400;
  color: #9d9d9d;
  cursor: pointer;
`;

const CartPurchaseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0 150px;
  padding: 32px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
`;

const CartPurchaseInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CartPurchaseCost = styled.p`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const CartPurchaseTime = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #a4a4a4;
`;
