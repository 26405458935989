import React, {Component} from "react";
import styled from "styled-components";
import logoImg from "../../../images/ig-logo.png";
import ig1 from "../../../images/instagram/ig-1.png";
import ig2 from "../../../images/instagram/ig-2.png";
import ig3 from "../../../images/instagram/ig-3.png";
import ig4 from "../../../images/instagram/ig-4.png";

class Instagram extends Component {
	render() {
		return (
			<Container>
				<Wrapper>
					<Header>
						<Logo src={logoImg} />
						<Text>
							Больше отзывов <br /> вы найдете по хэштегу <br />
							<TextLink
								href="https://www.instagram.com/explore/tags/%D1%81%D1%8B%D1%82%D1%8B%D0%B9%D0%BE%D1%84%D0%B8%D1%81/"
								target="_blank">
								#сытый
							</TextLink>
						</Text>
						<Link href="https://www.instagram.com/sitiy_tlt/" target="_blank">
							Instagram
						</Link>
					</Header>
					<Grid>
						<Item>
							<ItemImg style={{ backgroundImage: `url(${ig1})` }} />
						</Item>
						<Item>
							<ItemImg style={{ backgroundImage: `url(${ig2})` }} />
						</Item>
						<Item>
							<ItemImg style={{ backgroundImage: `url(${ig3})` }} />
						</Item>
						<Item>
							<ItemImg style={{ backgroundImage: `url(${ig4})` }} />
						</Item>
					</Grid>
				</Wrapper>
			</Container>
		);
	}
}

export default Instagram;

const Container = styled.div`
	padding: 45px 0 15px;
	background-color: #f8f8f8;
`;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 0 15px;
	margin: 0 auto;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: 700px) {
		flex-direction: column;
	}
`;

const Logo = styled.img`
	margin-right: 80px;

	@media (max-width: 768px) {
		margin-right: 30px;
	}

	@media (max-width: 700px) {
		margin-right: 0;
		margin-bottom: 20px;
	}
`;

const Text = styled.p`
	line-height: 1.35;
	color: rgb(0, 0, 0);
	margin-bottom: 0;
	font-size: 17px;

	@media (max-width: 700px) {
		text-align: center;
		margin-bottom: 20px;
	}
`;

const TextLink = styled.a`
	color: #E0D5D2;

	&:hover {
		color: #E0D5D2;
	}
`;

const Link = styled.a`
	margin-left: auto;
	color: #fff;
	padding: 10px 60px;
	border-radius: 55px;
	background-color: #E0D5D2;

	&:hover {
		color: #fff;
		text-decoration: none;
	}

	@media (max-width: 700px) {
		margin-left: 0;
	}
`;

const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
`;

const ItemImg = styled.div`
	width: 100%;
	height: 100%;
	transition: all .6s ease;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
`;

const Item = styled.div`
	padding: 0 15px;
	flex-basis: 25%;
	max-width: 25%;
	margin-bottom: 30px;
	height: 500px;
	overflow-y: hidden;

	&:hover {
		${ItemImg} {
			background-position: center bottom;
		}
	}

	@media (max-width: 992px) {
		flex-basis: 50%;
		max-width: 50%;
	}

	@media (max-width: 450px) {
		flex-basis: 100%;
		max-width: 100%;
	}
`;
