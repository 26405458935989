import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "../core/styles";
import Input from "./Input";
import Button from "./Button";

const Subscribe = () => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const handleClick = () => {
    console.log(value);
  }

  return (
    <Wrapper>
      <Container>
        <Flex>
          <Info>
            <svg style={{width: '60px'}} width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M33.3389 17.1542C32.9188 16.9102 32.3857 16.9638 31.8763 17.3013L22.4649 23.5377C22.203 23.7113 22.1244 24.0754 22.2893 24.351C22.4543 24.6266 22.8003 24.7093 23.0622 24.5358L32.4735 18.2994C32.6723 18.1677 32.7811 18.1774 32.7972 18.1868C32.8134 18.1963 32.8791 18.2879 32.8791 18.535C32.8791 19.1994 32.4105 20.0927 31.8763 20.4467L22.7525 26.4924C22.2674 26.8139 21.8239 26.9023 21.5357 26.7349C21.2475 26.5675 21.0823 26.1255 21.0823 25.5222C21.0823 23.9937 22.1207 22.0143 23.3498 21.1999L33.7382 14.3162C33.9012 14.2082 34 14.0197 34 13.8171C34 13.6144 33.9011 13.426 33.7382 13.318L13.7768 0.0907039C13.5943 -0.0302347 13.362 -0.0302347 13.1795 0.0907039L2.66467 7.05828C1.17048 8.04843 0 10.2795 0 12.1376V12.4522C0 14.3103 1.17048 16.5414 2.66467 17.5315L18.3039 27.8947C18.5658 28.0683 18.9119 27.9855 19.0768 27.7099C19.2417 27.4343 19.1631 27.0702 18.9012 26.8966L3.26195 16.5334C2.10133 15.7644 1.12087 13.8954 1.12087 12.4522V12.1376C1.12087 10.6944 2.10133 8.82541 3.26195 8.05637L13.4781 1.28672L32.3878 13.817L22.7525 20.2017C21.1874 21.2388 19.9614 23.5758 19.9614 25.5222C19.9614 26.5833 20.3281 27.3808 20.994 27.7676C21.2614 27.9229 21.5564 28 21.8689 28C22.3345 28 22.8392 27.8289 23.3497 27.4905L32.4735 21.4448C33.3295 20.8776 33.9999 19.5995 33.9999 18.535C33.9999 17.9015 33.759 17.3983 33.3389 17.1542Z" fill="#2C2E32"/>
              <path d="M26.4418 15C26.627 15 26.8082 14.9 26.9142 14.7177C27.0783 14.4353 27 14.0623 26.7395 13.8844L13.8548 5.09298C13.5941 4.91518 13.2499 5.00001 13.0858 5.2823C12.9217 5.56467 13 5.93776 13.2605 6.11556L26.1452 14.907C26.2374 14.97 26.3402 15 26.4418 15Z" fill="#2C2E32"/>
              <path d="M24.4042 16C24.6023 16 24.796 15.8977 24.909 15.7113C25.0836 15.4232 24.9994 15.0432 24.7209 14.8626L18.9114 11.0942C18.6328 10.9134 18.2655 11.0007 18.091 11.2887C17.9164 11.5769 18.0006 11.9568 18.2791 12.1374L24.0886 15.9058C24.1869 15.9696 24.2961 16 24.4042 16Z" fill="#2C2E32"/>
              <path d="M16.9047 13.0933C16.6277 12.9136 16.2628 13.0013 16.0898 13.289C15.9168 13.5767 16.0012 13.9555 16.2783 14.1351L22.0953 17.9067C22.1928 17.9699 22.301 18 22.408 18C22.6052 18 22.798 17.8976 22.9102 17.711C23.0832 17.4233 22.9988 17.0445 22.7217 16.8649L16.9047 13.0933Z" fill="#2C2E32"/>
              <path d="M10.8102 6.09183L6.25596 9.21735C6.09616 9.327 5.99942 9.51857 6 9.72439C6.00059 9.93022 6.09828 10.1212 6.25866 10.2298L11.6901 13.9099C11.7787 13.97 11.879 14 11.9794 14C12.083 14 12.1864 13.9681 12.2769 13.9044L13.4866 13.0524C13.7408 12.8733 13.8136 12.5037 13.6494 12.2267C13.4851 11.9497 13.1459 11.8704 12.8917 12.0493L11.9738 12.6958L7.58109 9.71946L11.1029 7.3025L15.4317 10.2603L14.927 10.6157C14.6728 10.7948 14.6 11.1644 14.7642 11.4414C14.9285 11.7184 15.2679 11.7978 15.5219 11.6188L16.7494 10.7542C16.9068 10.6433 17.0012 10.4526 17 10.2485C16.9987 10.0444 16.9018 9.85514 16.7431 9.74669L11.3933 6.09127C11.215 5.96937 10.9883 5.9696 10.8102 6.09183Z" fill="#2C2E32"/>
              <path d="M20.9113 18.7108C21.0827 18.4235 20.9982 18.0459 20.7226 17.8673L14.898 14.0925C14.6223 13.9138 14.26 14.0019 14.0887 14.2892C13.9173 14.5765 14.0018 14.9542 14.2774 15.1328L20.102 18.9076C20.1985 18.9702 20.3058 19 20.4117 19C20.6081 19 20.8001 18.8974 20.9113 18.7108Z" fill="#2C2E32"/>
              <path d="M4.08554 11.2744C3.92026 11.5469 4.00173 11.9051 4.2676 12.0746L18.1339 20.9123C18.227 20.9717 18.3304 21 18.4326 21C18.622 21 18.8071 20.9027 18.9145 20.7257C19.0797 20.4531 18.9983 20.0949 18.7324 19.9255L4.86615 11.0878C4.60028 10.9182 4.25082 11.0018 4.08554 11.2744Z" fill="#2C2E32"/>
            </svg>
            <p>Подпишитесь на новости, мы присылаем только важные новости, акции и скидки</p>
          </Info>
          <Form>
            <Input
              type='text'
              size='small'
              placeholder='Введите email'
              value={value}
              onChange={handleChange}
            />
            <Button type='primary' size='small' onClick={handleClick}>Подписаться</Button>
          </Form>
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default Subscribe;

const Wrapper = styled.div`
  margin-top: auto;
  padding: 16px 0;
  background: #C0DAEC;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 450px;
`;

const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;