import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import {TimeShower} from "../TimeShower/TimeShower";
import {SwitcherTimeShower} from "../TimeShower/types";

export const Timer = memo(({time, timeString, title, color}) => {
    return (<>
        <TimerContainer color = {color}>
           <p>{title}</p>  <TimeShower type={SwitcherTimeShower.REST_TIMER} timeString={timeString} time={time}/>
        </TimerContainer>
    </>)

})

const TimerContainer = styled.div`
  display: flex;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.7852098345756531px;
  margin: 0 !important;
  p {
    margin: 0 4px 0 4px !important;
    color: ${({color}) => `#${color}`} !important;
  }



`


