import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
// import {
//   getAddress,
//   getOwnAddresses,
//   resetAddress, resetAddressMark, resetAddressSuggest,
//   saveAddress,
//   setAddressFromMark, setMapCity,
//   setMapPointsFromClicker, setSuggestAddresses
// } from "../../redux/reducer/slices/mapSlice";
import { setIsCityModalClose, setCurrentCity } from '../redux/reducer/slices/settingsSlice';
import styled from "styled-components";
import Button from './Button';

const SelectCityForm = () => {
  const { cities, currentCity, haveCity, isLoading, error } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const handleCityClick = (city) => {
    localStorage.setItem('city', JSON.stringify(city));
    dispatch(setCurrentCity(city));
    // if (changeAdressSelect)
    //     changeAdressSelect()
    // if (setCity)
    //     setCity(city)
    // if (setPoints)
    //     setPoints()
    // if(openModalCreateAddress)
    //   openModalCreateAddress()

    dispatch(setIsCityModalClose());
  }
  return (
    <Wrapper>
      <Title>В каком вы городе?</Title>
      <Form>
        { !isLoading && cities.map(city => (
          <Button key={city.id} onClick={() => handleCityClick(city)}>{city.name}</Button>
        ))}
      </Form>
    </Wrapper>
  )
}

export default SelectCityForm

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
  color: #37474F;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
