import React from 'react'
import styled from "styled-components";
import shop from '../../../../images/Promotion/Shop.png'
export const PromotionItem = ({
    img,
    title,
    text,
    header,
    kitchen,
    backgroundImage


}) => {
    return (<>
    <ItemContainer  className="swiper-slide">
        <Item
            src = {backgroundImage.default[0]}
        >
                    <h1>{header}</h1>
                    <p>{title}</p>
        </Item>
        <FooterContainer>
            <img src = {shop}/>
            <p>{kitchen.name}</p>
        </FooterContainer>
    </ItemContainer>

        </>)
}

const ItemContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 185px !important;
  height: 225px;
 
`

const Item = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 185px;
  padding: 21px;
  border-radius: 32px;
  background:  ${({src}) =>  'url(' + src + ')' } center no-repeat;
  p {
    font-family: SFProTextRegular;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: -0.8666252493858337px;
    margin: 0;
    color: #FFFFFF;

  }
  h1 {
    font-family: SFProTextMedium;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.4011237323284149px;
    margin: 0;
    color: #FFFFFF;
  }
`

const FooterContainer = styled.div `
   display: flex;
   justify-content: left;
  margin-top: 21px;
  align-items: center;
  img {
    margin-right: 10px;
  }
  p {
    font-family: SFProTextRegular;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.8666252493858337px;
    margin: 0;
  }
`
