import React from 'react'
import create from '../../../../../images/Profile/CreateV2.png'
import styled from "styled-components";


export const CreatePlane = () => {
    return (<>
        <InnerContainer>
           <Image src = {create}/>
            <Header>Новая карта</Header>
        </InnerContainer>

    </>)
}




const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 384px;
  border-radius: 16px;
  height: 229px;
`
const Header = styled.h1`
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`

const Image = styled.img`
    max-width: 70px;
    max-height: 70px;
`
