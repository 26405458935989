import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  products: [],
  ordersCount: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    addProductToCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.products.find(item => item.id === product.id && item.isLocked === false);

      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        const existingKitchenOrder = state.products.find(item => item.kitchenId === product.kitchenId && item.isLocked === false);
        
        if (!existingKitchenOrder) {
          state.ordersCount += 1;
        }

        state.products.push({
          ...product,
          quantity: 1,
          multiplier: 1,
          order: existingKitchenOrder ? existingKitchenOrder.order : state.ordersCount,
          isLocked: false,
          orderName: '',
        });
      }

      state.totalPrice = state.products.reduce((acc, item) => acc + item.price * item.quantity * item.multiplier, 0);
    },
    removeProductFromCart: (state, action) => {
      const { productId, removeAll } = action.payload;
      const existingProduct = state.products.find(item => item.id === productId && item.isLocked === false);
  
      if (existingProduct) {
        if (removeAll) {
          state.products = state.products.filter(item => !(item.id === productId && item.isLocked === false));
        } else {
          existingProduct.quantity -= 1;
        }
      }
  
      state.totalPrice = state.products.reduce((acc, item) => acc + item.price * item.quantity * item.multiplier, 0);
    },
    removeOrder: (state, action) => {
      const orderToRemove = String(action.payload);

      state.products = state.products.filter(product => String(product.order) !== orderToRemove);
      state.totalPrice = state.products.reduce((acc, item) => acc + item.price * item.quantity * item.multiplier, 0);

      if (state.products.length === 0) {
        state.ordersCount = 0;
      }
    },
    lockedOrder: (state, action) => {
      const orderToLock = String(action.payload);
    
      state.products.forEach(product => {
        if (String(product.order) === orderToLock) {
          product.isLocked = true;
        }
      });
    },
    setMultiplier: (state, action) => {
      const { order, multiplier } = action.payload;
      const productsInOrder = state.products.filter(product => String(product.order) === order);
      let isEnoughStock = true;
    
      productsInOrder.forEach(product => {
        const matchingProducts = state.products.filter(item => item.id === product.id);
        const totalQuantity = matchingProducts.reduce((acc, item) => acc + item.quantity, 0);
        const availableCount = product.amountPerOrder - totalQuantity;

        if (totalQuantity > availableCount) {
          isEnoughStock = false;
        }
      });
    
      if (isEnoughStock) {

        productsInOrder.forEach(product => {
          product.multiplier = multiplier;
        });
    
        state.totalPrice = state.products.reduce((acc, item) => acc + item.price * item.quantity * item.multiplier, 0);
      } else {
        console.log("Не хватает количества продуктов для установки множителя");
      }
    },
    renameOrder: (state, action) => {
      const { order, newOrderName } = action.payload;

      state.products.forEach(product => {
        if (String(product.order) === String(order)) {
          product.orderName = newOrderName;
        }
      });
    },
    clearCart: (state) => {
      state.products = [];
      state.ordersCount = 0;
      state.totalPrice = 0;
    },
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  removeOrder,
  lockedOrder,
  setMultiplier,
  renameOrder,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;