import {MenuItemProfile} from "./MenuItemProfile/MenuItemProfile";
import SignOut from "../../../../images/Profile/Sign_out_squre_light.png";
import React from "react";
import styled from "styled-components";
import BagBlack from "../../../../images/Profile/BagBlack.png";
import BagLight from "../../../../images/Profile/BagLight.png";
import WalletBlack from "../../../../images/Profile/WalletBlack.png";
import WalletLight from "../../../../images/Profile/WalletLight.png";
import CartBlack from "../../../../images/Profile/CartBlack.png";
import CartLight from "../../../../images/Profile/CartLight.png";
import GeoBlack from "../../../../images/Profile/GeoBlack.png";
import GeoLight from "../../../../images/Profile/GeoLight.png";
import PromoBlack from "../../../../images/Profile/PromoBlack.png";
import PromoLight from "../../../../images/Profile/PromoLight.png";
import StarBlack from "../../../../images/Profile/StarBlack.png";
import StarLight from "../../../../images/Profile/StarLight.png";
import SettingBlack from "../../../../images/Profile/SettingBlack.png";
import SettingLight from "../../../../images/Profile/SettingLight.png";

export const MyMenu = ({
    clickHandle,
    LogOut,
    isClick
                       }) => {
    const menu = [{id:1, title: 'Мои заказы', pathImg: BagBlack, pathImgAlt: BagLight}, {id:2, title: 'Мои финансы', pathImg: WalletBlack, pathImgAlt: WalletLight}, {id:3, title: 'Мои карты', pathImg: CartBlack, pathImgAlt: CartLight}, {id:4, title: 'Мои адреса', pathImg: GeoBlack, pathImgAlt: GeoLight},
        {id:5 , title: 'Личный промокод', pathImg: PromoBlack, pathImgAlt: PromoLight}, {id:6, title: 'Вкусовые предпочтения', pathImg: StarBlack, pathImgAlt: StarLight},{id:7, title: 'Настройки', pathImg: SettingBlack, pathImgAlt: SettingLight} ]
    return (<>
        <MenuContainerProfile>
            {
                menu.map((element, index) => <MenuItemProfile key = {index} isFirst={index === 0} pathImg={element.pathImg}  pathImgAlt = {element.pathImgAlt} title = {element.title} isClick = {isClick === element.id} onClick = {() => clickHandle(element.id)} />)
            }
            <LogOutButton
                onClick = {LogOut}
            ><img alt = '' src = {SignOut}/> <p>Выйти из аккаунта</p></LogOutButton>
        </MenuContainerProfile>

        </>)
}


const MenuContainerProfile = styled.div `
  max-width: 383px;
  width: 100%;
  
`

const LogOutButton = styled.div `
  display: flex;
  border-radius: 16px;
  background-color: #EEEEEE;
  padding: 20px;
  max-width: 263px;
  margin-top: 16px;
  cursor: pointer;
  
  p {
    margin: 0 0 0 10px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.44200000166893005px;
  }
  
`
