import React from "react";
import styled from "styled-components";

export const MyPoints = ({title,subtitle,points}) => {
    return (<>
        <InnerContainer>
            <HeaderContainer>
               <Header>{title}</Header>
                <SubTitle>{subtitle}</SubTitle>
            </HeaderContainer>
               <PointTitle>
                   {points}
               </PointTitle>
        </InnerContainer>

        </>)
}




const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`

const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`

const PointTitle = styled.p`
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;
`
