import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Success from '../../../images/Auth/SuccessLogin.png';
import { setIsAuthModalClose } from "../../../redux/reducer/slices/profileSlice";

const SuccessLogin = () => {
    const [visible, setVisible] = useState(true);
    const isAuth = useSelector((state) => state.profile.isAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let timeOutId = null;
        if (isAuth) {
            timeOutId = setTimeout(() => {
                dispatch(setIsAuthModalClose());
                navigate('/personal');
                setVisible(false);
            }, 2000);
        }
        return () => {
            clearTimeout(timeOutId);
        };
    }, [isAuth, navigate]);

    return visible ? (
        <SuccessContainer>
            <img src={Success} alt="Success" />
        </SuccessContainer>
    ) : null;
};

const SuccessContainer = styled.div`
  position: fixed;
  z-index: 20000;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
`;

export default SuccessLogin;



