import { useEffect, useState } from "react";
import moment from "moment";

export const useTimer = (shutOffKitchenMoment, serverTimeMoment) => {
    const [canShow, setShow] = useState(false);
    const [time, setTime] = useState([]);
    const [timeEnd, setTimeEnd] = useState(false);
    const [showSeconds, setShowSeconds] = useState(false);

    useEffect(() => {
        const difference = moment.duration(shutOffKitchenMoment.diff(serverTimeMoment));
        let intervalId = null;
        let arr = [
            ['seconds', difference.get('seconds')],
            ['minutes', difference.get('minutes')],
            ['hours', difference.get('hours')]
        ];

        if (difference.asSeconds() > 0) {
            intervalId = setInterval(() => {
                arr = arr.map(([key, value], index) => {
                    if (key === 'seconds' && (value - 1 >= 0)) {
                        return [key, --value];
                    }
                    if (key === 'minutes' && value === 0) {
                        setShowSeconds(true);
                    }
                    if (key === 'seconds') {
                        if (arr[index + 1][1] > 0) {
                            arr[index + 1][1] = --arr[index + 1][1];
                            return [key, 60];
                        }
                        if (arr[index + 2][1] > 0) {
                            arr[index + 2][1] = --arr[index + 2][1];
                            arr[index + 1][1] = 59;
                            return [key, 60];
                        }
                        clearInterval(intervalId);
                        return [key, 0];
                    }
                    return [key, value];
                });
                setTime(arr);
                if (arr.every(([key, element]) => element === 0)) {
                    clearInterval(intervalId);
                    setTimeEnd(true);
                    return;
                }
                if (!canShow && !arr.every(([key, element]) => element === 0)) {
                    setShow(true);
                }
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [shutOffKitchenMoment, serverTimeMoment, canShow]);

    return { time, canShow, timeEnd, showSeconds };
}