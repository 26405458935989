import React from "react";
import styled from "styled-components";
import InJob from '../imgs/InJob.png';
import Complete from '../imgs/Complete.png';
import InCurier from '../imgs/InCurier.png';
export const SwitchStatus = ({status, statusName}) => {
    const switcher = (status) => {
        switch (status) {
            case 10:
                return  <StatusContainer complete = {false}>
                    <p>{statusName}</p> <img alt='' src = {InJob}/>
                </StatusContainer>
            case 20:
                return <StatusContainer complete = {false}>
                    <p>{statusName}</p> <img alt='' src = {InJob}/>
                </StatusContainer>
            case 30:
                return <StatusContainer complete = {false}>
                    <p>{statusName}</p> <img alt='' src = {InJob}/>
                </StatusContainer>
            case 40:
                return <StatusContainer complete = {false}>
                    <p>{statusName}</p> <img alt='' src = {InCurier}/>
                </StatusContainer>
            case 50:
                return <StatusContainer complete = {true}>
                    <p>{statusName}</p> <img alt='' src = {Complete}/>
                </StatusContainer>
            case 100:
                return <StatusContainer complete = {false}>
                    <p>{statusName}</p> <img alt='' src = {InJob}/>
                </StatusContainer>
            default:
                return null
        }
    }
    return(<>
        {
            switcher(status)
        }

        </>)
}

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(complete) => complete ? '#558F4F' : '#2C2E32' };
  
  img {
    margin-left: 10px;
  }
  
  p {
    margin: 0;
    border: none !important;
  }
  

`
