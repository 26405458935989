import React, {Component, Fragment} from "react";
import styled from "styled-components";
import smart1 from "../../../images/woman.png";
import smart2 from "../../../images/man.png";
import smart3 from "../../../images/robot.png";
import {connect} from "react-redux";
import CountUp from "react-countup";
import Fade from "react-reveal";
import {NAME_COMPANY} from "../../../utils/constants";

class Info extends Component {
    componentDidMount(){
        this.props.getCountStatistics();
    }

	render() {

	    const { statistic } = this.props;

	    let cityCount = parseInt(statistic.citiesCount || 0),
            restCount = parseInt(statistic.restaurantsCount || 0),
            prodCountToday = parseInt(statistic.today ? statistic.today.dayProductsCount : 0),
            prodCountTomorrow = parseInt(statistic.tomorrow ? statistic.tomorrow.dayProductsCount : 0),
            orderCount = parseInt(statistic.totalOrdersCount || 0);

	    let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
	    let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

        return (
            <Fade>
                {is51obed ? (
                    <InfoBlockMain>
                        <InfoBlockInfo>
                            <InfoBlockMainText style={{flexBasis: '100%'}}>
                                <InfoBlockMainTitleMain>Гастрономический холдинг «Дружба»</InfoBlockMainTitleMain>
                                <Text1>
                                    Гастрономический холдинг «Дружба» - это 30-ти летний опыт в сфере общественного питания, широкий ассортимент блюд, высокие стандарты качества и гарантия пищевой безопасности.
                                </Text1>
                                <br/>
                                <Text2 style={{backgroundColor: 'rgba(239, 83, 80, 0.1)', fontSize: 18, lineHeight: '29px', color: 'rgb(82, 82, 88)'}}>
                                    <ul>
                                        <li>11 заведений сети «Дом 51» в формате Free flow в Самарской области</li>
                                        <li>Выездной ресторан «Шеф кейтеринг» - уже 15 лет является ведущим региональным оператором кейтеринговых услуг</li>
                                        <li>Собственное современное заготовочное производство и кондитерский цех мощностью 2 тонны/сутки</li>
                                        <li>Свой автомобильный парк и отлаженная система логистики</li>
                                        <li>Эффективный производственный цикл, рассчитанный на объем от 2 500 гостей ежедневно</li>
                                    </ul>
                                </Text2>
                                <br/>
                                <Text1>
                                    Юридическое наименование: ООО «Шайба»
                                </Text1>
                                <br/>
                                <Text2 style={{backgroundColor: 'rgba(239, 83, 80, 0.1)', fontSize: 18, lineHeight: '29px', color: 'rgb(82, 82, 88)'}}>
                                    <p style={{fontSize: 24}}>Адреса:</p>
                                    <ul>
                                        <li>ул. Красноармейская, 93 (Самара, корпоративная столовая)</li>
                                        <li>ул. Красноармейская, 1б (Самара) пн-пт 9.00-16.30</li>
                                        <li>ул. Галактионовская, 141 (Самара) пн-пт 9.30-17.30</li>
                                        <li>ул. Миронова, 7/13 (Новокуйбышевск) пн-пт 9.00-16.30</li>
                                        <li>Ленинский, пр-т, 1б (Тольятти) пн-пт 9.00-16.30</li>
                                        <li>ул. Свердлова, 51 (Тольятти) пн-пт 9.00-16.30</li>
                                        <li>ул. Спортивная, 18в (Тольятти) пн-пт 9.00-16.30</li>
                                        <li>Южное шоссе, 24а (Тольятти) пн-пт 9.00-16.30</li>
                                        <li>ул. Транспортная, 27а (Тольятти, корпоративная столовая)</li>
                                        <li>Ул. Юбилейная, 55 (Тольятти, корпоративная столовая)</li>
                                    </ul>
                                </Text2>
                            </InfoBlockMainText>
                        </InfoBlockInfo>
                    </InfoBlockMain>
                ) : (isStraus ? (
                    <InfoBlockMain>
                        <InfoBlockInfo>
                            <InfoBlockMainText style={{flexBasis: '100%'}}>
                                <InfoBlockMainTitleMain>Столовая-пекарня «Страус» нового современного формата</InfoBlockMainTitleMain>
                                <Text1>
                                    Готовим только из качественных и свежих продуктов, большой выбор блюд из мяса, рыбы, птицы, вегетарианские блюда.
                                </Text1>
                                <Text1>
                                    Идем в ногу со временем, следим за новинками и готовим их для вас.
                                </Text1>
                                <br/>
                                <Text2 style={{backgroundColor: 'rgba(239, 83, 80, 0.1)', fontSize: 18, lineHeight: '29px', color: 'rgb(82, 82, 88)'}}>
                                    <p style={{fontSize: 24}}>Наш адрес:</p>
                                    <ul>
                                        <li>ул. Демократическая, 53а</li>
                                    </ul>
                                    <p style={{fontSize: 24}}>Наш инстаграм:</p>
                                    <ul>
                                        <li>@stolovka_straus</li>
                                    </ul>
                                </Text2>
                            </InfoBlockMainText>
                        </InfoBlockInfo>
                    </InfoBlockMain>
                ) : (
                    <Fragment>
                        <InfoBlockMain>
                            <InfoBlockInfo>
                                <InfoBlockMainText>
                                    <InfoBlockMainTitleMain>{NAME_COMPANY}</InfoBlockMainTitleMain>
                                    <Text1>
                                        {`${NAME_COMPANY} - это сервис доставки еды на каждый день. Это проект, в котором мы объединяем лучшие столовые в городе и ежедневно предоставляем для вас их меню. Большой выбор продукции поможет вам пробовать новые и новые блюда, составлять собственное меню на обед. Мы сократили расстояние до столовых в несколько кликов! Не нужно тратить время на дорогу и очереди`}
                                    </Text1>
                                    <br/>
                                    <Text2>
                                        Сделать один заказ из разных столовых <br/> и получить его в обед - это возможно!
                                    </Text2>
                                    <br/><br/>
                                    <Text1>
                                        Как это работает? Сейчас мы расскажем!
                                    </Text1>
                                </InfoBlockMainText>
                                <InfoBlockMainImage>
                                    <Fade bottom>
                                        <MainImage src={smart1}/>
                                    </Fade>
                                </InfoBlockMainImage>
                            </InfoBlockInfo>
                        </InfoBlockMain>
                        <InfoBlockMain>
                            <InfoBlockInfo>
                                <InfoBlockMainTextFluid>
                                    <InfoBlockMainTitle>Предзаказы - это удобно☝</InfoBlockMainTitle>
                                    <Text1>
                                        Мы сделали заказ обедов лёгким и быстрым с помощью системы предзаказов. Наше меню доступно на неделю вперёд, что позволяет вам планировать свой обед заранее. Вместе с обедом мы привезём всё необходимое, салфетки и комплект приборов
                                    </Text1>
                                </InfoBlockMainTextFluid>
                            </InfoBlockInfo>
                        </InfoBlockMain>
                        <InfoBlockMain>
                            <InfoBlockInfo>
                                <InfoBlockMainText>
                                    <InfoBlockMainTitle>Совместный заказ</InfoBlockMainTitle>
                                    <Text1>
                                        Разделите стоимость доставки. Вы можете добавить своих коллег или друзей в свою компанию, и заказывать обед вместе! Вы всегда видете свой и общий заказ компании. Если вы используете наличную оплату, это облегчает процесс рассчёта с курьером
                                    </Text1>
                                </InfoBlockMainText>
                                <InfoBlockMainImage>
                                    <Fade bottom>
                                        <MainImage src={smart2}/>
                                    </Fade>
                                </InfoBlockMainImage>
                            </InfoBlockInfo>
                        </InfoBlockMain>
                        <InfoBlockMain>
                            <InfoBlockInfo>
                                <InfoBlockMainTextEnd>
                                    <InfoBlockMainTitle>Информационная система “{NAME_COMPANY}”</InfoBlockMainTitle>
                                    <Text1>
                                        Для того чтобы успевать за всеми процессами мы разработали специальные инструменты и объединили их в одно название - информационная система “{NAME_COMPANY}” <br/>
                                        Она принимает заказы и платежи, рассчитывает стоимость обеда и стоимость доставки, формирует личный и общий чек, строит быстрые маршруты для курьеров, анализирует продажи и напоминает вам о том, что ваш заказ принят или доставлен ;)
                                    </Text1>
                                </InfoBlockMainTextEnd>
                                <InfoBlockMainImageLast>
                                    <Fade bottom>
                                        <MainImageLast src={smart3}/>
                                    </Fade>
                                </InfoBlockMainImageLast>
                            </InfoBlockInfo>
                        </InfoBlockMain>
                        <InfoBlockMain>
                            <InfoBlockInfo>
                                <InfoBlockMainTextFluid>
                                    <InfoBlockMainTitle>Цифры</InfoBlockMainTitle>
                                    <Statistics>
                                        <CountUp end={cityCount} duration={0.75} redraw={true}>
                                            {({ countUpRef }) => (
                                                <BlockCount>
                                                    <TextTitle>Работаем в городах</TextTitle>
                                                    <Text_3 >
                                                        <TextCount ref={countUpRef}/>
                                                    </Text_3>
                                                </BlockCount>
                                            )}
                                        </CountUp>
                                        <CountUp end={restCount} duration={0.75} redraw={true}>
                                            {({ countUpRef }) => (
                                                <BlockCount>
                                                    <TextTitle>Столовых с нами</TextTitle>
                                                    <Text_3 >
                                                        <TextCount ref={countUpRef}/>
                                                    </Text_3>
                                                </BlockCount>
                                            )}
                                        </CountUp>
                                        <CountUp
                                            end={prodCountToday + prodCountTomorrow}
                                            duration={0.75}
                                            redraw={true}
                                            separator=" "
                                        >
                                            {({ countUpRef }) => (
                                                <BlockCount>
                                                    <TextTitle>Блюд и напитков</TextTitle>
                                                    <Text_3 >
                                                        <TextCount ref={countUpRef}/>
                                                    </Text_3>
                                                </BlockCount>
                                            )}
                                        </CountUp>
                                        <CountUp
                                            end={orderCount}
                                            duration={0.75}
                                            redraw={true}
                                            separator=" "
                                        >
                                            {({ countUpRef }) => (
                                                <BlockCount>
                                                    <TextTitle>Всего заказов</TextTitle>
                                                    <Text_3 >
                                                        <TextCount ref={countUpRef}/>
                                                    </Text_3>
                                                </BlockCount>
                                            )}
                                        </CountUp>
                                    </Statistics>
                                    <ButtonResetStatistics onClick={()=>{this.props.getCountStatistics()}}>Обновить циферки</ButtonResetStatistics>
                                </InfoBlockMainTextFluid>
                            </InfoBlockInfo>
                        </InfoBlockMain>
                    </Fragment>
                ))}
            </Fade>
		);
	}
}

const mapStateToProps = store => ({
    statistic: store.main.statistic,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Info);

const InfoBlockMain = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 100px;
	
	&:nth-child(4){
	    margin-bottom: 70px;
	}
`;
const InfoBlockMainTitle = styled.h1`
	font-family: "Lato", sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 38px;
	color: #333333;
	margin-bottom: 24px;
`;
const InfoBlockMainTitleMain = styled(InfoBlockMainTitle)`
	font-size: 38px;
	line-height: 46px;
`;
const InfoBlockInfo = styled.div`
	display: flex;
	
	@media(max-width: 992px){
		flex-direction: column;
	}
`;
const InfoBlockMainText = styled.div`
	flex-basis: 65%;
	padding-right: 100px;
	display: flex;
    flex-direction: column;
    justify-content: center;
    
	@media(max-width: 992px){
		flex-basis: 100%;
		padding-right: 0;
	}
`;
const InfoBlockMainTextEnd = styled(InfoBlockMainText)`
	justify-content: end;
`;
const InfoBlockMainTextFluid = styled.div`
	flex-basis: 1000%;

`;
const Statistics = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media(max-width: 768px){
		flex-direction: column;
	}
`;
const BlockCount = styled.div`
    display: flex;
    flex-direction: column;
    align-center: center;
`;
const Text1 = styled.p`
	font-family: "Lato", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #333333;
`;
const Text2 = styled(Text1)`
	background: rgba(29, 217, 96, 0.1);
	border-radius: 12px;
    align-self: flex-start;
	padding: 20px 36px;
	font-family: "Lato", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	color: #E0D5D2;
`;
const Text_3 = styled(Text2)`
    display: flex;
    align-self: auto;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    padding: 40px 30px;
    font-size: 52px;
    line-height: 62px;
`;
const TextTitle = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 15px;
    
    @media(max-width: 768px){
		text-align: center;
	}
`;
const TextCount = styled.span``;
const InfoBlockMainImage = styled.div`
	flex-basis: 35%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media(max-width: 992px){
		flex-basis: 100%;
	}
`;
const ButtonResetStatistics = styled.div`
    width: 260px;
    height: 50px;
    background: #67B8EE;
    border-radius: 66px;
    color: #fff;
    display: flex;
    align-items: center;
	justify-content: center;
	margin: 20px auto -75px;
	cursor: pointer;
`;
const MainImage = styled.img`
	width: 100%;
`;
const InfoBlockMainImageLast = styled(InfoBlockMainImage)`
	
`;
const MainImageLast = styled(MainImage)`
	width: 70%;
	margin-top: 75px;
`;
