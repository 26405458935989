import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { persistedReducer } from './redux-persist-config'; 

const store = configureStore({
  reducer: persistedReducer,
});

let persistor;

try {
  persistor = persistStore(store);
} catch (error) {
  console.error('Ошибка при инициализации persistStore:', error);
}

export { store, persistor };
