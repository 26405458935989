import React from "react";
import styled from "styled-components";
import EditAdress from '../../../../../images/Profile/EditAdress.png'
import {Close} from "../../share/Close/Close";
export const MyAdress = ({ typeAdress, adress }) => {
    return (<>
        <InnerContainer>
            <Close/>
            <HeaderContainer>
                <Header>{typeAdress}</Header>
            <EditContainer>
                <Title>{adress}</Title>
                <Image src = {EditAdress} />
            </EditContainer>
            </HeaderContainer>
            <FooterContainer>
                <FooterTitle isGreen = {true}>+Пригласить друга</FooterTitle>
                <FooterTitle isGreen = {false}>Отчет о заказах</FooterTitle>
            </FooterContainer>
        </InnerContainer>

    </>)
}
const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  height: 100%;
`

const Image = styled.img`
  margin-left: 25px;
`

const Header = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const Title = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

`

const EditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`
const FooterTitle = styled.p`
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  max-width: ${({isGreen}) => isGreen ? '' : '119px'}; ;
  letter-spacing: -0.44200000166893005px;
  text-align: left;
  color: ${({isGreen}) => isGreen ? '#408628' : '#111111'};
  border-bottom:  ${({isGreen}) => isGreen ? '1px solid #408628' : '1px solid #111111'};;

`

