import React from 'react'
import create from '../../../../../images/Profile/Create.png'
import styled from "styled-components";


export const CreatePlane = () => {
    return (<>
        <InnerContainer>
           <Image src = {create}/>
            <FooterContainer>
                <Header>Создать счёт</Header>
                <Title>Доступ близким или сотрудникам
                    Ограничение трат на день, неделю или месяц</Title>
            </FooterContainer>
        </InnerContainer>

    </>)
}




const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  height: 100%;
  cursor: pointer;
`

const Header = styled.h1`
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.44200000166893005px;
  margin: 0;

`
const Title = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.44200000166893005px;
  text-align: center;
  margin: 0;


`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Image = styled.img`
    max-width: 70px;
    max-height: 70px;
`
