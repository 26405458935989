import React from "react";
import styled from "styled-components";
import { Container } from "../core/styles";
import Button from "./Button";

const Footer = () => {
  const Links = [
    {
      title: 'Сытый Офис',
      links: [
        { name: 'О нас', url: '#' },
        { name: 'Меню', url: '#' },
        { name: 'Оплата', url: '#' },
        { name: 'Доставка', url: '#' },
        { name: 'Для руководителей', url: '#' },
      ]
    },
    {
      title: 'Соц. сети',
      links: [
        { name: 'Instagram', url: '#' },
        { name: 'Вконтакте', url: '#' },
        { name: 'Facebook', url: '#' },
        { name: 'Telegram', url: '#' },
      ]
    },
    {
      title: 'Цифры',
      links: [
        { name: 'Всего заказов', value: '18 851' },
        { name: 'Блюд и напитков', value: '1 186' },
        { name: 'Столовых', value: '9' },
        { name: 'Города', value: '2' },
      ]
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Flex>
          <LinksWrapper>
            {Links.map((column, index) => (
              <LinksCol key={index}>
                <h3>{column.title}</h3>
                <ul>
                  {column.links.map((link, idx) => (
                    <li key={idx}>
                      {link.url ? (
                        <a href={link.url}>{link.name}</a>
                      ) : (
                        <span>{link.name}: {link.value}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </LinksCol>
            ))}
          </LinksWrapper>

          <Info>
            <ContactInfo>
              <h4>Качество работы</h4>
              <Button>Оставить отзыв</Button>
              <Phone>8 800 200-69-24</Phone>
              <p>звонок бесплатный</p>
              <PaymentIcons>
                <img src="mir.svg" alt="МИР" />
                <img src="visa.svg" alt="Visa" />
                <img src="mastercard.svg" alt="MasterCard" />
              </PaymentIcons>
              <AppStores>
                <img src="google-play-badge.svg" alt="Google Play" />
                <img src="app-store-badge.svg" alt="App Store" />
              </AppStores>
            </ContactInfo>
          </Info>
        </Flex>
        <FooterNote>
          © Сытый Офис - Сервис доставки еды 2018-2020
        </FooterNote>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  padding: 65px 0;
  background: #F9FAFC;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
`;

const LinksCol = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;
    }

    a, span {
      font-size: 14px;
      color: #333;
      text-decoration: none;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #888;
  }
`;

const Phone = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const PaymentIcons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;

  img {
    height: 24px;
  }
`;

const AppStores = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  img {
    height: 36px;
  }
`;

const FooterNote = styled.div`
  margin-top: 32px;
  text-align: center;
  font-size: 14px;
  color: #888;
`;
