import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../../utils/axiosRequest';

const initialState = {
    addressSuggest: [],
    addressMark: {},
    addressesOwn: [],
    address: null,
    points: [],
    mapPoints: [],
    isLoading: false,
    mapCity: null,
    isLoadingAddressesSuggest: false,
    isLoadingAddressOwn: false,
    errors: null,
    isAddressModalOpen: false,
};

export const getAddressSearch = createAsyncThunk(
    'map/getAddressSearch',
    async ({ searchAddress, ll }, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.get('https://geocode-maps.yandex.ru/1.x/', {
                params: {
                    geocode: searchAddress,
                    format: 'json',
                    rspn: 1,
                    ll: localStorage.getItem('mapPoints').split(' ').join(', '),
                    spn: '5.0001,5.0001',
                    kind: 'house',
                    apikey: '77d090bf-14e3-4ca8-b58a-aac1f3c1eaa7',
                }
            });
            return response.data.response.GeoObjectCollection.featureMember;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const setAddressFromMark = createAsyncThunk(
    'map/setAddressFromMark',
    async (addressMark, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.get('https://geocode-maps.yandex.ru/1.x/', {
                params: {
                    geocode: [addressMark[1], addressMark[0]].join(','),
                    format: 'json',
                    apikey: '77d090bf-14e3-4ca8-b58a-aac1f3c1eaa7',
                }
            });
            return response.data.response.GeoObjectCollection.featureMember[0].GeoObject;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const setSuggestAddresses = createAsyncThunk(
    'map/setSuggestAddresses',
    async (searchRequest, { rejectWithValue }) => {
        const api = new axiosRequest().instance;
        try {
            const response = await api.get('https://suggest-maps.yandex.ru/v1/suggest', {
                params: {
                    text: searchRequest[0],
                    ll: localStorage.getItem('mapPoints').split(' ').join(', '),
                    spn: '0.001,0.001',
                    types: 'house',
                    apikey: 'a86de378-1edd-4f73-84f8-21ad2a1b470b',
                    strict_bounds: 1,
                    attrs: 'uri'
                }
            });
            return response.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const getOwnAddresses = createAsyncThunk(
    'map/getOwnAddresses',
    async (_, { getState, rejectWithValue }) => {
        const api = new axiosRequest().instance;
        const cityId = getState().settings.currentCity.id;
        try {
            const response = await api.post('addresses/get', {
                data: {
                    "token": localStorage.getItem('token'),
                    "city": { "id": cityId },
                    "clientInfo": {
                        "os": "android",
                        "osVersion": "33",
                        "model": "M2101K6G",
                        "brand": "Redmi",
                        "screenWidth": 1080,
                        "deviceId": "69516350155354a2",
                        "appVersion": "1.29.13",
                        "appId": "com.menu4me.dinner.sitiyoffice",
                        "locale": "ru_RU"
                    }
                }
            });
            return response.data.data.addresses;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

export const saveAddress = createAsyncThunk(
    'map/saveAddress',
    async (data, { getState, rejectWithValue }) => {
        const api = new axiosRequest().instance;
        const state = getState();
        try {
            const response = await api.post('addresses/create', {
                data: {
                    "token": localStorage.getItem('token'),
                    "city": { "id": state.map.mapCity.id },
                    "address": {
                        "latitude": state.map.mapPoints[0],
                        "longitude": state.map.mapPoints[1],
                        "city": { "id": state.map.mapCity.id },
                        "street": data.nameAddress[0],
                        "house": data.nameAddress[1],
                        "flat": data.flat,
                        "floor": data.floor,
                        "entrance": data.entrance,
                        "name": data.name,
                        "comment": data.comment,
                        "addAddressAnyway": true
                    },
                    "clientInfo": {
                        "os": "android",
                        "osVersion": "33",
                        "model": "M2101K6G",
                        "brand": "Redmi",
                        "screenWidth": 1080,
                        "deviceId": "69516350155354a2",
                        "appVersion": "1.29.13",
                        "appId": "com.menu4me.dinner.sitiyoffice",
                        "locale": "ru_RU"
                    }
                }
            });
            if (response.data.result) {
              return (getOwnAddresses());
            }
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    }
);

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMapCity: (state, action) => {
            state.mapCity = action.payload;
        },
        setMapPointsFromClicker: (state, action) => {
            state.mapPoints = action.payload.split(' ').reverse();
        },
        resetAddress: (state) => {
            state.points = [];
        },
        resetAddressSuggest: (state) => {
            state.addressSuggest = [];
        },
        resetAddressMark: (state) => {
            state.addressMark = {};
        },
        setIsAddressModalOpen: (state) => {
            state.isAddressModalOpen = true;
        },
        setIsAddressModalClose: (state) => {
            state.isAddressModalOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddressSearch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAddressSearch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.points = action.payload || [];
            })
            .addCase(getAddressSearch.rejected, (state) => {
                state.isLoading = false;
                state.errors = true;
            })
            .addCase(setAddressFromMark.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(setAddressFromMark.fulfilled, (state, action) => {
                state.addressMark = action.payload;
                state.isLoading = false;
            })
            .addCase(setAddressFromMark.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(setSuggestAddresses.pending, (state) => {
                state.isLoadingAddressesSuggest = true;
            })
            .addCase(setSuggestAddresses.fulfilled, (state, action) => {
                state.addressSuggest = action.payload;
                state.isLoadingAddressesSuggest = false;
            })
            .addCase(setSuggestAddresses.rejected, (state) => {
                state.isLoadingAddressesSuggest = false;
            })
            .addCase(getOwnAddresses.pending, (state) => {
                state.isLoadingAddressOwn = true;
            })
            .addCase(getOwnAddresses.fulfilled, (state, action) => {
                state.addressesOwn = action.payload;
                state.isLoadingAddressOwn = false;
            })
            .addCase(getOwnAddresses.rejected, (state) => {
                state.isLoadingAddressOwn = false;
            });
    }
});

export const {
  setMapCity,
  setMapPointsFromClicker,
  resetAddress,
  resetAddressSuggest,
  resetAddressMark,
  setIsAddressModalOpen,
  setIsAddressModalClose,
} = mapSlice.actions;

export default mapSlice.reducer;
