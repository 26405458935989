import React, { useState } from 'react';
import styled from 'styled-components';
import Star from '../images/rating_star.svg';
import star from '../images/menu/Star.png';
import like from '../images/menu/Like.png';
import likeBlack from '../images/menu/LikeBlack.png';
import NoImg from '../images/menu/NoImg.png';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSubscribe } from "../redux/reducer/slices/kitchenSlice";

// kitchen: {
//   "isMenuPublished": true,
//   "kitchenId": 202865,
//   "kitchenName": "Кушать подано",
//   "kitchenRating": 4.6,
//   "deliveryDay": 1722988800,
//   "deliveryDayString": "2024-08-07",
//   "kitchenRacePolygonId": 31,
//   "timeStart": "11:30",
//   "timeEnd": "12:30",
//   "isSubscribed": false,
//   "messege": "Меню опубликовано",
//   "isLiked": false,
//   "deliveryTimeNote": "Доставка с 11:30 до 12:30",
//   "shutoffDeliveryDay": 1723008600,
//   "shutoffDeliveryDayString": "2024-08-07 05:30:00",
//   "img": "https://api-test.menuforme.online/storage/kitchens/202865/kitchenPlashka/2oayzykdy7knijohadkv.jpg",
//   "deliveryNotes": [
//       [
//           "Бесплатная доставка"
//       ],
//       [
//           "Заказ от 100"
//       ]
//   ]
// }

const KitchenCard = ({kitchen}) => {
  const { isAuth } = useSelector(state => state.profile);
  const { serverTime } = useSelector(state => state.kitchen);
  const navigate = useNavigate();
  const [subscribe, setSubscribeS] = useState(kitchen.isLiked || false)
  const DateDelivery = new moment (kitchen.deliveryDay*1000)
  const dispatch = useDispatch();

  const sendSubscribeInfoToServer = (e) => {
    e.stopPropagation();
    const url = subscribe ? 'removeLike' : 'setLike';
    setSubscribeS(prevState => !prevState);
    dispatch(setSubscribe({ url, kitchenId: kitchen.kitchenId }));
  };

  const handleClickCard = () => {
    navigate(`/menu?date=${DateDelivery.get('date')}.${DateDelivery.get('month')+1}.${DateDelivery.get('year')}&kitchen=${kitchen.kitchenId}&race=${kitchen.kitchenRacePolygonId}`)
  }

  const renderLabel = () => {
    if (!kitchen.isMenuPublished) {
      return <LabelWrapper disabled={true}><Label>Меню не опубликовано</Label></LabelWrapper>;
    }
  
    const shutOffTime = moment(kitchen.shutoffDeliveryDay * 1000);
    const remainingTime = moment.duration(shutOffTime.diff(moment(serverTime * 1000)));
  
    if (remainingTime.asMinutes() <= 0) {
      return <LabelWrapper disabled={true}><Label>Приём заказов завершён в {shutOffTime.format('HH:mm')}</Label></LabelWrapper>;
    } else if (remainingTime.asMinutes() > 30) {
      return <LabelWrapper><Label>Приём заказов до {shutOffTime.format('HH:mm')}</Label></LabelWrapper>;
    } else {
      return <LabelWrapper><Label>Приём заказов ещё {Math.ceil(remainingTime.asMinutes())} минут</Label></LabelWrapper>;
    }
  };

  return (
    <Wrapper onClick={handleClickCard}>
      <ImageBackground src={kitchen.img ? kitchen.img : NoImg}>
        <Labels>
          {renderLabel()}
        </Labels>
        <Promotion>
          <img src={star} alt="Promotion" />
          <p>Акция</p>
        </Promotion>
        {isAuth && (
          <SubscribeContainer subscribe={subscribe} onClick={sendSubscribeInfoToServer}>
            <img src={subscribe ? like : likeBlack} alt="Like button" />
          </SubscribeContainer>
        )}
      </ImageBackground>
      <RestTitle>
        <RestName>{kitchen.kitchenName}</RestName>
        {kitchen.kitchenRating > 0 && (
          <RatingWrapper>
            <img src={Star} width={18} height={19} alt="Rating star" />
            <RatingValue>{kitchen.kitchenRating.toFixed(1)}</RatingValue>
          </RatingWrapper>
        )}
      </RestTitle>
      {kitchen.deliveryNotes && kitchen.deliveryNotes.length > 0 && (
        <DeliveryNotes>
          {kitchen.deliveryNotes.map((note, index) => (
            note[0] && (
              <DeliveryNote key={index}>
                <DeliveryNoteText>{note[0]}</DeliveryNoteText>
              </DeliveryNote>
            )
          ))}
        </DeliveryNotes>
      )}
    </Wrapper>
  );
};

export default KitchenCard;

const Wrapper = styled.div`
  position: relative;
	width: 100%;
  cursor: pointer;
`;

const ImageBackground = styled.div`
  position: relative;
  width: 100%;
  height: 192px;
  background: ${({src}) => src ? 'url(' + src + ')' : ''} #E7E7E7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: ${({isDisabled}) => isDisabled ? 'rgba(255, 255, 255, 0.6)' : 'transparent'};
  }
`;

const Labels = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 12px;
  background: ${({disabled}) => disabled && '#FFFFFF99'};
`;

const Label = styled.div`
  padding: 8px 12px;
  width: fit-content;
  background: #FFFFFF;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
`;

const RestTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const RestName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2C2E32;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const RatingValue = styled.p`
  margin: 0 0 0 8px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2C2E32;
  user-select: none;
`;

const DeliveryNotes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
`;

const DeliveryNote = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin-left: 8px;
  margin-top: 12px;
  padding: 0 12px;
  background-color: #F1F1F1;
  border-radius: 18px;
`;

const DeliveryNoteText = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-size: 13px;
  line-height: 17px;
  color: #2C2E32;
  user-select: none;
`;

const Promotion = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  bottom: 16px;
  right: 12px;
  border-radius: 16px;
  background: linear-gradient(90deg, #FCC61E 0%, #FC7D58 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  p {
    margin: 0;
  }
  img {
    margin-right: 4px;
  }
;
`

const SubscribeContainer = styled.div `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 34px;
  height: 34px;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  background-color: ${({subscribe}) => subscribe ? '#FF1744' : '#FAFAFA'};
  border: ${({subscribe}) => subscribe ? '1px solid #F3F3F3' : 'none'};

`
