import React, {Component} from "react";
import styled from "styled-components";
// import Swiper from "swiper";
// import "swiper/css/swiper.css";
import gallery1 from "../../../images/gallery/gallery-1.jpg";
import gallery2 from "../../../images/gallery/gallery-2.jpg";
import gallery3 from "../../../images/gallery/gallery-3.jpg";
import gallery4 from "../../../images/gallery/gallery-4.jpg";
import gallery5 from "../../../images/gallery/gallery-5.jpg";
// import arrowLeft from "../../../images/play-button.svg";
// import arrowRight from "../../../images/play-button.svg";

class Advantages extends Component {
	// componentDidMount() {
	// 	this.navSlider = new Swiper(".gallery-container", {
	// 		loop: true,
	// 		spaceBetween: 40,
	// 		slidesPerView: 4,
	// 		autoplay: {
	// 			disableOnInteraction: false
	// 		},
	// 		breakpoints: {
	// 			992: {
	// 				slidesPerView: 3,
	// 				spaceBetween: 30
	// 			},
	// 			768: {
	// 				slidesPerView: 2,
	// 				spaceBetween: 20
	// 			},
	// 			576: {
	// 				slidesPerView: 1,
	// 				spaceBetween: 10
	// 			}
	// 		}
	// 	});
	// }

	// componentWillUnmount() {
	// 	this.navSlider.destroy();
	// }

	// render() {
	// 	return (
	// 		<Container>
	// 			<WrapperTitle>Фотогалерея</WrapperTitle>
	// 			<Wrapper>
	// 				<Slider className="gallery-container swiper-container">
	// 					<SliderWrapper className="swiper-wrapper">
	// 						<SliderSlide className="swiper-slide">
	// 							<GalleryItem>
	// 								<GalleryItemImg src={gallery1} />
	// 							</GalleryItem>
	// 						</SliderSlide>
	// 						<SliderSlide className="swiper-slide">
	// 							<GalleryItem>
	// 								<GalleryItemImg src={gallery2} />
	// 							</GalleryItem>
	// 						</SliderSlide>
	// 						<SliderSlide className="swiper-slide">
	// 							<GalleryItem>
	// 								<GalleryItemImg src={gallery3} />
	// 							</GalleryItem>
	// 						</SliderSlide>
	// 						<SliderSlide className="swiper-slide">
	// 							<GalleryItem>
	// 								<GalleryItemImg src={gallery4} />
	// 							</GalleryItem>
	// 						</SliderSlide>
	// 						<SliderSlide className="swiper-slide">
	// 							<GalleryItem>
	// 								<GalleryItemImg src={gallery5} />
	// 							</GalleryItem>
	// 						</SliderSlide>
	// 					</SliderWrapper>
	// 				</Slider>
	// 			</Wrapper>
	// 		</Container>
	// 	);
	// }
}

export default Advantages;

const Container = styled.div`
	padding: 45px 0;
	background-color: #fff;
`;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 0 15px;
	margin: 0 auto;
`;

const WrapperTitle = styled.h1`
	font-family: Lato;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 38px;
	color: #333333;
`;

const Slider = styled.div`
	display: flex;
`;

const SliderWrapper = styled.div``;

const SliderSlide = styled.div`
	padding: 5px 0;
`;

const GalleryItem = styled.div`
	padding: 5px;
	border-radius: 6px;
	background-color: rgb(255, 255, 255);
`;

//box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);

const GalleryItemImg = styled.img`
	width: 100%;
`;

// const SliderArrowLeft = styled.span`
// 	&& {
// 		background-image: url(${arrowLeft});
// 		background-size: 10px 10px;
// 		background-color: #fff;
// 		background-position: center center;
// 		height: 40px;
// 		width: 40px;
// 		border-radius: 50%;
// 		outline: none;
// 		left: 20px;
// 		box-shadow: 0 0 5px #aaa;
// 		transform: rotate(180deg);
// 	}
// `;
//
// const SliderArrowRight = styled.span`
// 	&& {
// 		background-image: url(${arrowRight});
// 		background-size: 10px 10px;
// 		background-color: #fff;
// 		background-position: center center;
// 		height: 40px;
// 		width: 40px;
// 		border-radius: 50%;
// 		outline: none;
// 		right: 20px;
// 		box-shadow: 0 0 5px #aaa;
// 	}
// `;
