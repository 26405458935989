import React, {useEffect} from 'react'
import styled from "styled-components";
// import Swiper from "swiper";
// import "swiper/css/swiper.css";
import arrow from '../../../images/Promotion/Arrow.png'
import shop from '../../../images/Promotion/Shop.png'
import backgroundImage from '../../../images/Promotion/Gift.png'
import {PromotionItem} from "./PromotionItem";
import {Loader} from "../../../utils/loader";
import * as url from "url";
export const Promotion = ({
    width,
    data,
    isLoading,
    resetPromotion
                          }) => {

    // useEffect(() => {
    //     const swiper = new Swiper((data.length >= 7 &&'.swiper'), {
    //         spaceBetween: 20,
    //         slidesPerView: 7,
    //         navigation: {
    //             nextEl: '.ButtonSilderNext',
    //         },

    //     });
    //    return () => {
    //        resetPromotion()
    //     }
    // }, []);



    return (<>
        {
            isLoading? <Loader/> : <MainContainer >
                <AlignContainer vw = {width}/>
                <Container className= { data.length >= 7 ? "swiper" : undefined}>
                    <Header vw = {width}>Акции</Header>
                    <SliderContainer className= { data.length >= 7 ? "swiper-wrapper" : undefined}>
                        {
                            data.map((element, index) =>
                                <div key = {index}>
                                    <PromotionItem
                                        img = {element.img}
                                        title = {element.text}
                                        header = {element.title}
                                        kitchen = {element.kitchen}
                                        backgroundImage = {element.pictures}
                                    />
                                </div>
                                )
                        }
                    </SliderContainer>
                    {data.length >= 7 &&  <ButtonSilderNext className="ButtonSilderNext">
                                           <img src = {arrow}/>
                                          </ButtonSilderNext>

                    }

                </Container>
            </MainContainer>
        }

        </>)
}


const MainContainer = styled.div`
   display: flex;
  margin-bottom: 83px;
 
`
const Header = styled.h1 `
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  margin-bottom: 39px;


`
const AlignContainer = styled.div `
  min-width: calc((${({vw}) => vw}px - 1280px) / 2);
  //height: 220px;
  margin-right: 45px;
`
const Container = styled.div`
   overflow: hidden;
   position: relative;
   //height: 240px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px; /* Ширина тени */
    box-shadow:0 0 50px rgba(255, 255, 255, 0.5);
  }
`

const SliderContainer = styled.div `

`

const ButtonSilderNext = styled.div `
    position: absolute;
    top: 45%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 1px solid #F6F4F4;
    z-index: 100;
    
  
`



