import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDevApiUrl, setProdApiUrl } from '../redux/reducer/slices/commonSlice';
import { setIsAuthModalClose } from '../redux/reducer/slices/profileSlice';
import { getSettings, setIsCityModalOpen, setIsCityModalClose } from '../redux/reducer/slices/settingsSlice';
import { setIsAddressModalClose } from '../redux/reducer/slices/mapSlice';
import { updateServerURL } from '../utils/axiosRequest';
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cookie from '../components/Cookie';
import { Loader } from "../utils/loader";
import Modal from '../components/Modal';
import AuthForm from '../components/AuthForm';
import SelectCityForm from '../components/SelectCityForm';
import AddressSelector from '../components/AddressSelector';

const Layout = ({ children }) => {
	const { apiUrl } = useSelector((state) => state.common);
  const { isAuthModalOpen } = useSelector((state) => state.profile);
	const { isAddressModalOpen } = useSelector((state) => state.map);
	const { isCityModalOpen, haveCity } = useSelector((state) => state.settings);
  const [globalLoad, setGlobalLoad] = useState(false);
  const dispatch = useDispatch();
	const urlParams = new URLSearchParams(window.location.search);

	console.log(isAddressModalOpen)

  useEffect(() => {
    setGlobalLoad(true);
    Promise.all([dispatch(getSettings())])
      .then(() => setGlobalLoad(false))
      .catch(() => setGlobalLoad(false));
  }, [dispatch]);

  useEffect(() => {
    updateServerURL(apiUrl);
  }, [apiUrl]);

	useEffect(() => {
    console.log(haveCity);
    if (!haveCity) {
      dispatch(setIsCityModalOpen());
    }
  }, []);

  return (
		<Wrapper>
			{!globalLoad ? (
				<>
					<Header/>
					<Content>
						{children}
					</Content>
					<Footer/>

					<Modal isOpen={isAuthModalOpen} onClose={() => {dispatch(setIsAuthModalClose())}}><AuthForm/></Modal>
					<Modal isOpen={isCityModalOpen} onClose={() => {dispatch(setIsCityModalClose())}}><SelectCityForm/></Modal>
					<Modal isOpen={isAddressModalOpen} onClose={() => {dispatch(setIsAddressModalClose())}}><AddressSelector/></Modal>

					<Cookie/>

					{/* Для разработки. Переключение серверов */}
					{urlParams.get('dev') === 'true' &&
						<div style={{ position: 'fixed', bottom: 20, left: 10, display: 'flex', flexDirection: 'column' }}>
							<button style={{ background: apiUrl === 'https://bottest2.menu4me.ru/api/v2/website/' ? 'red' : 'grey' }} onClick={() => dispatch(setDevApiUrl())}>Dev</button>
							<button style={{ background: apiUrl === 'https://api-test.menuforme.online/api/v2/website/' ? 'red' : 'grey' }} onClick={() => dispatch(setProdApiUrl())}>Prod</button>
						</div>
					}
					{/* /Для разработки. Переключение серверов */}
				</>
			) : (
				<Loader />
			)}
		</Wrapper>
  );
};

export default Layout;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Content = styled.div`
	padding-top: 125px;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	background: #F8F8F8;
`;
