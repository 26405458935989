import React from 'react'
import styled from "styled-components";
export const CreatePromo = () => {
return (<>
 <InnerContainer>
     <Header>Создать промокод</Header>
     <InputCustom
      placeholder = 'Придумайте название'
     />
     <ButtonCustom>Создать</ButtonCustom>
 </InnerContainer>

    </>)

}

const InnerContainer = styled.div`
  padding: 48px 44px;
  

`

const Header = styled.p`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  color: #37474F;
  
`

const InputCustom = styled.input`
  color: #E0E0E0;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #E0E0E0;
  width: 100%;
  
`

const ButtonCustom = styled.button `
    border-radius: 16px;
    color: #FFFFFF;
    background-color: #EA394B;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.44200000166893005px;
    text-align: center;
    width: 100%;
    border: none;
    padding: 20px;
    margin-top: 32px;

`
