import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  apiUrl: 'https://api-test.menuforme.online/api/v2/website/',
};

export const selectCommonState = state => state.common;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setDevApiUrl: (state) => {
      state.apiUrl = 'https://bottest2.menu4me.ru/api/v2/website/';
    },
    setProdApiUrl: (state) => {
        state.apiUrl = 'https://api-test.menuforme.online/api/v2/website/';
    },
  },
});

export const {
  setDevApiUrl,
  setProdApiUrl,
} = commonSlice.actions;

export default commonSlice.reducer;
