import React from 'react'
import styled from "styled-components";
import BackArrow from '../../../../images/Profile/BackArrow.png'
import { useState} from "react";
import {MyFinance} from "../MyFinance";
import {MyCart} from "../MyCart";
import {useNavigate, withRouter} from "react-router-dom";
import {MyAdress} from "../MyAdress";
import {MyPrefers} from "../MyPrefers";
import {MyPromoCode} from "../MyPromoCode";
import {MySettings} from "../MySettings";
import {MyOrders} from "../MyOrders";
import {MyMenu} from "../MyMenu";


import { useDispatch } from 'react-redux';
import { sendCodeToTelephone, logout } from '../../../../redux/reducer/slices/profileSlice';

const InnerProfile = ({
   history,
   deleteToken,
   getOrders,
   isLoading,
   data,
   hasMore,
   isLoadingProfile,
   phone,
   name,
   settings,
   serverTime,
   resetOrders,
   serverError,
   profileErrorServer,
   errorSaveProfile,
   saveProfile
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isClick, setIsClick] = useState(1);

    const clickHandle = (id) => {
        setIsClick(id)
    }
    const clickBack = () => {
      navigate(-1);
    }
    const LogOut = () => {
      dispatch(logout());
    }
    return (<>

   <MainContainer>
       <HeaderContainer>
           <BackButton onClick = {clickBack}>
               <BackImg src = {BackArrow}/>
           </BackButton>
           <Header>Профиль</Header>
       </HeaderContainer>
       <ProfileContainer>


          <MyMenu clickHandle = {clickHandle} LogOut = {LogOut} isClick={isClick} />
           {
               isClick === 1 ? <MyOrders resetOrders = {resetOrders}
                                         hasMore = {hasMore}
                                         serverTime = {serverTime}
                                         getOrders={getOrders}
                                         data={data}
                                         isLoading={isLoading}
                                         serverError = {serverError}
                   /> :
                   isClick === 2 ?
                   <MyFinance/> :
                       isClick === 3? <MyCart/>:
                           isClick ===4? <MyAdress/>:
                               isClick ===5? <MyPromoCode/>:
                                   isClick === 6? <MyPrefers/>:
                                       <MySettings settings = {settings}
                                                   name = {name}
                                                   phone = {phone}
                                                   isLoadingProfile = {isLoadingProfile}
                                                   profileErrorServer = {profileErrorServer}
                                                   errorSaveProfile = {errorSaveProfile}
                                                   saveProfile = {saveProfile}
                                       />



           }
       </ProfileContainer>
   </MainContainer>
    </>)
}
export default InnerProfile


const MainContainer = styled.div`
  padding-top: 170px;
  width: 100%;
  background-color: #F8F8F8;
`;


const ProfileContainer = styled.div `
  display: flex;
  justify-content: space-between;
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  padding: 54px 0;

`


const HeaderContainer = styled.div `
  max-width: 1270px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: start;

`

const Header = styled.div `
  font-family: "SF Pro Display";
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
`

const BackButton = styled.button `
  background-color: transparent;
  border: none;
  
`

const BackImg = styled.img`
  
`


