import React from 'react';
import styled from "styled-components";

const Button = ({ type='default', disabled=false, size, onClick, children }) => {
  return (
    <StyledButton
      type={type}
      size={size}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: ${({ type }) => (type === 'text' ? 0 : '0 30px')};
  min-width: ${({ type }) => (type === 'text' ? 'fit-content' : '150px')};
  height: ${({ size }) => (size === 'small' ? '50px' : '60px')};
  background: ${({ disabled, type }) => (disabled ? '#DCDCDC' : type === 'primary' ? '#EA394B' : type === 'text' ? 'none' : '#F3F3F3')};
  border-radius: ${({ type }) => (type === 'text' ? 0 : '20px')};
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: ${({ disabled, type }) => (disabled ? '#AAAAAA' : type === 'primary' ? '#FFFFFF' : '#2C2E32')};
  text-align: center;
  transition: all 0.2s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;

  &:hover {
    background: ${({ type, disabled }) => {
      if (disabled) return;
      return type === 'primary' ? '#EB273B' : type === 'text' ? 'none' : '#E4E3E3';
    }};
  }

  &:active {
    background: ${({ type, disabled }) => {
      if (disabled) return;
      return type === 'primary' ? '#E01B2F' : type === 'text' ? 'none' : '#D9D8D8';
    }};
  }
`;