import React, { useState } from 'react';
import styled from 'styled-components';
import KitchenCard from './KitchenCard';
import { useSelector } from 'react-redux';

const KitchenContainer = () => {
  const { kitchenData } = useSelector(state => state.kitchen);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Wrapper>
      <TabList>
        {kitchenData.map((delivery, index) => (
          <Tab
            key={index}
            isActive={index === activeTabIndex}
            onClick={() => setActiveTabIndex(index)}
          >
            Доставка с {new Date(delivery.timeStart * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            до {new Date(delivery.timeEnd * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Tab>
        ))}
      </TabList>

      {kitchenData.map((delivery, index) => (
        <TabPanel key={index} hidden={index !== activeTabIndex}>
          {delivery.kitchens.map((kitchen) => (
            <KitchenCard key={kitchen.kitchenId} kitchen={kitchen}/>
          ))}
        </TabPanel>
      ))}
    </Wrapper>
  );
};

export default KitchenContainer;

const Wrapper = styled.div`
  margin-top: 80px;
`;

const TabList = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 30px;
`;

const Tab = styled.button`
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => (props.isActive ? '#000000' : '#2C2E32')};
  transition: all .2s ease;
  cursor: pointer;

  &:hover {
    color: #EB273B;
  }
`;

const TabPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
`;