import React from "react"
import styled from "styled-components"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
import {
  getDeliverDay,
  getMenuByDay,
  setCurrentDay,
} from "../redux/reducer/slices/kitchenSlice";
import { setIsCityModalOpen } from "../redux/reducer/slices/settingsSlice";
import moment from "moment";
import { DaysSwitcher, MonthSwitcher } from "../core/utils";

const KitchenFilter = () => {
  const { currentDay, deliveryDays } = useSelector(state => state.kitchen);
  const { currentCity } = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const [select, setSelect] = useState(0)
  const DayMoment = deliveryDays.map(day => new moment( moment(day.day, 'DD-MM-YYYY')));

  console.log(currentCity);

  useEffect(() =>  {
    dispatch(getDeliverDay());
  }, [])

  useEffect(() =>  {
    dispatch(getMenuByDay());
  }, [currentDay])

  useEffect(() =>  {
    if (currentCity) {
      console.log('запрос меню');
      dispatch(getMenuByDay());
    }
  }, [currentCity])

  const handleClickCity = () => {
    dispatch(setIsCityModalOpen());
  }

  const handleClickDay = (id) => {
    setSelect(id);
    dispatch(setCurrentDay(DayMoment[id]));
  }

  return(
    <Wrapper>
      <Title>Еда на каждый день в <span style={{borderBottom: '3px solid black', cursor: 'pointer'}} onClick={handleClickCity}>{currentCity.name}</span></Title>
      <Filters>
        <DaysList>
          {DayMoment && DayMoment.length > 0 && DayMoment.map((day, index) => {
            return (
              <DayItem key={index} active={select === index} onClick={() => handleClickDay(index)}>
                <DayText active={select === index}>
                  {
                    moment().date() === day.date() ? 'Сегодня' :
                    moment().date() === day.date() - 1 || day.date() - 1 === 0 ? 'Завтра' :
                    <div>
                      {DaysSwitcher(day.day())}
                      {', ' + day.date() + ' '}
                      {MonthSwitcher(day.month())}
                    </div>
                  }
                </DayText>
              </DayItem>
            );
          })}
        </DaysList>
        <Categories>
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" stroke-width="1.80833" stroke-linecap="round" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 14L35 14"/>
            <path d="M7 28L24.5 28"/>
            <ellipse cx="12.25" cy="14" rx="5.25" ry="5.25" transform="rotate(90 12.25 14)"/>
            <ellipse cx="29.75" cy="28" rx="5.25" ry="5.25" transform="rotate(90 29.75 28)"/>
          </svg>
        </Categories>
      </Filters>
    </Wrapper>
  );
}

export default KitchenFilter;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin-bottom: 32px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  color: #2C2E32;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
`;

const DaysList = styled.div`
  display: flex;
`;

const DayItem = styled.div`
  display: flex;
  align-items: center;
  height: 55px;;
  padding: 0 18px;
  background: ${({active}) => active ? "linear-gradient(143.43deg, #8BCC74 -3.03%, #70BF54 78.71%)" : "#FFFFFF"};
  border: 1px solid #EDEDED;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(219, 219, 219, 0.25);
  cursor: pointer;
  
  &:not(:last-child) {
		margin-right: 24px;
	}
`;

const DayText = styled.div`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: ${({active}) => active ? "600" : "500"};
  font-size: 18px;
  line-height: 22px;
  color: ${({active}) => active ? '#FFFFFF' : '#2C2E32'};
  user-select: none;
`;

const Categories = styled.button`
  width: fit-content;
  
  svg {
    stroke: #333333;
    transition: all .2s ease;

    &:hover {
      stroke: #EB273B;
    }
  }
`;
