import React, { useEffect } from "react";
import {Die} from "../../../../common/die/Die";
import './DiePeriods.css'
import right from '../../../../images/DetailMenu/Right.png'
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const DiePeriods = ({
text,
index,
indexDate,
state,
getMenuByClick,
day,
kitchenId,
kitchenRacePolygonId,
history
                           }) => {
     const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    let currentDate = new moment (urlParams.get('date').replaceAll('.','-'), 'DD-MM-YYYY');
    let checkDate = new moment (day, 'DD-MM-YYYY');
    useEffect(() => {
         currentDate = new moment (urlParams.get('date').replaceAll('.','-'), 'DD-MM-YYYY');
         checkDate = new moment (day, 'DD-MM-YYYY');
    },[urlParams.get('date')])

    const toggleInnerState = () => {
        getMenuByClick(day)
        urlParams.set('date', day.replaceAll('-', '.'))
        navigate(`/menu?date=${day.replaceAll('-', '.')}&kitchen=${kitchenId}&race=${kitchenRacePolygonId}`)
    }
    return (<>
        <Die additionalClassString={['DetailMenu__DiePeriods']}
             additionalClassBoolean={{'Die_chosen': currentDate.isSame(checkDate) }}
             action={toggleInnerState}
             imgSrc={ index === state && right}
             postionImg={2}
             body={text}
        />


        </>)
}
