import  React from 'react'
import styled from "styled-components";
import ArrowMenu from '../../../../../images/Profile/ArrowMenu.png'
import ArrowMenuBlack from '../../../../../images/Profile/ArrowBlack.png'

export const MenuItemProfile = ({title, isClick, onClick, pathImg, pathImgAlt, isFirst}) => {
    return (<>
  <MenuItem onClick = {onClick} isClick = {isClick} isFirst = {isFirst}>
      <InnerMenuItem>
          {
              isClick ?  <img alt = '' src = {pathImgAlt}/> : <img alt = '' src = {pathImg} />
          }
          <p>{title}</p></InnerMenuItem>
      {
         isClick ?   <img alt ='' src = {ArrowMenu}/>  : <img alt = '' src = {ArrowMenuBlack}/>
      }

  </MenuItem>

    </>)
}

const MenuItem = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26.5px;
  max-width: 383px;
  margin-top: ${({isFirst}) => isFirst ? '0px' : '16px'};
  width: 100%;
  box-shadow: 0px 1px 5px 0px #0000000D;
  border-radius: 16px;
  background-color: ${({isClick}) => isClick ? '#EA394B' : '#FFFFFF'};
  color: ${({isClick}) => isClick ? '#FFFFFF' : '#2C2E32'};
  cursor: pointer;
 
`

const InnerMenuItem = styled.div `
  display: flex;
  align-items: center;
  
  p {
    margin: 0 0 0 10px;
  }
  img {
    width: 24px;
    height: 24px;
  }


`


