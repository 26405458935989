import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import {useInfinityScroll} from "../InfinityScroll/useInfinityScroll";

export const WrapperContainer = ({action, ...args}) => {
    const refWrapper = useRef(null)
    const refTrigger = useRef(null)

    const callback = () => {
            action()
    }
    useInfinityScroll(refWrapper, refTrigger, callback);

    return(<>
            <TriggerWrap ref = {refTrigger}/>
        </>)
}


const Wrap = styled.div`
  display: inline-block;
  
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`

const TriggerWrap = styled.div `
  width:  10px;
  height: 2px;
  background-color: transparent;
  

`
