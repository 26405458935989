import React from 'react'
import styled from "styled-components";
import {SwitcherTimeShower} from "./types.js";
import {FullTimer} from "./ContentSwitcher/fullTimer";
import {RestTimer} from "./ContentSwitcher/restTimer";




export const TimeShower = ({
    minutes,
    hours,
    seconds,
    title,
    time,
    timeString,
    type
                           }) => {
    const Switcher = (type) => {
          switch (type) {
              case SwitcherTimeShower.FULL_TIMER:
                  return <FullTimer
                   minutes={minutes}
                   hours={hours}
                   seconds={seconds}
                   title={title}
                  />

              case SwitcherTimeShower.REST_TIMER:
                  return <RestTimer
                  time = {time}
                  timeString={timeString}
                  />
          }
    }
    return (<>
        <ShowerContainer>
            {Switcher(type)}
        </ShowerContainer>
        </>)
}


const ShowerContainer = styled.div`
    z-index: 10;
`
