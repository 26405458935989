import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStyle } from './styles';
import Layout from './Layout';
import Router from './Router';

const App = () => {
  const { isAuth } = useSelector((state) => state.profile);

  return (
    <>
      <GlobalStyle />
      <Layout>
        <Router isAuth={isAuth} />
      </Layout>
    </>
  );
};

export default App;
