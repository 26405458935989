import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import styled from "styled-components";
import MainGallery from "./../Main/Gallery";
import MainInstagram from "./../Main/Instagram";
import MainDownload from "./../Main/DownloadMobile";
//import MainSubscribe from "./../Main/Subscribe";
import MainInfo from "../Main/Info";
import {Fade} from "react-reveal";

class Delivery extends Component {
    render() {
        let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
        let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

        return (
            <Fade>
                <Wrapper headerHeight={this.props.headerHeight}>
                    <Helmet>
                        <title>О нас</title>
                    </Helmet>
                    <Fragment>
                        <MainInfo />
                        {(!is51obed && !isStraus) && (
                            <Fragment>
                                <MainGallery />
                                <MainInstagram />
                                <MainDownload />
                            </Fragment>
                        )}
                    </Fragment>
                </Wrapper>
                {/*<MainSubscribe />*/}
            </Fade>
        );
    }
}

export default Delivery;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 65px 15px 0;
	margin: 0 auto;
	
    @media(max-width: 992px){
        margin: 0 auto;
        margin-top: ${({headerHeight}) => headerHeight + 'px'};
	}
`;