import React, {useState} from 'react'
import styled from "styled-components";
import promoImg from '../../../../images/Profile/Promo.png'
import fire from '../../../../images/Profile/Fire.png'
import wallet from '../../../../images/Profile/Wallets.png'
import {Portal} from "../../../Portal";
import {PromoModal} from "../../../Modal/PromoModal";
import {CreatePromo} from "./CreatePromo/CreatePromo";
export const MyPromoCode = () => {
    const [open ,setOpen] = useState(false)
    const clickHandler = () => {
         setOpen((prevState) => !prevState)
    }
    return (<>
        <PromoContainer>
           <ImgCustom src = {promoImg}/>
            <Header>Создай свой личный
                промокод и зарабатывай баллы</Header>
            <Title>Придумай промокод. Расскажи о нём друзьям и коллегам! Они получат скидку 20% на первый заказ, а ты 50 баллов
                за каждое применение промокода!</Title>
            <FlexContainer>
                <InnerContainer>
                    <ImgCustom src = {fire}/>
                    <div>
                        <p>Заработанные баллы
                            не сгорают</p>
                        <p>Баллы, которые у вас накопились можно
                            потратить на любые товары. 1Б = 1₽</p>
                    </div>
                </InnerContainer>
                <InnerContainer>
                    <ImgCustom src = {wallet}/>
                    <div>
                        <p>Как тратить баллы</p>
                        <p>Баллами можно оплатить
                            50% стоимости заказа</p>
                    </div>
                </InnerContainer>
            </FlexContainer>
            <ButtonCustom onClick = {clickHandler}>Создать код</ButtonCustom>
        </PromoContainer>
        {
          open && <Portal>
                <PromoModal isAuthOpen={open} changeAuthOpen={clickHandler}>
                   <CreatePromo/>
                </PromoModal>
            </Portal>
        }
        </>)
}

 const PromoContainer = styled.div`
   display: flex;
  flex-direction: column; 
  align-items: center;  
  max-width: 787px;
  width: 100%;
  height: 639px;
  border-radius: 16px;
  background-color: #FFFFFF;
  padding: 32px 95px;



`
const Header = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.3799999952316284px;
  align-self: start;
  color: #000000 !important;
  max-width: 435px;
  margin: 0


`

const Title = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.44200000166893005px;
  color: #000000 !important;
  max-width: 556px;
  align-self: start;
  margin-top: 16px;


`

const InnerContainer = styled.div`
  display: flex;
  div {
    margin-left: 10px;
  }
  img {
    align-self: start;
  }
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.44200000166893005px;
    margin: 0
  }
  
  p:last-child {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.44200000166893005px;
    margin: 0
  }

`

const FlexContainer = styled.div`
  display: flex;
  align-self: start;
  max-width: 556px;
  margin-top: 26px;
  width: 100%;
  justify-content: space-between;
`
const ImgCustom = styled.img `
`

const ButtonCustom = styled.button `
    border-radius: 16px;
    color: #FFFFFF;
    background-color: #EA394B;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.44200000166893005px;
    text-align: center;
    width: 100%;
    border: none;
    padding: 20px;
    margin-top: 32px;

`

