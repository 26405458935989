import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	companies: [],
	errorGetCompanies: false,
	myInvites: [],
	errorMyInvites: false,
	myCard: [],
	getMyCardsError: false,
	cardsLoading: false,
	payLoading: false,
};

const personalSlice = createSlice({
	name: 'personal',
	initialState,
	reducers: {
		showMyInvitesRequest(state) {
			state.myInvites = [];
			state.errorMyInvites = false;
		},
		showMyInvitesSuccess(state, action) {
			state.myInvites = action.payload;
			state.errorMyInvites = false;
		},
		showMyInvitesError(state) {
			state.myInvites = [];
			state.errorMyInvites = true;
		},
		getMyCardsRequest(state) {
			state.getMyCardsError = false;
			state.cardsLoading = true;
		},
		getMyCardsSuccess(state, action) {
			state.myCard = action.payload;
			state.getMyCardsError = false;
			state.cardsLoading = false;
		},
		getMyCardsError(state) {
			state.myCard = [];
			state.getMyCardsError = true;
			state.cardsLoading = false;
		},
		loaderPay(state, action) {
			state.payLoading = action.payload;
		},
		confirmInviteToCompany(state, action) {
			state.payLoading = action.payload;
		},
		getRemoveInviteToCompany(state, action) {
			state.payLoading = action.payload;
		},
	},
});

export const {
	showMyInvitesRequest,
	showMyInvitesSuccess,
	showMyInvitesError,
	getMyCardsRequest,
	getMyCardsSuccess,
	getMyCardsError,
	loaderPay,
	confirmInviteToCompany,
	getRemoveInviteToCompany,
} = personalSlice.actions;

export default personalSlice.reducer;
