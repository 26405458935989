import React from 'react';
import styled from "styled-components";
import {TypesPlane} from "../types";
import {MyAdress} from "./MyAdress";

export const Plane = ({typeAdress, adress, type, isFirst}) => {
    const SwitchType = (type) => {
         switch (type) {
             case TypesPlane.MY_ADRESS:
                 return <MyAdress typeAdress={typeAdress} adress={adress} type={type}  />
             case TypesPlane.CREATE_PLANE:
                 return <MyAdress/>
             default:
                 return null
         }
    }

    return(<>
       <PlaneConatiner isFirst = {isFirst}>
           {SwitchType(type)}
       </PlaneConatiner>

        </>)
}


const PlaneConatiner = styled.div`
  width: 100%;
  max-width: 384px;
  height: 229px;
  padding: 10px 24px;
  border-radius: 16px;
  font-family: Montserrat;
  font-weight: 500;
  background-color: #FFFFFF;
  box-shadow: #0000000D;
  margin-top: ${({isFirst}) => isFirst ? '0px' : '15px'};
`

