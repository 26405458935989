import React from "react";
import styled from "styled-components";
import raiting from '../imgs/Raiting.png'

export const SwitchButton = ( {isPayed, currentTime, shutOffTime, timeEnd, timeIsRangeOut }) => {
    const current = new Date(currentTime*1000)
    const shut = new Date(shutOffTime*1000)
    const switcher = (isPayed, currentTime, shutOffTime,  timeEnd, timeIsRangeOut) => {
         // if (!isPayed) {
         //     return (<ButtonCustom>Оплатить</ButtonCustom>)
         // } else {
         //      if (status === 50) {
         //          return (<ButtonCustom> <img src = {raiting}/> Оценить заказ </ButtonCustom>)
         //      }
         //       if (current < shut) {
         //           return (<ButtonCustom>Отменить</ButtonCustom>)
         //       }
         //       if (current > shut) {
         //           return (<ButtonCustom>Где мой заказ?</ButtonCustom>)
         //       }
         // }
        if (timeIsRangeOut) {
            return (<ButtonCustom>Где мой заказ?</ButtonCustom>)
        } else {
            return (<ButtonCustom>Отменить</ButtonCustom>)
        }
    }
    return(<>
        {
            switcher(isPayed, currentTime, shutOffTime, timeEnd, timeIsRangeOut)
        }

    </>)
}

const ButtonCustom = styled.button`
  border-radius: 16px;
  background-color: #F1F1F1;
  padding: 10px;
  color: black;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.7852098345756531px;
  text-align: left;
  border: none;
  
  img {
    margin-right: 8px;
  }


`
