import React, {useEffect, useState} from 'react';
import styled from "styled-components";
export const DetailOrderModal = ({children, changeAuthOpen, isAuthOpen }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(isAuthOpen);
    }, [isAuthOpen]);
    const changeOpen = (e) => {
        e.stopPropagation();
        if (open) {
            setOpen(false);
        }
        setTimeout(() => {
            changeAuthOpen();
        }, 200)
    }

    return (<>
        <MainContainer
            isAuthOpen = {open}
        >
            <ModalContainer
                onClick = {changeOpen}
            >
                <InnerModalContainer
                    onClick = {(e) => e.stopPropagation()}
                >
                    {children}
                    <CloseContainer
                        onClick = {changeOpen}
                    />
                </InnerModalContainer>
            </ModalContainer>
        </MainContainer>

    </>)
}

const CloseContainer = styled.div`
  position: absolute;
  width: 21px;
  height: 3px;
  left: 100%;
  top: 0;
  
  ::before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: #FFFFFF;
    transform: rotate(45deg);
    
  }

  ::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: #FFFFFF;
    transform: rotate(-45deg);


  }
`;

const MainContainer = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s linear;
  opacity: ${({isAuthOpen}) => isAuthOpen ? '1' : '0'};
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const InnerModalContainer = styled.div`
  position: relative;
  border-radius: 24px;
  max-width: 1070px;
  width: 100%;
  height: 452px;
  
`;
