import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Locked from '../../../images/Locked.png';
import NotLocked from '../../../images/NotLocked.png';

export const typesMessages = {
    LOCKED_MESSAGE: { message: 'У вас есть открытый активный заказ. Закройте его и сможете открыть и изменить этот' },
    KITCHEN_ERROR_SERVER_MESSAGE: { message: 'На сервере произошла ошибка, меню сейчас недоступно!' },
    MENU_DETAIL_ERROR_SERVER_MESSAGE: { message: 'На сервере произошла ошибка, неудалось загрузить меню!' },
    ORDER_IS_CLOSED: { message: 'Заказ зафиксирован, продолжайте\nпополнять корзину, следующие товары\nбудут добавлены отдельно', img: Locked },
    ORDER_IS_OPEN: { message: 'Заказ снова открыт,\nвы можете его редактировать\nили удалить лишние товары', img: NotLocked }
};

// Асинхронный thunk для добавления сообщения
export const addActiveMessage = createAsyncThunk(
    'messages/addActiveMessage',
    async (data, { dispatch }) => {
        let timeOutId = null;
        dispatch(messagesSlice.actions.addMessage(data));
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
        timeOutId = setTimeout(() => {
            timeOutId = null;
            dispatch(deleteActiveMessage());
        }, 3500);
    }
);

// Асинхронный thunk для удаления сообщения
export const deleteActiveMessage = createAsyncThunk(
    'messages/deleteActiveMessage',
    async (_, { dispatch, getState }) => {
        const activeMessagesExist = await dispatch(checkActiveMessageExists());
        if (activeMessagesExist) {
            const activeMessages = getState().messages.activeMessages.slice(1); // Удаляем только первое сообщение
            dispatch(messagesSlice.actions.setMessages(activeMessages));
        }
        return activeMessagesExist;
    }
);

// Асинхронный thunk для проверки существования сообщений
export const checkActiveMessageExists = createAsyncThunk(
    'messages/checkActiveMessageExists',
    async (_, { getState }) => {
        const activeMessages = getState().messages.activeMessages;
        return activeMessages.length > 0;
    }
);

// Слайс для управления сообщениями
const messagesSlice = createSlice({
    name: 'messages',
    initialState: {
        types: typesMessages,
        activeMessages: []
    },
    reducers: {
        addMessage(state, action) {
            state.activeMessages.push(action.payload.obj);
        },
        setMessages(state, action) {
            state.activeMessages = action.payload;
        }
    }
});

export const { addMessage, setMessages } = messagesSlice.actions;

export default messagesSlice.reducer;